import { InterfaceAccess, InterfaceRole } from "./roles.interface";
import { userInfo } from "./utils";

const rules: { [key in InterfaceAccess]: InterfaceRole[] } = {
    PROJECT: ["FPCompanyAdmin", "CompanyAdminAddProject", "RequestorAddProject", "RequestorAssignFielder", "CompanyAdminAssignFielder", "FieldingManagerAssignFielder"],
    PROJECT_CREATE: ["CompanyAdminAddProject", "RequestorAddProject"],
    PROJECT_EDIT: ["CompanyAdminAddProject", "RequestorAddProject"],
    FIELDING_ASSIGN: ["FieldingManager", "FieldingManagerAssignFielder", "CompanyAdminAssignFielder", "RequestorAssignFielder"],
    USER: ["FPCompanyAdmin", "CompanyAdminEditUser", "CompanyAdminCreateUser", "RequestorCreateUser"],
    USER_CREATE: ["CompanyAdminCreateUser", "RequestorCreateUser"],
    USER_EDIT: ["CompanyAdminEditUser"],
    PAYMENT: ["PaymentProcessor"],
    FIELDING: ["FielderGoFielding"],
    WORKING_AREA: ["FielderGoFielding"],
    EXPENSE: ["FPCompanyAdmin", "CompanyAdminAddProject", "RequestorAddProject", "RequestorAssignFielder", "CompanyAdminAssignFielder", "FieldingManagerAssignFielder"],
}

export default function roles(accessName: InterfaceAccess) {
    let roleArr = userInfo().user.roles || []
    return rules[accessName].some(name => roleArr.includes(name))
}