import { addressToLocations, locationToAddress } from "@arcgis/core/rest/locator"
import { InterfaceFieldingMarkerList, InterfaceMarkerPoint } from "../pages/fielding/fielding.interface"
import { esriToken, googleToken } from "../state"
import esriPoint from "@arcgis/core/geometry/Point"
import { Loader } from "@googlemaps/js-api-loader"
import { useDispatch } from "react-redux"
import { setLoading } from "../pages/auth/auth.reducer"

interface InterfaceResultGetAddress {
    address: string
    city: string
}

export default function useAddress() {

    const dispatch = useDispatch<any>()

    const getAddress = async (latitude: number, longitude: number): Promise<InterfaceResultGetAddress> => {
        dispatch(setLoading(true))
        try {
            const responseGeocode = await locationToAddress(
                "http://geocode-api.arcgis.com/arcgis/rest/services/World/GeocodeServer?token=" +
                esriToken +
                "&featureTypes=PointAddress",
                { location: new esriPoint({ latitude: latitude, longitude: longitude }) }
            )
            dispatch(setLoading(false))
            return {
                address: responseGeocode.address,
                city: `${responseGeocode?.attributes?.City}, ${responseGeocode?.attributes?.Region}`
            }
        } catch (error) { }
        try {
            const loader = new Loader({
                apiKey: googleToken,
                libraries: ["places"]
            })
            const google = await loader.load()
            const geocoder = new google.maps.Geocoder()
            const response = await geocoder.geocode({ location: { lat: latitude, lng: longitude } })
            if (response.results[0]) {
                const city =
                    response.results[0].address_components.find((component: any) => component.types.includes("locality"))
                        ?.long_name ?? "-"
                const region =
                    response.results[0].address_components.find((component: any) =>
                        component.types.includes("administrative_area_level_1")
                    )?.long_name ?? "-"
                dispatch(setLoading(false))
                return {
                    address: response.results[0].formatted_address,
                    city: `${city}, ${region}`
                }
            }
        } catch (error) {
            console.log(error)
        }
        dispatch(setLoading(false))
        return {
            address: "-",
            city: `-`
        }
    }

    const getCoordinate = async (address: string): Promise<{ latitude: number; longitude: number }> => {
        return new Promise((resolve, reject) => {
            dispatch(setLoading(true))
            addressToLocations("http://geocode-api.arcgis.com/arcgis/rest/services/World/GeocodeServer?token=" + esriToken + "&featureTypes=PointAddress", { address: { singleLine: address, token: esriToken } })
                .then(response => {
                    if (response.length > 0) {
                        resolve({
                            latitude: response[0].location.latitude,
                            longitude: response[0].location.longitude
                        })
                    }
                })
                .catch(err => {
                    reject(err)
                })
                .finally(() => {
                    dispatch(setLoading(false))
                })
        })
    }

    const fillAddressMultiple = async (
        fieldingRequestNoAddressList: Array<{
            id: string
            markers: InterfaceFieldingMarkerList
        }>,
        home: boolean = false
    ): Promise<Array<{ id: string; value: string }>> => {
        const addressToAdd: Array<{ id: string; value: string }> = []
        for (const i in fieldingRequestNoAddressList) {
            const coordinateList: Array<InterfaceMarkerPoint> = []
            const fieldingRequest = fieldingRequestNoAddressList[i]
            for (const j in fieldingRequest.markers) {
                if (Object.prototype.hasOwnProperty.call(fieldingRequest.markers, j)) {
                    const marker = fieldingRequest.markers[j]
                    if (marker.dotLongLat !== "") {
                        coordinateList.push({
                            latitude: parseFloat(marker.dotLongLat.split(",")[1]),
                            longitude: parseFloat(marker.dotLongLat.split(",")[0]),
                            id: marker.id
                        })
                    } else if (marker.lineData.length > 0) {
                        coordinateList.push({
                            latitude: marker.lineData[0][0].latitude,
                            longitude: marker.lineData[0][0].longitude,
                            id: marker.id
                        })
                    } else if (marker.shapeData.length > 0) {
                        coordinateList.push({
                            latitude: marker.shapeData[0][0].latitude,
                            longitude: marker.shapeData[0][0].longitude,
                            id: marker.id
                        })
                    }
                }
            }
            for (const j in coordinateList) {
                if (Object.prototype.hasOwnProperty.call(coordinateList, j)) {
                    const coordinate = coordinateList[j]

                    const address = await getAddress(coordinate.latitude, coordinate.longitude)
                    addressToAdd.push({ id: home ? fieldingRequest.id : coordinate.id, value: address.address })
                }
            }
        }
        return addressToAdd
    }

    return { getAddress, getCoordinate, fillAddressMultiple }

}