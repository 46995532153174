import { Cancel, Check } from "@mui/icons-material";
import { Autocomplete, Box, IconButton, LinearProgress, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";
import { userInfo } from "../../../shared/utils";
import { baseUrl } from "../../../state";
import notificationError from "../../../shared/notificationError";
import { useDispatch } from "react-redux";
import moment from "moment-timezone";
import { FieldingMarkerDefaultTimeOffsetGetFieldingId } from "../fielding.api";
import notificationSuccess from "../../../shared/notificationSuccess";
import { Dropzone } from "@dropzone-ui/react";

export default function FieldingUploadImages(props: { fieldingId: string, onClose: any }) {

    const dispatch: any = useDispatch()

    const [timeOffsetSelected, setTimeOffsetSelected] = useState<number>(0)
    const [filesQueue, setFilesQueue] = useState<any>([])
    const [isUploading, setIsUploading] = useState(false)

    useEffect(() => {
        if (props.fieldingId) {
            dispatch(FieldingMarkerDefaultTimeOffsetGetFieldingId({ token: userInfo().token, fieldingRequestId: props.fieldingId }))
                .unwrap()
                .then((response: any) => {
                    setTimeOffsetSelected(response.data.data.length > 0 ? response.data.data[0] : new Date().getTimezoneOffset())
                })
            setFilesQueue([])
        }
    }, [props.fieldingId])

    useEffect(() => {
        if (!isUploading && filesQueue.length > 0) {
            setIsUploading(true)
            uploading()
        }
    }, [filesQueue])

    const onChangeImage = async (filesLocal: any) => {
        if (filesLocal.length > 0) {
            setFilesQueue([
                ...filesQueue,
                ...Array.from(filesLocal).map((file: any) => {
                    return {
                        name: file.name,
                        uploadProgress: 0,
                        file: file.file
                    }
                })
            ])
        }
    }

    const uploading = async () => {
        let filesLocal: any = filesQueue
        let filesToUpload: any = filesLocal.filter((file: any) => file.uploadProgress === 0)
        if (filesToUpload.length > 0) {
            for (const i in filesToUpload) {
                try {
                    let data = new FormData();
                    data.append("projectId", "")
                    data.append("fieldingRequestId", props.fieldingId)
                    data.append("token", userInfo().token)
                    data.append("timeOffset", String(timeOffsetSelected || localOffset))
                    data.append("file", filesToUpload[i].file)
                    await axios.post(`${baseUrl}/newapi/FieldingRequestMarker/TimerUpload`, data, {
                        onUploadProgress: (progressEvent) => {
                            var percentCompleted = Math.round((progressEvent.loaded * 100) / (progressEvent.total || 1))
                            filesLocal = filesLocal.map((f: any) => {
                                if (f.name === filesToUpload[i].name) {
                                    return {
                                        ...f,
                                        uploadProgress: percentCompleted
                                    }
                                }
                                return f
                            })
                            setFilesQueue(filesLocal)
                        }
                    });
                } catch (error: any) {
                    console.log(error)
                    notificationError(error.response)
                }
            }
        }
        setIsUploading(false)
        notificationSuccess("Uploaded successfully")
        props.onClose()
    }

    const localOffset = new Date().getTimezoneOffset()

    const timeOffsetOptions = [
        {
            name: "-",
            value: [240, 300, 360, 420, 480, 600].includes(localOffset) ? 0 : localOffset
        },
        {
            name: "EDT/Eastern Time",
            value: moment().tz("America/New_York").isDST() ? 240 : 300
        },
        {
            name: "MDT/Mountain Time",
            value: moment().tz("America/Denver").isDST() ? 360 : 420
        },
        {
            name: "PDT/Pacific Time",
            value: moment().tz("America/Los_Angeles").isDST() ? 420 : 480
        },
        {
            name: "CDT/Central Time",
            value: moment().tz("America/Chicago").isDST() ? 300 : 360
        },
        {
            name: "HST/Hawaii Time",
            value: 600
        }
    ]

    return (
        <Box>
            <Autocomplete
                fullWidth
                options={timeOffsetOptions}
                getOptionLabel={(time) => time.name}
                value={timeOffsetOptions.filter(time => time.value === timeOffsetSelected)[0] || null}
                onChange={(e, value) => setTimeOffsetSelected(value?.value || 0)}
                renderInput={(params) => <TextField {...params} label="Time Zone" />}
            />
            <br />
            <Dropzone onChange={onChangeImage} header={false} accept="image/*" disabled={isUploading} />
            {filesQueue.map((file: any) => (
                <Box key={file.name}>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        {file.name}
                        <IconButton>
                            {file.uploadProgress < 100
                                ? (
                                    <Cancel />
                                )
                                : (
                                    <Check color="success" />
                                )
                            }
                        </IconButton>
                    </Box>
                    <LinearProgress variant="determinate" value={file.uploadProgress} />
                </Box>
            ))}
        </Box>
    )
}