import { createSlice } from '@reduxjs/toolkit';
import {
  accountStorageGet,
  accountStorageGetByFielding,
  accountStorageGetByProject,
  accountStorageGetByTools,
  accountValidateToken,
  createAccount,
  deleteAccount,
  editAccount,
  getAccount,
  getAccountFielder,
  getAccountFielderDetailList,
  getAllAccount,
  getFielderRating,
  getFielderRatingByRequestId,
  getServerTime,
  projectClearNotification,
  projectGetNotification,
  projectReadNotification,
  resetPassword,
  submitFielderRating,
} from './account.api';
import {
  InitialAccountGet,
  InitialAccountStorage,
  InterfaceAccountDataList,
  InterfaceAccountFielderDataList,
  InterfaceAccountFielderDetailDataList,
  InterfaceAccountGet,
  InterfaceAccountRatingFielderDataList,
  InterfaceAccountRatingFielderDataListRequestId,
  InterfaceAccountStorage,
} from './account.interface';
import moment from 'moment';

export interface AccountState {
  loading: boolean;
  parameterGetData: InterfaceAccountGet;
  dataList: InterfaceAccountDataList;
  dataTotal: number;
  dataFielderList: InterfaceAccountFielderDataList;
  ratingFielderList: InterfaceAccountRatingFielderDataList;
  ratingFielderRequestList: InterfaceAccountRatingFielderDataListRequestId;
  dataFielderDetailList: InterfaceAccountFielderDetailDataList;
  storageDetails: InterfaceAccountStorage;
  storageDetailsPerGroup: InterfaceAccountStorage;
  dataNotificationProjectList: any
  serverTime: string
}
const initialState: AccountState = {
  loading: false,
  parameterGetData: InitialAccountGet,
  dataList: [],
  dataTotal: 0,
  dataFielderList: [],
  ratingFielderList: [],
  ratingFielderRequestList: [],
  dataFielderDetailList: [],
  storageDetails: InitialAccountStorage,
  storageDetailsPerGroup: InitialAccountStorage,
  dataNotificationProjectList: [],
  serverTime: '',
};

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setParameterGetData: (state, action) => {
      state.parameterGetData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createAccount.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(createAccount.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(createAccount.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(editAccount.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(editAccount.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(editAccount.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(getAccount.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getAccount.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.data.status) {
        state.dataList = action.payload.data.data.data;
        state.dataTotal = action.payload.data.data.total;
      }
    });
    builder.addCase(getAccount.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(getAllAccount.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getAllAccount.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getAllAccount.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(getAccountFielder.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getAccountFielder.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.data.status) {
        const list: InterfaceAccountFielderDataList = action.payload.data.data;
        state.dataFielderList = list.sort((a, b) => a.fullName.localeCompare(b.fullName));
      }
    });
    builder.addCase(getAccountFielder.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(getFielderRating.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getFielderRating.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.data.status) {
        state.ratingFielderList = action.payload.data.data;
      }
    });
    builder.addCase(getFielderRating.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(getFielderRatingByRequestId.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getFielderRatingByRequestId.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.data.status) {
        state.ratingFielderRequestList = action.payload.data.data;
      }
    });
    builder.addCase(getFielderRatingByRequestId.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(submitFielderRating.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(submitFielderRating.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(submitFielderRating.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(getAccountFielderDetailList.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getAccountFielderDetailList.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.data.status) {
        state.dataFielderDetailList = action.payload.data.data;
      }
    });
    builder.addCase(getAccountFielderDetailList.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(deleteAccount.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteAccount.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(deleteAccount.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(resetPassword.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(resetPassword.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(accountStorageGet.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(accountStorageGet.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.data.status) {
        state.storageDetails = action.payload.data.data;
      }
    });
    builder.addCase(accountStorageGet.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(accountStorageGetByProject.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(accountStorageGetByProject.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.data.status) {
        state.storageDetailsPerGroup = action.payload.data.data;
      }
    });
    builder.addCase(accountStorageGetByProject.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(accountStorageGetByFielding.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(accountStorageGetByFielding.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.data.status) {
        state.storageDetailsPerGroup = action.payload.data.data;
      }
    });
    builder.addCase(accountStorageGetByFielding.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(accountStorageGetByTools.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(accountStorageGetByTools.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.data.status) {
        state.storageDetailsPerGroup = action.payload.data.data;
      }
    });
    builder.addCase(accountStorageGetByTools.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(accountValidateToken.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(accountValidateToken.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(accountValidateToken.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(projectGetNotification.fulfilled, (state, action) => {
      if (action.payload.data.status) {
        state.dataNotificationProjectList = action.payload.data.data;
      }
    });

    builder.addCase(projectReadNotification.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(projectReadNotification.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(projectReadNotification.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(projectClearNotification.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(projectClearNotification.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(projectClearNotification.rejected, (state, action) => {
      state.loading = false;
    });


    builder.addCase(getServerTime.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getServerTime.fulfilled, (state, action) => {
      state.loading = false;
      state.serverTime = moment(action.payload.replace("-07:00", "")).format("YYYY-MM-DD HH:mm:ss");
    });
    builder.addCase(getServerTime.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const { setParameterGetData } = accountSlice.actions;

export default accountSlice.reducer;
