import { userInfo } from '../../shared/utils';
import { InterfaceMarkerPoint } from '../fielding/fielding.interface';

export interface InterfaceAccountRole {
  fpCompanyAdmin: boolean;
  fieldingManager: boolean;
  fieldingRequestSimple: boolean;
  fielderSimple: boolean;
  paymentProcessor: boolean;
  fieldingManagerCoverageArea: boolean;
  companyAdminCreateUser: boolean;
  requestorCreateUser: boolean;
  companyAdminEditUser: boolean;
  companyAdminAddProject: boolean;
  requestorAddProject: boolean;
  companyAdminAssignFielder: boolean;
  fieldingManagerAssignFielder: boolean;
  requestorAssignFielder: boolean;
  companyAdminCompanyStorage: boolean;
  billingCompanyStorage: boolean;
  fielderGoFielding: boolean;
  billingCreateStatement: boolean;
  billingViewTransactionHistory: boolean;
  billingBlastMail: boolean;
}
export const InitialAccountRole: InterfaceAccountRole = {
  fpCompanyAdmin: false,
  fieldingManager: false,
  fieldingRequestSimple: false,
  fielderSimple: false,
  paymentProcessor: false,
  fieldingManagerCoverageArea: false,
  companyAdminCreateUser: false,
  requestorCreateUser: false,
  companyAdminEditUser: false,
  companyAdminAddProject: false,
  requestorAddProject: false,
  companyAdminAssignFielder: false,
  fieldingManagerAssignFielder: false,
  requestorAssignFielder: false,
  companyAdminCompanyStorage: false,
  billingCompanyStorage: false,
  fielderGoFielding: false,
  billingCreateStatement: false,
  billingViewTransactionHistory: false,
  billingBlastMail: false,
};

export interface InterfaceAccountCreate {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  passwordConfirm: string;
  companyId: string;
  roles: InterfaceAccountRole;
  address: string;
  workingAreaPolyData: string;
  phoneNumber: string;
}

export interface InterfaceAccountEdit {
  token: string;
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  workingAreaPolyData: string;
  address: string;
  roles: InterfaceAccountRole;
}

export interface InterfaceAccountGet {
  companyId: string;
  token: string;
  skip: number;
  take: number;
  filter: string;
  sortField: string;
  sortType: 0 | 1;
}

export interface InterfaceAccountData {
  address: string;
  company: string;
  companyId: string;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  rolesDisplay: string;
  workingAreaPolyData: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
  averageRatingString: string;
  ratingTotal: number;
  roles: Array<string>;
}
export interface InterfaceAccountDataList extends Array<InterfaceAccountData> {}

export interface InterfaceAccountFielderData {
  id: string;
  value: string;
  fullName: string;
}
export interface InterfaceAccountRatingFielderData {
  ratedByName: string;
  ratedByProfilePicturePath: any;
  rating: number;
  review: string;
  createdById: string;
}
export interface InterfaceAccountRatingFielderDataRequestId {
  fielderId: string;
  fielderName: string;
  rating: number;
  review: null | string;
}

export interface InterfaceAccountFielderDataList
  extends Array<InterfaceAccountFielderData> {}

export interface InterfaceAccountRatingFielderDataList
  extends Array<InterfaceAccountRatingFielderData> {}

export interface InterfaceAccountRatingFielderDataListRequestId
  extends Array<InterfaceAccountRatingFielderDataRequestId> {}

export interface InterfaceAccountFielderDetailData {
  id: string;
  email: string;
  fullName: string;
  workingAreaPolyData: string;
  address: string;
  averageRatingString: string;
  ratingTotal: number;
  assignedFieldingRequestMarkerLocations: Array<string>;
  skillTypeId: string;
  color?: string;
  addressLocation?: InterfaceMarkerPoint;
  state?: string;
}
export interface InterfaceAccountFielderDetailDataList
  extends Array<InterfaceAccountFielderDetailData> {}

export interface InterfaceAccountStorageFileList
  extends Array<{
    projectAttachmentId: string;
    fieldingRequestMarkerAttachmentId: string;
    fieldingRequestAttachmentId: string;
    project: string;
    fieldingRequest: string;
    fileName: string;
    fileSize: string;
    uploadDate: string;
    uploadDateString: string;
    filePath: string;
  }> {}
export interface InterfaceAccountStorage {
  totalImageSize: string;
  totalImageSizePercentage: number;
  totalDocumentSize: string;
  totalDocumentSizePercentage: number;
  totalStorageUsed: string;
  fileList: InterfaceAccountStorageFileList;
}
export const InitialAccountStorage: InterfaceAccountStorage = {
  totalImageSize: '',
  totalImageSizePercentage: 0,
  totalDocumentSize: '',
  totalDocumentSizePercentage: 0,
  totalStorageUsed: '',
  fileList: [],
};

export interface InterfaceAccountResetPassword {
  token: string;
  password: string;
  userId: string;
  newPassword: string;
  newPasswordConfirm: string;
}
export const InitialAccountResetPassword: InterfaceAccountResetPassword = {
  token: '',
  password: '',
  userId: '',
  newPassword: '',
  newPasswordConfirm: '',
};

export const InitialAccountGet: InterfaceAccountGet = {
  companyId: userInfo().user.companyId,
  token: userInfo().token,
  skip: 0,
  take: 50,
  filter: '',
  sortField: '',
  sortType: 0,
};

export const InitialAccountCreate: InterfaceAccountCreate = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  passwordConfirm: '',
  companyId: '',
  roles: InitialAccountRole,
  address: '',
  workingAreaPolyData: '',
  phoneNumber: '',
};

export const InitialAccountData: InterfaceAccountData = {
  id: '',
  firstName: '',
  lastName: '',
  email: '',
  companyId: '',
  company: '',
  address: '',
  rolesDisplay: '',
  workingAreaPolyData: '',
  lastModifiedBy: '',
  lastModifiedDate: '',
  averageRatingString: '',
  ratingTotal: 0,
  roles: [],
};
