import { CalendarMonth } from "@mui/icons-material";
import { List, ListItem, ListItemButton, ListItemText, Popover, TextField } from "@mui/material";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import ButtonOutlined from "../ButtonOutlined";

export default function CustomDatePicker(props: { setDateStart: any, dateStart: string, setDateEnd: any, dateEnd: string, format?: string, fullWidth?: boolean }) {

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [optionSelected, setOptionSelected] = useState<{ name: string, value: number } | null>()
    const [customOption, setCustomOption] = useState<{ start: string | undefined, end: string | undefined } | null>()

    const options = [
        {
            name: "Last 7 Days",
            value: 7
        },
        {
            name: "Last 14 Days",
            value: 14
        },
        {
            name: "Last 30 Days",
            value: 30
        },
        {
            name: "Last 90 Days",
            value: 90
        },
    ]

    useEffect(() => {
        const diff = moment(props.dateEnd).diff(moment(props.dateStart), 'days')
        setOptionSelected(options.find((option) => option.value === diff))
    }, [])

    useEffect(() => {
        if (Boolean(optionSelected)) {
            let start = moment().subtract(optionSelected?.value, 'd').format(props.format !== undefined ? props.format : 'YYYY-MM-DD');
            let end = moment().format(props.format !== undefined ? props.format : 'YYYY-MM-DD');
            props.setDateStart(start)
            props.setDateEnd(end)
            setCustomOption(null)
        }
    }, [optionSelected])

    useEffect(() => {
        if (Boolean(customOption)) {
            if (Boolean(customOption?.start) && Boolean(customOption?.end)) {
                props.setDateStart(moment(customOption?.start).format(props.format !== undefined ? props.format : 'YYYY-MM-DD'))
                props.setDateEnd(moment(customOption?.end).format(props.format !== undefined ? props.format : 'YYYY-MM-DD'))
                setOptionSelected(null)
            }
        }
    }, [customOption])

    return (
        <Fragment>
            <ButtonOutlined sx={{ fontSize: 10, backgroundColor: 'white', borderRadius: '20px' }} fullWidth={props.fullWidth} onClick={(e) => setAnchorEl(e.currentTarget)} startIcon={<CalendarMonth />}>
                {Boolean(optionSelected)
                    ? `${optionSelected?.name}: ${moment(props.dateStart).format("MM/DD/YYYY")} - ${moment(props.dateEnd).format("MM/DD/YYYY")}`
                    : `Custom: ${moment(props.dateStart).format("MM/DD/YYYY")} - ${moment(props.dateEnd).format("MM/DD/YYYY")}`
                }
            </ButtonOutlined>
            <Popover
                id={"id"}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <List>
                    {options.map(option => (
                        <ListItemButton key={option.value} onClick={() => { setOptionSelected(option); setAnchorEl(null) }}>
                            <ListItemText>{option.name}</ListItemText>
                        </ListItemButton>
                    ))}
                    <ListItem>
                        <ListItemText>
                            <TextField type="date" fullWidth={false} value={customOption?.start || ""} onChange={(e) => setCustomOption({ start: e.target.value, end: customOption?.end })} />{` `}
                            <TextField type="date" fullWidth={false} value={customOption?.end || ""} onChange={(e) => setCustomOption({ start: customOption?.start, end: e.target.value })} />
                        </ListItemText>
                    </ListItem>
                </List>
            </Popover>
        </Fragment>
    )
}