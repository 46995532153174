import { useEffect, useRef, useState } from "react"
import esriConfig from "@arcgis/core/config"
import esriMap from "@arcgis/core/Map"
import esriMapView from "@arcgis/core/views/MapView"
import esriGraphicsLayer from "@arcgis/core/layers/GraphicsLayer"
import esriZoom from "@arcgis/core/widgets/Zoom"
import esriSearch from "@arcgis/core/widgets/Search"
import esriGraphics from "@arcgis/core/Graphic"
import esriPoint from "@arcgis/core/geometry/Point"
import esriMarkerSymbol from "@arcgis/core/symbols/SimpleMarkerSymbol"
import esriPictureMarkerSymbol from "@arcgis/core/symbols/PictureMarkerSymbol"
import esriPolyline from "@arcgis/core/geometry/Polyline"
import esriSimpleLineSymbol from "@arcgis/core/symbols/SimpleLineSymbol"
import esriPolygon from "@arcgis/core/geometry/Polygon"
import esriSimpleFillSymbol from "@arcgis/core/symbols/SimpleFillSymbol"
import esriTextSymbol from "@arcgis/core/symbols/TextSymbol"
import esriSketch from "@arcgis/core/widgets/Sketch"
import { webMercatorToGeographic } from "@arcgis/core/geometry/support/webMercatorUtils"
import { Button, IconButton, Tooltip, Typography, useTheme } from "@mui/material"
import { Circle, Delete, Done, ShapeLine, Timeline, Undo } from "@mui/icons-material"
import { InterfaceFieldingMarkerList, InterfaceMarkerLine, InterfaceMarkerPoint } from "../../fielding/fielding.interface"
import { esriToken, googleToken } from "../../../state"
import axios from "axios"
import { Loader } from "@googlemaps/js-api-loader"
import logo_pegman from "../../../assets/images/logo_pegman.png"
import logo_direction from "../../../assets/images/logo_direction.png"
import { useWindowSize } from "../../../shared/utils"

interface interfaceFieldingRequest {
    id: string
    name: string
    markers: InterfaceFieldingMarkerList
    color: string
    type?: string
}

export default function ProjectFormMap(props: {
    fieldingRequestList: Array<interfaceFieldingRequest>
    onAddMarker: (params: {
        lineData: Array<InterfaceMarkerLine>
        shapeData: Array<InterfaceMarkerLine>
        dotLongLat: string
    }) => void
    onUndoMarker?: () => void
    onDeleteMarker?: (markerId: string) => void
    onClickGraphic: (params: { fieldingRequestId: string | null; markerId: string | null }) => void
    editable: boolean
    editMarkerOnly?: any | undefined
    center: InterfaceMarkerPoint | null
    selectedMarkerId?: string
    setSelectedMarkerId?: any
    fieldingIndexSelected?: number
}) {
    const mapRef = useRef<any>()
    const googleMapsRef = useRef<any>()
    const googleMapsPanoramaRef = useRef<any>()
    const { width } = useWindowSize()
    const theme = useTheme()

    const [map, setMap] = useState<any>()
    const [view, setView] = useState<any>()
    const [sketch, setSketch] = useState<esriSketch>()
    const [layerMarker, setLayerMarker] = useState<any>()
    const [layerMarkerTemp, setLayerMarkerTemp] = useState<any>()
    const [layerMarkerPegman, setLayerMarkerPegman] = useState<any>()

    const [tempPoint, setTempPoint] = useState<InterfaceMarkerPoint | undefined>()
    const [tempPolygon, setTempPolygon] = useState<InterfaceMarkerLine>([])
    const [tempPolyline, setTempPolyline] = useState<InterfaceMarkerLine>([])

    const [palleteActive, setPalleteActive] = useState(0)
    const [latLngClick, setLatLngClick] = useState({ lat: 0, lng: 0 })
    const [isPegmanActive, setIsPegmanActive] = useState(false)
    const [pegmanPosition, setPegmanPostion] = useState<InterfaceMarkerPoint | null>(null)

    const [markerIdToDelete, setMarkerIdToDelete] = useState<string>("")
    // eslint-disable-next-line
    const [_, setRefreshCount] = useState(0)

    // handle initial load
    useEffect(() => {
        axios("https://ipapi.co/json").then((response) => {
            // initialize
            esriConfig.apiKey = esriToken
            let mapLocal = new esriMap({
                basemap: "topo-vector" //"satellite"
            })
            let layerMarkerLocal = new esriGraphicsLayer()
            let layerMarkerTempLocal = new esriGraphicsLayer()
            let layerMarkerPegmanLocal = new esriGraphicsLayer()
            mapLocal.add(layerMarkerLocal)
            mapLocal.add(layerMarkerTempLocal)
            mapLocal.add(layerMarkerPegmanLocal)
            let viewLocal = new esriMapView({
                container: mapRef.current,
                map: mapLocal,
                zoom: props.center !== null ? 15 : 11,
                center:
                    props.center !== null
                        ? [props.center.longitude, props.center.latitude]
                        : [response.data.longitude, response.data.latitude]
            })
            // widget
            let viewZoom = new esriZoom({ view: viewLocal })
            viewLocal.ui.remove(viewZoom)
            let viewSearch = new esriSearch({ view: viewLocal, locationEnabled: false })
            viewLocal.ui.add(viewSearch, { position: "top-left" })
            const sketchLocal = new esriSketch({
                layer: layerMarkerTempLocal,
                view: viewLocal
            })
            setSketch(sketchLocal)
            // listener
            viewLocal.on("click", (event) => {
                viewLocal.hitTest(event).then((response) => {
                    setLatLngClick({
                        lat: response.results[0].mapPoint.latitude,
                        lng: response.results[0].mapPoint.longitude
                    })
                    let firstResult = response.results[0]
                    if (firstResult.type === "graphic") {
                        if (
                            firstResult.graphic.attributes?.type === "FIELDING_DOT" ||
                            firstResult.graphic.attributes?.type === "FIELDING_LINE" ||
                            firstResult.graphic.attributes?.type === "FIELDING_SHAPE" ||
                            firstResult.graphic.attributes?.type === "FIELDING_TEXT"
                        ) {
                            if (Boolean(firstResult.graphic.attributes.fieldingRequestId)) {
                                props.onClickGraphic({
                                    fieldingRequestId: firstResult.graphic.attributes?.fieldingRequestId || null,
                                    markerId: firstResult.graphic.attributes?.markerId || null
                                })
                            }
                            else {
                                setMarkerIdToDelete(firstResult.graphic.attributes?.markerId)
                            }
                        }
                    }
                })
            })
            viewLocal.on("layerview-create", () => {
                if (document.getElementById("searchbox-input")) {
                    (document.getElementById("searchbox-input") as HTMLInputElement).placeholder = "Enter adress or GPS"
                }
            })
            viewLocal.on("pointer-move", (event) => {
                viewLocal.hitTest(event).then((response) => {
                    let firstResult = response.results[0]
                    if (firstResult.type === "graphic") {
                        if (
                            firstResult.graphic.attributes?.type === "FIELDING_DOT" ||
                            firstResult.graphic.attributes?.type === "FIELDING_LINE" ||
                            firstResult.graphic.attributes?.type === "FIELDING_SHAPE" ||
                            firstResult.graphic.attributes?.type === "FIELDING_TEXT"
                        ) {
                            viewLocal.container.style.cursor = "pointer";
                        }
                        else {
                            viewLocal.container.style.cursor = "default";
                        }
                    }
                });
            });
            setLayerMarker(layerMarkerLocal)
            setLayerMarkerTemp(layerMarkerTempLocal)
            setLayerMarkerPegman(layerMarkerPegmanLocal)
            setView(viewLocal)
            setMap(mapLocal)
        })
        return () => view?.destroy()
        // eslint-disable-next-line
    }, [])

    // centering from props
    useEffect(() => {
        if (view) {
            view.goTo({
                center: [props.center?.longitude, props.center?.latitude]
            })
        }
    }, [view, props.center])

    // on creating marker
    useEffect(() => {
        if (palleteActive === 0) {
            sketch?.cancel()
        }
        else if (palleteActive === 1) {
            sketch?.create("point")
        }
        else if (palleteActive === 2) {
            sketch?.create("polyline")
        }
        else if (palleteActive === 3) {
            sketch?.create("polygon")
        }
        sketch?.on("create", function (event) {
            if (event.state === "complete") {
                layerMarkerTemp.removeAll()
                var polygonGeometry = event.graphic.geometry
                var wgs84Geometry = webMercatorToGeographic(polygonGeometry)
                const geometry: any = wgs84Geometry
                if (geometry.latitude && geometry.longitude) {
                    setTempPoint({ latitude: geometry.latitude, longitude: geometry.longitude })
                }
                else if (geometry.paths && geometry.paths.length > 0) {
                    const newLineData: InterfaceMarkerLine = geometry.paths[0].map((latlng: any) => {
                        return {
                            latitude: latlng[1],
                            longitude: latlng[0]
                        }
                    })
                    setTempPolyline(newLineData)
                }
                else if (geometry.rings && geometry.rings.length > 0) {
                    const newShapeData: InterfaceMarkerLine = geometry.rings[0].map((latlng: any) => {
                        return {
                            latitude: latlng[1],
                            longitude: latlng[0]
                        }
                    })
                    setTempPolygon(newShapeData)
                }
                setPalleteActive(0)
                sketch?.cancel()
            }
        })
        // eslint-disable-next-line
    }, [palleteActive, sketch, palleteActive])

    // when marker point created
    useEffect(() => {
        if (tempPoint) {
            props.onAddMarker({
                lineData: [],
                shapeData: [],
                dotLongLat: `${tempPoint.longitude},${tempPoint.latitude}`
            })
            setTempPoint(undefined)
        }
        // eslint-disable-next-line
    }, [tempPoint])

    // when marker line created
    useEffect(() => {
        if (tempPolyline.length > 0) {
            props.onAddMarker({
                lineData: [tempPolyline],
                shapeData: [],
                dotLongLat: ""
            })
            setTempPolyline([])
        }
        // eslint-disable-next-line
    }, [tempPolyline])

    // when marker shape created
    useEffect(() => {
        if (tempPolygon.length > 0) {
            props.onAddMarker({
                lineData: [],
                shapeData: [tempPolygon],
                dotLongLat: ""
            })
            setTempPolygon([])
        }
        // eslint-disable-next-line
    }, [tempPolygon])

    // when pegman active and clicked
    useEffect(() => {
        const { lat, lng } = latLngClick
        if (isPegmanActive) {
            setPegmanPostion({ latitude: lat, longitude: lng })
        }
    }, [latLngClick, isPegmanActive])

    // when marker list changed
    useEffect(() => {
        if (layerMarker) {
            let textMarkers: Array<any> = []
            let pointMarkers: Array<any> = []
            let lineMarkers: Array<any> = []
            let shapeMarkers: Array<any> = []
            props.fieldingRequestList.forEach((fieldingRequest) => {
                let newGraphic = fieldingRequest?.markers?.map(marker => {
                    if (marker.dotLongLat && marker.dotLongLat !== "") {
                        textMarkers.push(
                            new esriGraphics({
                                geometry: new esriPoint({
                                    latitude: parseFloat(marker.dotLongLat.split(",")[1]),
                                    longitude: parseFloat(marker.dotLongLat.split(",")[0])
                                }),
                                symbol: new esriTextSymbol({
                                    color: "black",
                                    text: marker.customTypeId ? fieldingRequest.name : "",
                                    xoffset: marker.customTypeId ? 40 : 0,
                                    yoffset: marker.customTypeId ? -25 : -15,
                                    font: {
                                        size: marker.customTypeId ? 12 : 8,
                                        family: "Roboto"
                                    }
                                }),
                                attributes: {
                                    type: "FIELDING_TEXT",
                                    fieldingRequestId: fieldingRequest.id,
                                    markerId: marker.id
                                }
                            })
                        )

                        let newEsri = new esriGraphics({
                            geometry: new esriPoint({
                                latitude: parseFloat(marker.dotLongLat.split(",")[1]),
                                longitude: parseFloat(marker.dotLongLat.split(",")[0])
                            }),
                            symbol: new esriMarkerSymbol({
                                style: "circle",
                                color: marker.colorCode
                                    ? marker.colorCode
                                    : marker.customTypeId
                                        ? marker.id !== undefined && props.selectedMarkerId === marker.id
                                            ? "yellow"
                                            : "red"
                                        : "green"
                            }),
                            attributes: {
                                type: "FIELDING_DOT",
                                fieldingRequestId: fieldingRequest.id,
                                markerId: marker.id
                            }
                        })

                        return newEsri
                    }
                    return null
                })
                pointMarkers = [...pointMarkers, ...newGraphic.filter((item) => item !== null)]
                fieldingRequest.markers
                    .filter((marker) => marker.dotLongLat === "")
                    .map((marker) => {
                        marker.lineData?.map((lineData) => {
                            try {
                                textMarkers.push(
                                    new esriGraphics({
                                        geometry: new esriPoint({
                                            latitude: lineData[0].latitude,
                                            longitude: lineData[0].longitude
                                        }),
                                        symbol: new esriTextSymbol({
                                            color: "black",
                                            text: fieldingRequest.name,
                                            xoffset: 40,
                                            yoffset: -25,
                                            font: {
                                                size: 12,
                                                family: "Roboto"
                                            }
                                        }),
                                        attributes: {
                                            type: "FIELDING_TEXT",
                                            fieldingRequestId: fieldingRequest.id,
                                            markerId: marker.id
                                        }
                                    })
                                )
                                let lineMarker = new esriGraphics({
                                    geometry: new esriPolyline({
                                        paths: [lineData.map((line) => [line.longitude, line.latitude])]
                                    }),
                                    symbol: new esriSimpleLineSymbol({
                                        color:
                                            marker.id !== undefined && props.selectedMarkerId === marker.id
                                                ? "yellow"
                                                : fieldingRequest.color,
                                        width: 2
                                    }),
                                    attributes: {
                                        type: "FIELDING_LINE",
                                        fieldingRequestId: fieldingRequest.id,
                                        markerId: marker.id
                                    }
                                })
                                lineMarkers = [...lineMarkers, lineMarker]
                            } catch (error) {
                                console.log(error)
                            }
                            return null
                        })
                        return null
                    })
                fieldingRequest.markers
                    .filter((marker) => marker.dotLongLat === "")
                    .map((marker) => {
                        marker.shapeData?.map((shapeData) => {
                            try {
                                textMarkers.push(
                                    new esriGraphics({
                                        geometry: new esriPoint({
                                            latitude: shapeData[0].latitude,
                                            longitude: shapeData[0].longitude
                                        }),
                                        symbol: new esriTextSymbol({
                                            color: "black",
                                            text: fieldingRequest.name,
                                            xoffset: 40,
                                            yoffset: -25,
                                            font: {
                                                size: 12,
                                                family: "Roboto"
                                            }
                                        }),
                                        attributes: {
                                            type: "FIELDING_TEXT",
                                            fieldingRequestId: fieldingRequest.id,
                                            markerId: marker.id
                                        }
                                    })
                                )
                                let shapeMarker = new esriGraphics({
                                    geometry: new esriPolygon({
                                        rings: [shapeData.map((shape) => [shape.longitude, shape.latitude])]
                                    }),
                                    symbol: new esriSimpleFillSymbol({
                                        color: [10, 10, 10, 0.5],
                                        outline: {
                                            color:
                                                marker.id !== undefined && props.selectedMarkerId === marker.id
                                                    ? "yellow"
                                                    : fieldingRequest.color,
                                            width: 2
                                        }
                                    }),
                                    attributes: {
                                        type: "FIELDING_SHAPE",
                                        fieldingRequestId: fieldingRequest.id,
                                        markerId: marker.id
                                    }
                                })
                                shapeMarkers = [...shapeMarkers, shapeMarker]
                            } catch (error) {
                                console.log(error)
                            }
                            return null
                        })
                        return null
                    })
            })
            layerMarker.removeAll()
            if (pointMarkers.length > 0) {
                layerMarker.addMany(pointMarkers)
            }
            if (lineMarkers.length > 0) {
                layerMarker.addMany(lineMarkers)
            }
            if (shapeMarkers.length > 0) {
                layerMarker.addMany(shapeMarkers)
            }
            if (textMarkers.length > 0) {
                layerMarker.addMany(textMarkers)
            }
        }
    }, [layerMarker, props.fieldingRequestList, props.selectedMarkerId])

    useEffect(() => {
        if (!props.editable) setPalleteActive(0)
        if (props.editMarkerOnly && props.editable) setPalleteActive(props.editMarkerOnly?.dotLongLat !== "" ? 1 : 0)
    }, [props.editable, props.editMarkerOnly])

    // streetview handler
    useEffect(() => {
        if (pegmanPosition !== null) {
            const loader = new Loader({
                apiKey: googleToken,
                libraries: ["places"]
            })
            loader
                .load()
                .then((google) => {
                    const mapGoogle = new google.maps.Map(googleMapsRef.current)
                    const panorama = new google.maps.StreetViewPanorama(googleMapsPanoramaRef.current, {
                        position: {
                            lat: pegmanPosition.latitude,
                            lng: pegmanPosition.longitude
                        },
                        enableCloseButton: true
                    })
                    panorama.addListener("closeclick", () => {
                        setPegmanPostion(null)
                    })
                    panorama.addListener("position_changed", () => {
                        changeMapMarker()
                        view.goTo({
                            zoom: 20,
                            center: [panorama.getPosition().lng(), panorama.getPosition().lat()]
                        })
                    })
                    panorama.addListener("pov_changed", () => {
                        changeMapMarker()
                    })
                    mapGoogle.setStreetView(panorama)
                    const changeMapMarker = () => {
                        layerMarkerPegman.removeAll()
                        layerMarkerPegman.add(
                            new esriGraphics({
                                geometry: new esriPoint({
                                    latitude: panorama.getPosition().lat(),
                                    longitude: panorama.getPosition().lng()
                                }),
                                symbol: new esriPictureMarkerSymbol({
                                    url: logo_direction,
                                    width: 16,
                                    height: 16,
                                    angle: panorama.getPov().heading
                                })
                            })
                        )
                    }
                })
                .catch((e) => {
                    console.log(e)
                })
        } else if (layerMarkerPegman) {
            layerMarkerPegman.removeAll()
        }
    }, [pegmanPosition, layerMarkerPegman, view])

    const handleFinish = () => {
        sketch?.complete()
        setPalleteActive(0)
    }

    const handleUndo = () => {
        if (palleteActive !== 0) {
            sketch?.undo()
        }
        else {
            props.onUndoMarker?.()
        }
    }

    const handleDelete = () => {
        props.onDeleteMarker?.(markerIdToDelete)
        setMarkerIdToDelete("")
    }

    return (
        <div className="map-container" style={{ flexDirection: width < 1000 ? "column" : "row" }}>
            <div className="arcgis-container">
                <div ref={mapRef} style={{ height: "100%", width: "100%" }} />
                {props.editable && (
                    <>
                        {Boolean(props.editMarkerOnly) ? (
                            <>
                                {props.editMarkerOnly.dotLongLat !== "" && (
                                    <>
                                        <div
                                            className="map-pallete top-right"
                                            style={{ background: "#ffff80", width: "50px" }}
                                        >
                                            Moving Markers:
                                            <IconButton
                                                color={palleteActive === 1 ? "primary" : undefined}
                                                onClick={() => {
                                                    setPalleteActive(palleteActive === 1 ? 0 : 1)
                                                    setIsPegmanActive(false)
                                                }}
                                            >
                                                <Circle />
                                            </IconButton>
                                        </div>
                                        {palleteActive === 1 && (
                                            <div
                                                className="map-pallete top-center"
                                                style={{ border: "1px solid #ff0000" }}
                                            >
                                                <Typography variant="h6">Ready to move marker</Typography>
                                            </div>
                                        )}
                                    </>
                                )}
                            </>
                        ) : (
                            <>
                                {
                                    props.fieldingRequestList.length > 0 && (
                                        <div className="map-pallete top-right">
                                            Markers:
                                            <Tooltip title="Point" placement="left-end">
                                                <IconButton sx={{border: palleteActive === 1 ? 1 : undefined}}
                                                            color={palleteActive === 1 ? "primary" : undefined}
                                                            onClick={() => {
                                                                setPalleteActive(palleteActive === 1 ? 0 : 1);
                                                                setIsPegmanActive(false)
                                                            }}>
                                                    <Circle/>
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Line" placement="left-end">
                                                <IconButton sx={{border: palleteActive === 2 ? 1 : undefined}}
                                                            color={palleteActive === 2 ? "primary" : undefined}
                                                            onClick={() => {
                                                                setPalleteActive(palleteActive === 2 ? 0 : 2);
                                                                setIsPegmanActive(false)
                                                            }}>
                                                    <Timeline/>
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Area" placement="left-end">
                                                <IconButton sx={{border: palleteActive === 3 ? 1 : undefined}}
                                                            color={palleteActive === 3 ? "primary" : undefined}
                                                            onClick={() => {
                                                                setPalleteActive(palleteActive === 3 ? 0 : 3);
                                                                setIsPegmanActive(false)
                                                            }}>
                                                    <ShapeLine/>
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="undo" placement="left-end">
                                                <IconButton color="primary" onClick={handleUndo}
                                                            disabled={props.fieldingIndexSelected !== undefined && props.fieldingRequestList[props.fieldingIndexSelected]?.markers.length === 0}>
                                                    <Undo/>
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="delete">
                                                <IconButton
                                                    color="primary"
                                                    disabled={markerIdToDelete === ""}
                                                    onClick={handleDelete}
                                                >
                                                    <Delete/>
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    )
                                }
                            </>
                        )}
                    </>
                )}
                {(palleteActive === 2 || palleteActive === 3) && (
                    <div className="map-pallete end" style={{backgroundColor: theme.palette.primary.main}}>
                        <Button
                            onClick={handleFinish}
                            startIcon={<Done/>}
                            sx={{color: "white"}}
                        >
                            Finish
                        </Button>
                    </div>
                )}
                <div className="map-pallete pegman">
                    <IconButton
                        onClick={() => {
                            setPalleteActive(0)
                            setIsPegmanActive(!isPegmanActive)
                        }}
                    >
                        <img src={logo_pegman} alt="pegman" height={20} style={{opacity: isPegmanActive ? 1 : 0.5}}/>
                    </IconButton>
                </div>
                {map && (
                    <div className="map-pallete map-switch">
                        <Button
                            variant={map.basemap.id === "topo-vector" ? "contained" : "outlined"}
                            onClick={() => {
                                map.set("basemap", "topo-vector");
                                setRefreshCount(last => last + 1)
                            }}
                        >
                            Map
                        </Button>
                        <Button
                            variant={map.basemap.id === "hybrid" ? "contained" : "outlined"}
                            onClick={() => {
                                map.set("basemap", "hybrid");
                                setRefreshCount(last => last + 1)
                            }}
                        >
                            Satellite
                        </Button>
                    </div>
                )}
            </div>
            {pegmanPosition !== null && (
                <div className="google-container">
                    <div ref={googleMapsRef}/>
                    <div ref={googleMapsPanoramaRef} style={{height: "100%", width: "100%"}}/>
                </div>
            )}
        </div>
    )
}
