import { Button, Checkbox, FormControlLabel, IconButton, InputAdornment, TextField, Typography } from "@mui/material"
import { FormEvent, useEffect, useState } from "react"
import icon from "../../../assets/images/icon.png"
import { useDispatch } from "react-redux"
import { login } from "../auth.api"
import Swal from "sweetalert2"
import CustomLoading from "../../../shared/CustomLoading"
import { useNavigate } from "react-router-dom"
import { Visibility, VisibilityOff } from "@mui/icons-material"
import bg_auth from "../../../assets/images/bg_auth.png"

export default function SignIn() {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [values, setValues] = useState<any>({})
    const [showPassword, setShowPassword] = useState(false)
    const [isRememberMe, setIsRememberMe] = useState(false)

    useEffect(() => {
        clearLocalStorage(true)
    }, [])

    const clearLocalStorage = async (first?: boolean) => {
        let lastLocalUser = localStorage.getItem("fielderProData")
        let lastLocalUser2 = localStorage.getItem("fielderProAccount")
        let lastLocalUser3 = localStorage.getItem("fielderProUserData")
        if (lastLocalUser || lastLocalUser2 || lastLocalUser3) {
            await localStorage.removeItem("fielderProData")
            await localStorage.removeItem("fielderProAccount")
            await localStorage.removeItem("fielderProUserData")
            await localStorage.removeItem("fielderProUserAccount")
            if (first)  setTimeout(() => {
                window.location.href = window.location.href
            }, 1000);
        }
    }

    const handleChangeValues = (e: any) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
    }

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault()
        dispatch<any>(login({ ...values, timeOffset: -420, rememberMe: isRememberMe }))
            .unwrap()
            .then((response: any) => {
                clearLocalStorage()
                if (response.status === 200) {
                    if (response.data.status) {
                        window.localStorage.setItem("fielderProUserAccount", JSON.stringify(response.data.data))
                        window.location.href = "/"
                    } else {
                        Swal.fire({
                            title: "Hello",
                            text: response.data,
                            footer: "Promblem or question ? Email us as Contact@fielderpro.com"
                        })
                    }
                } else {
                    Swal.fire({
                        title: "Hello",
                        text:
                            response.data.message ||
                            (response.data.errors
                                ? `${response.data?.errors.map((item: any) => {
                                      return ` ${item.key}`
                                  })} is required`
                                : null) ||
                            response.data,
                        footer: "Promblem or question ? Email us as Contact@fielderpro.com"
                    })
                }
            })
            .catch((err: any) => {
                Swal.fire({
                    title: "Hello",
                    text: err,
                    footer: "Promblem or question ? Email us as Contact@fielderpro.com"
                })
            })
    }

    return (
        <div className="signin">
            <CustomLoading />
            <img src={bg_auth} alt="bg" className="bg" />
            <div className="container">
                <Typography align="center">
                    <img src={icon} alt="icon" height={128} width={128} />
                </Typography>
                <Typography align="center" variant="h3" sx={{ margin: 5 }}>
                    Welcome Back!
                </Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        margin="dense"
                        fullWidth
                        onChange={handleChangeValues}
                        type="userName"
                        label="Email"
                        name="userName"
                    />
                    <TextField
                        margin="dense"
                        fullWidth
                        onChange={handleChangeValues}
                        type={showPassword ? "text" : "password"}
                        label="Password"
                        name="password"
                        InputProps={
                            values.password && {
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => setShowPassword(!showPassword)}>
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }
                        }
                    />
                    <br />
                    <FormControlLabel
                        control={<Checkbox checked={isRememberMe} onChange={(e, value) => setIsRememberMe(value)} />}
                        label="Remember me"
                    />
                    <br />
                    <br />
                    <Button type="submit" fullWidth variant="contained">
                        Login
                    </Button>
                    <Typography align="center" variant="h6" sx={{ marginTop: 5 }}>
                        Do not have account?{" "}
                        <span style={{ color: "mediumblue", cursor: "pointer" }} onClick={() => navigate("/signup")}>
                            Register New Company
                        </span>
                    </Typography>
                </form>
            </div>
        </div>
    )
}
