import { Button, ButtonProps } from "@mui/material";
import { useState } from "react";

export default function ButtonOutlined(props: ButtonProps) {

    const [isHover, setIsHover] = useState(false)

    return (
        <Button
            {...props}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            variant={isHover ? "contained" : "outlined"}
        />
    )
}