import { Autocomplete, Box, Button, Card, CardContent, IconButton, List, ListItem, TextField, Typography } from "@mui/material"
import { InterfaceFieldingNotes } from "../fielding.interface"
import { FormEvent, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { fieldingNoteCommentCreate, fieldingNoteCreate } from "../fielding.api"
import { formatName, userInfo } from "../../../shared/utils"
import { Send } from "@mui/icons-material"
import { RootState } from "../../../store"
import { getAccountFielder } from "../../account/account.api"
import { InterfaceAccountFielderData } from "../../account/account.interface"
import ButtonOutlined from "../../../shared/ButtonOutlined"
import moment from "moment/moment";

export default function FieldingNotes(props: {
    fieldingRequestId: string
    notes: Array<InterfaceFieldingNotes>
    onUpdateData: () => void
}) {
    const dispatch = useDispatch<any>()

    const { dataFielderList } = useSelector((state: RootState) => state.account)

    const [notesComment, setNotesComment] = useState("")
    const [isOnAddMode, setIsOnAddMode] = useState(false)
    const [fieldingIdList, setFieldingIdList] = useState<Array<string>>([])
    const [notes, setNotes] = useState("")

    const [comments, setComments] = useState<Array<{
        comment: string
        name: string
        dateTimeString: string
    }>>([]);

    useEffect(() => {
        dispatch(getAccountFielder())
    }, [])

    useEffect(() => {
        if (props.notes.length > 0) {
            const _comments = [...props.notes[0]?.comments];
            // sort comments by dateTimeString
            _comments.sort((a, b) => moment(a.dateTimeString).diff(moment(b.dateTimeString)));
            setComments(_comments);
        }
    }, [props.notes]);

    const handleComment = (e: FormEvent) => {
        e.preventDefault()
        dispatch(
            fieldingNoteCommentCreate({
                token: userInfo().token,
                fieldingRequestNoteId: props.notes[0].id,
                comment: notesComment
            })
        )
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    setNotesComment("")
                    props.onUpdateData()
                }
            })
    }

    const handleSubmitNotes = async () => {
        await dispatch(
            fieldingNoteCreate({
                fieldingRequestId: props.fieldingRequestId,
                token: userInfo().token,
                content: notes,
                fielderIdList: fieldingIdList
            })
        ).unwrap()
        setIsOnAddMode(false)
        props.onUpdateData()
    }

    return (
        <Box>
            {props.notes.length > 0
                ? (
                    <Box marginBottom={1}>
                        <Typography variant="body1" fontWeight={"bold"}>
                            To: {props.notes[0].fielderNames.toString()}
                        </Typography>
                        <Typography variant="body2">{props.notes[0].createdDateString}</Typography>
                        <Typography variant="body1" marginTop={1}>
                            {props.notes[0].content}
                        </Typography>
                    </Box>
                )
                : isOnAddMode
                    ? (
                        <Box>
                            <Box display={"flex"} alignItems={"center"} gap={1}>
                                <Typography variant="body1" sx={{whiteSpace: "nowrap"}}>
                                    To :
                                </Typography>
                                <Autocomplete
                                    readOnly={false}
                                    fullWidth
                                    multiple
                                    options={dataFielderList}
                                    getOptionLabel={(value: InterfaceAccountFielderData) => formatName(value.fullName)}
                                    onChange={(e, value) => setFieldingIdList(value.map((v) => v.id))}
                                    value={dataFielderList.filter((data) => fieldingIdList.includes(data.id))}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </Box>
                            <TextField
                                margin="normal"
                                placeholder="Type your notes here..."
                                multiline
                                minRows={5}
                                value={notes}
                                onChange={(e) => setNotes(e.target.value)}
                            />
                            <Box display={"flex"} justifyContent={"flex-end"} gap={1} marginTop={1}>
                                <Button variant="contained" onClick={() => setIsOnAddMode(false)}>
                                    Cancel
                                </Button>
                                <ButtonOutlined onClick={handleSubmitNotes}>
                                    Done
                                </ButtonOutlined>
                            </Box>
                        </Box>
                    )
                    : (
                        <Button
                            variant="contained"
                            onClick={() => setIsOnAddMode(true)}
                            fullWidth
                        >
                            Add Notes
                        </Button>
                    )
            }
            <List disablePadding>
                {props.notes.length > 0 && (
                    props.notes[0].comments.length > 0
                        ? (
                            comments?.map((comment, i) => (
                                <ListItem key={i}>
                                    <Card variant="outlined" sx={{ width: "100%" }}>
                                        <CardContent>
                                            <Typography variant="body1" fontWeight={"bold"}>
                                                Message:
                                            </Typography>
                                            <Typography variant="body1">{comment.comment}</Typography>
                                            <Box display={"flex"} justifyContent={"flex-end"}>
                                                <Box>
                                                    <Typography variant="body1">From: {comment.name}</Typography>
                                                    <Typography variant="body1">{comment.dateTimeString}</Typography>
                                                </Box>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </ListItem>
                            ))
                        )
                        : (
                            <ListItem>No reply</ListItem>
                        )
                )}
                {props.notes.length > 0 && (
                    <ListItem>
                        <form onSubmit={handleComment} style={{ display: "flex", flexGrow: 1 }}>
                            <TextField
                                label="Reply"
                                value={notesComment}
                                onChange={(e) => setNotesComment(e.target.value)}
                            />
                            <IconButton type="submit">
                                <Send />
                            </IconButton>
                        </form>
                    </ListItem>
                )}
            </List>
        </Box>
    )
}
