import { Box, Button, TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getExpense, rejectExpense } from "../expense.api";
import { RootState } from "../../../store";
import { useState } from "react";

export default function ExpenseReject(props: { id: string | null, onCancel: () => void, onSubmit: () => void}) {

    const dispatch = useDispatch<any>()

    const { parameterGetData } = useSelector((state: RootState) => state.expense)

    const [reason, setReason] = useState("")

    const handleReject = async () => {
        if (props.id !== null) {
            await dispatch(rejectExpense({ id: props.id, reason: reason }))
            await dispatch(getExpense(parameterGetData))
            props.onSubmit()
        }
    }

    return (
        <Box display={"flex"} flexDirection={"column"} gap={1}>
            <Typography variant="h6">Reject Reason</Typography>
            <TextField multiline minRows={5} value={reason} onChange={(e) => setReason(e.target.value)} />
            <Button variant="contained" fullWidth color="error" onClick={handleReject}>Reject</Button>
            <Button variant="contained" fullWidth color="secondary" onClick={props.onCancel}>Cancel</Button>
        </Box>
    )
}