import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../state";
import {InterfaceAccountChangePassword, InterfaceProfileUpdate} from "./myAccount.interface";
import handleError from "../../shared/handleError";
import { userInfo } from "../../shared/utils";

export const profileGet = createAsyncThunk('profileGet', async (token: string) => {
    try {
        const response = await axios({
            method: 'get',
            url: baseUrl + '/newapi/Account/FPGetProfile',
            params: { token: token }
        })
        return response
    } catch (error: any) {
        handleError(error)
        return error.response
    }
})

export const profileUpdate = createAsyncThunk('profileUpdate', async (data: InterfaceProfileUpdate) => {
    try {
        const response = await axios({
            method: 'post',
            url: baseUrl + '/newapi/Account/FPUpdateProfile',
            data: data
        })
        return response
    } catch (error: any) {
        handleError(error)
        return error.response
    }
})

export const linkToUf = createAsyncThunk('linkToUf', async (email: string) => {
    try {
        const response = await axios({
            method: 'put',
            url: baseUrl + '/newapi/Account/FPlinkToUF',
            params: {
                token: userInfo().token,
                email: email
            }
        })
        return response
    } catch (error: any) {
        handleError(error)
        return error.response
    }
})

export const unLink = createAsyncThunk('unLink', async () => {
    try {
        const response = await axios({
            method: 'put',
            url: baseUrl + '/newapi/Account/Unlink',
            params: {
                token: userInfo().token
            }
        })
        return response
    } catch (error: any) {
        handleError(error)
        return error.response
    }
})

export const changePassword = createAsyncThunk('changePassword', async (data: InterfaceAccountChangePassword) => {
    try {
        const response = await axios({
            method: 'post',
            url: baseUrl + '/api/Account/ChangePassword',
            data: data
        })
        return response
    } catch (error: any) {
        handleError(error)
        return error.response
    }
})