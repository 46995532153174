import { AdsClick, Save, Visibility, VisibilityOff } from "@mui/icons-material"
import {
    Box,
    Button,
    Card,
    CardContent,
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    Typography
} from "@mui/material"
import { FormEvent, Fragment, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { createAccount, editAccount, getAccount } from "../account.api"
import notificationSuccess from "../../../shared/notificationSuccess"
import notificationError from "../../../shared/notificationError"
import { formatName, isEmptyString, userInfo } from "../../../shared/utils"
import { RootState } from "../../../store"
import { InitialAccountCreate, InterfaceAccountCreate, InterfaceAccountData } from "../account.interface"
import CustomModal from "../../../shared/customModal/CustomModal"
import MyAccountArea from "../../myaccount/component/MyAccountArea"
import { InterfaceMarkerLine, InterfaceMarkerPoint } from "../../fielding/fielding.interface"
import MyAccountChooseAddress from "../../myaccount/component/MyAccountChooseAddress"
import Swal from "sweetalert2"
import ButtonOutlined from "../../../shared/ButtonOutlined"
import useAddress from "../../../shared/useAddress"

export default function AccountForm(props: { data?: InterfaceAccountData; type: "create" | "edit"; onClose: any }) {
    const dispatch = useDispatch<any>()
    const address = useAddress()

    const { parameterGetData } = useSelector((state: RootState) => state.account)

    const [values, setValues] = useState<InterfaceAccountCreate | any>(InitialAccountCreate)
    const [showPass, setShowPass] = useState(false)
    const [showRePass, setShowRePass] = useState(false)
    const [modalMap, setModalMap] = useState(false)
    const [center, setCenter] = useState<InterfaceMarkerPoint | null>(null)
    const [shape, setShape] = useState<InterfaceMarkerLine>([])
    const [modalMapChoose, setModalMapChoose] = useState(false)
    const [isEditingRoles, setIsEditingRoles] = useState(false)
    const [tempAddress, setTempAddress] = useState("")
    const [tempShape, setTempShape] = useState<InterfaceMarkerLine>([])

    useEffect(() => {
        if (props.data?.id) {
            setValues({
                firstName: props.data?.firstName,
                lastName: props.data?.lastName,
                companyId: props.data?.companyId,
                email: props.data?.email,
                password: "",
                passwordConfirm: "",
                roles: {
                    fpCompanyAdmin: props.data?.roles.includes("FPCompanyAdmin"),
                    fieldingManager: props.data?.roles.includes("FieldingManager"),
                    fieldingRequestSimple: props.data?.roles.includes("FieldingRequestSimple"),
                    fielderSimple: props.data?.roles.includes("FielderSimple"),
                    paymentProcessor: props.data?.roles.includes("PaymentProcessor"),
                    fieldingManagerCoverageArea: props.data?.roles.includes("FieldingManagerCoverageArea"),
                    companyAdminCreateUser: props.data?.roles.includes("CompanyAdminCreateUser"),
                    requestorCreateUser: props.data?.roles.includes("RequestorCreateUser"),
                    companyAdminEditUser: props.data?.roles.includes("CompanyAdminEditUser"),
                    companyAdminAddProject: props.data?.roles.includes("CompanyAdminAddProject"),
                    requestorAddProject: props.data?.roles.includes("RequestorAddProject"),
                    companyAdminAssignFielder: props.data?.roles.includes("CompanyAdminAssignFielder"),
                    fieldingManagerAssignFielder: props.data?.roles.includes("FieldingManagerAssignFielder"),
                    requestorAssignFielder: props.data?.roles.includes("RequestorAssignFielder"),
                    companyAdminCompanyStorage: props.data?.roles.includes("CompanyAdminCompanyStorage"),
                    billingCompanyStorage: props.data?.roles.includes("BillingCompanyStorage"),
                    fielderGoFielding: props.data?.roles.includes("FielderGoFielding"),
                    billingCreateStatement: props.data?.roles.includes("BillingCreateStatement"),
                    billingViewTransactionHistory: props.data?.roles.includes("BillingViewTransactionHistory"),
                    billingBlastMail: props.data?.roles.includes("BillingBlastMail")
                },
                address: props.data?.address,
                workingAreaPolyData: props.data?.workingAreaPolyData,
                phoneNumber: ""
            })
            setShape(JSON.parse(props.data?.workingAreaPolyData) || [])
        } else {
            setValues({
                ...InitialAccountCreate,
                companyId: userInfo().user.companyId
            })
        }
    }, [props.data])

    useEffect(() => {
        if (values.address) {
            address.getCoordinate(values.address).then((response) => {
                setCenter({
                    latitude: response.latitude,
                    longitude: response.longitude
                })
            })
        }
    }, [values.address])

    useEffect(() => {
        if (isEditingRoles) {
            setValues({
                ...values,
                roles: {
                    ...values.roles,
                    fpCompanyAdmin:
                        values.roles.companyAdminCreateUser ||
                        values.roles.companyAdminEditUser ||
                        values.roles.companyAdminAddProject ||
                        values.roles.companyAdminAssignFielder ||
                        values.roles.companyAdminCompanyStorage,
                    fieldingManager: values.roles.fieldingManagerAssignFielder,
                    fieldingRequestSimple:
                        values.roles.requestorCreateUser ||
                        values.roles.requestorAddProject ||
                        values.roles.requestorAssignFielder,
                    fielderSimple: values.roles.fielderGoFielding,
                    paymentProcessor:
                        values.roles.billingCreateStatement ||
                        values.roles.billingViewTransactionHistory ||
                        values.roles.billingBlastMail ||
                        values.roles.billingCompanyStorage
                }
            })
        }
    }, [values.roles])

    const handleChange = (e: any) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
    }
    const handleRoles = (e: any) => {
        setIsEditingRoles(true)
        const { name, checked } = e.target
        setValues({
            ...values,
            roles: {
                ...values.roles,
                [name]: checked
            }
        })
    }

    const handleChangeCompanyAdmin = (e: any, checked: boolean) => {
        setValues({
            ...values,
            roles: {
                ...values.roles,
                fpCompanyAdmin: checked,
                companyAdminCreateUser: checked,
                companyAdminEditUser: checked,
                companyAdminAddProject: checked,
                companyAdminAssignFielder: checked,
                companyAdminCompanyStorage: checked
            }
        })
    }
    const handleChangeAssignFielding = (e: any, checked: boolean) => {
        setValues({
            ...values,
            roles: {
                ...values.roles,
                fieldingManager: checked,
                fieldingManagerAssignFielder: checked
            }
        })
    }
    const handleChangeRequestor = (e: any, checked: boolean) => {
        setValues({
            ...values,
            roles: {
                ...values.roles,
                fieldingRequestSimple: checked,
                requestorCreateUser: checked,
                requestorAddProject: checked,
                requestorAssignFielder: checked
            }
        })
    }
    const handleChangeFielder = (e: any, checked: boolean) => {
        setValues({
            ...values,
            roles: {
                ...values.roles,
                fielderSimple: checked,
                fielderGoFielding: checked
            }
        })
    }
    const handleChangeBilling = (e: any, checked: boolean) => {
        setValues({
            ...values,
            roles: {
                ...values.roles,
                paymentProcessor: checked,
                billingCreateStatement: checked,
                billingViewTransactionHistory: checked,
                billingBlastMail: checked,
                billingCompanyStorage: checked
            }
        })
    }

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault()
        let roles = Object.keys(values.roles)
        let check: any = roles.map((key: string) => values.roles[key]).filter((val: boolean) => val === false)
        if (check.length === roles.length) {
            Swal.fire({
                icon: "warning",
                title: "Please select user role!",
            })
            return
        }
        if (props.type === "create") {
            dispatch(
                createAccount({
                    ...values,
                    workingAreaPolyData: JSON.stringify(shape)
                })
            )
                .unwrap()
                .then((response: any) => {
                    if (response.status === 200) {
                        notificationSuccess("account successfully created").then(() => {
                            dispatch(getAccount(parameterGetData))
                            props.onClose()
                        })
                    } else {
                        notificationError(response)
                    }
                })
        } else if (props.type === "edit") {
            dispatch(
                editAccount({
                    ...values,
                    token: userInfo().token,
                    id: props.data?.id || "",
                    workingAreaPolyData: JSON.stringify(shape)
                })
            )
                .unwrap()
                .then((response: any) => {
                    if (response.status === 200) {
                        notificationSuccess("").then(() => {
                            dispatch(getAccount(parameterGetData))
                            props.onClose()
                        })
                    } else {
                        notificationError(response)
                    }
                })
        }
    }

    return (
        <div>
            <CustomModal
                open={modalMapChoose}
                onClose={() => setModalMapChoose(false)}
                title="Choose Address"
                component={
                    <div className="working-area">
                        <MyAccountChooseAddress
                            center={center}
                            setAddress={setTempAddress}
                            onDone={() => setValues({ ...values, address: tempAddress })}
                        />
                    </div>
                }
            />
            <CustomModal
                open={modalMap}
                onClose={() => setModalMap(false)}
                title="Working Area"
                component={
                    <div className="working-area">
                        <MyAccountArea center={center} shape={shape} setShape={setTempShape} onDone={() => setShape(tempShape)} />
                    </div>
                }
            />
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2} sx={{ alignItems: "flex-start" }}>
                    <Grid item md={6}>
                        <TextField
                            label="First Name"
                            name="firstName"
                            value={formatName(values.firstName)}
                            onChange={handleChange}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            label="Last Name"
                            name="lastName"
                            value={formatName(values.lastName)}
                            onChange={handleChange}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            label="Email"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            type="email"
                            fullWidth
                            required
                        />
                    </Grid>
                    {props.type === "create" && (
                        <Fragment>
                            <Grid item md={6}>
                                <TextField
                                    label="Phone"
                                    name="phoneNumber"
                                    value={values.phoneNumber}
                                    onChange={handleChange}
                                    type="tel"
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item md={6}>
                                <TextField
                                    label="Password"
                                    name="password"
                                    value={values.password}
                                    onChange={handleChange}
                                    type={showPass ? "text" : "password"}
                                    helperText={
                                        <span className="helper-text">
                                            Password must be at least 8 characters long, includes upper and lower case
                                            letter, at least 1 number, and not matching to the email used
                                        </span>
                                    }
                                    fullWidth
                                    required
                                    inputProps={{
                                        minLength: 8
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => setShowPass(!showPass)}>
                                                    {showPass ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <TextField
                                    label="Password Confirmation"
                                    name="passwordConfirm"
                                    value={values.passwordConfirm}
                                    onChange={handleChange}
                                    type={showRePass ? "text" : "password"}
                                    fullWidth
                                    required
                                    inputProps={{
                                        minLength: 8
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => setShowRePass(!showRePass)}>
                                                    {showRePass ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                        </Fragment>
                    )}
                    <Grid item md={5}>
                        <TextField
                            fullWidth
                            label="Start Location"
                            name="address"
                            value={values.address || ""}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item md={1}>
                        <IconButton onClick={() => setModalMapChoose(true)}>
                            <AdsClick />
                        </IconButton>
                    </Grid>
                    {values.roles.fielderSimple && (
                        <Fragment>
                            <Grid item md={props.type === "create" ? 6 : 12}>
                                <ButtonOutlined fullWidth onClick={() => setModalMap(true)}>
                                    Working Area
                                </ButtonOutlined>
                            </Grid>
                        </Fragment>
                    )}
                    <Grid item md={12}>
                        <span>User Role :</span>
                        <Grid container spacing={2} alignItems={"flex-start"}>
                            <Grid item sm={4} lg={3}>
                                <Card>
                                    <CardContent>
                                        <FormControlLabel
                                            control={<Checkbox checked={values.roles.fpCompanyAdmin} />}
                                            onChange={handleChangeCompanyAdmin}
                                            label="Company Admin"
                                        />
                                        <Box paddingLeft={2} display={"flex"} flexDirection={"column"}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={values.roles.companyAdminCreateUser}
                                                        name="companyAdminCreateUser"
                                                        onChange={handleRoles}
                                                    />
                                                }
                                                label="Create User"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={values.roles.companyAdminEditUser}
                                                        name="companyAdminEditUser"
                                                        onChange={handleRoles}
                                                    />
                                                }
                                                label="Edit User"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={values.roles.companyAdminAddProject}
                                                        name="companyAdminAddProject"
                                                        onChange={handleRoles}
                                                    />
                                                }
                                                label="Add Project"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={values.roles.companyAdminAssignFielder}
                                                        name="companyAdminAssignFielder"
                                                        onChange={handleRoles}
                                                    />
                                                }
                                                label="Assign to a Fielder"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={values.roles.companyAdminCompanyStorage}
                                                        name="companyAdminCompanyStorage"
                                                        onChange={handleRoles}
                                                    />
                                                }
                                                label="Checking Company Storage"
                                            />
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item sm={4} lg={3}>
                                <Card>
                                    <CardContent>
                                        <FormControlLabel
                                            control={<Checkbox checked={values.roles.fieldingManager} />}
                                            onChange={handleChangeAssignFielding}
                                            label="Assign Fielding"
                                        />
                                        <Box paddingLeft={2} display={"flex"} flexDirection={"column"}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={values.roles.fieldingManagerAssignFielder}
                                                        name="fieldingManagerAssignFielder"
                                                        onChange={handleRoles}
                                                    />
                                                }
                                                label="Assign to a Fielder"
                                            />
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item sm={4} lg={3}>
                                <Card>
                                    <CardContent>
                                        <FormControlLabel
                                            control={<Checkbox checked={values.roles.fieldingRequestSimple} />}
                                            onChange={handleChangeRequestor}
                                            label="Requestor"
                                        />
                                        <Box paddingLeft={2} display={"flex"} flexDirection={"column"}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={values.roles.requestorCreateUser}
                                                        name="requestorCreateUser"
                                                        onChange={handleRoles}
                                                    />
                                                }
                                                label="Create User"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={values.roles.requestorAddProject}
                                                        name="requestorAddProject"
                                                        onChange={handleRoles}
                                                    />
                                                }
                                                label="Add Project"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={values.roles.requestorAssignFielder}
                                                        name="requestorAssignFielder"
                                                        onChange={handleRoles}
                                                    />
                                                }
                                                label="Assign to a Fielder"
                                            />
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item sm={4} lg={3}>
                                <Card>
                                    <CardContent>
                                        <FormControlLabel
                                            control={<Checkbox checked={values.roles.fielderSimple} />}
                                            onChange={handleChangeFielder}
                                            label="Fielder"
                                        />
                                        <Box paddingLeft={2} display={"flex"} flexDirection={"column"}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={values.roles.fielderGoFielding}
                                                        name="fielderGoFielding"
                                                        onChange={handleRoles}
                                                    />
                                                }
                                                label="Go Fielding"
                                            />
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item sm={4} lg={3}>
                                <Card>
                                    <CardContent>
                                        <FormControlLabel
                                            control={<Checkbox checked={values.roles.paymentProcessor} />}
                                            onChange={handleChangeBilling}
                                            label="Billing"
                                        />
                                        <Box paddingLeft={2} display={"flex"} flexDirection={"column"}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={values.roles.billingCreateStatement}
                                                        name="billingCreateStatement"
                                                        onChange={handleRoles}
                                                    />
                                                }
                                                label="Make Statement"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={values.roles.billingViewTransactionHistory}
                                                        name="billingViewTransactionHistory"
                                                        onChange={handleRoles}
                                                    />
                                                }
                                                label="Check Transaction History"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={values.roles.billingBlastMail}
                                                        name="billingBlastMail"
                                                        onChange={handleRoles}
                                                    />
                                                }
                                                label="Blast Mail"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={values.roles.billingCompanyStorage}
                                                        name="billingCompanyStorage"
                                                        onChange={handleRoles}
                                                    />
                                                }
                                                label="Checking Company Storage"
                                            />
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                    {!isEmptyString(props.data?.lastModifiedDate || "") && (
                        <Grid item md={12}>
                            <Typography variant="body1" align="right">
                                Last modified {props.data?.lastModifiedDate} by {props.data?.lastModifiedBy}
                            </Typography>
                        </Grid>
                    )}
                    <Grid item md={12}>
                        <div className="wrapbutton">
                            <Button variant="contained" fullWidth type="submit" startIcon={<Save />}>
                                {" "}
                                Save
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </form>
        </div>
    )
}
