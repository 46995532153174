import {Box, Button, Divider, Grid, Typography, useTheme} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store";
import {useNavigate} from "react-router-dom";
import hot from "../../../assets/images/hot.png"
import {Check, Delete, East, Edit, PersonRemove} from "@mui/icons-material";
import notificationDelete from "../../../shared/notificationDelete";
import {projectComplete, projectDelete} from "../../project/project.api";
import {useEffect} from "react";
import {getDashboardProject, getDashboardRequestProject} from "../dashboard.api";

export default function DashboardProject(props: {
    dateStart: string,
    dateEnd: string,
    status?: number,
    isTotalFieldingRequest?: boolean,
    onSelect: (projectId: string) => void
}) {

    const theme = useTheme()
    const navigate = useNavigate()
    const dispatch = useDispatch<any>()

    const {projectList} = useSelector((state: RootState) => state.dashboard)

    useEffect(() => {
        if (props?.isTotalFieldingRequest) {
            dispatch(getDashboardRequestProject({
                filterStartDate: props.dateStart,
                filterEndData: props.dateEnd,
                status: props.status || 0
            }))
        } else {
            dispatch(getDashboardProject({
                filterStartDate: props.dateStart,
                filterEndData: props.dateEnd,
                status: props.status || 0
            }))
        }

    }, [props?.isTotalFieldingRequest])

    const handleDelete = (id: string) => {
        notificationDelete().then((isConfirmed) => {
            if (isConfirmed) {
                dispatch(projectDelete(id))
                    .unwrap()
                    .then(() => {
                        if (props?.isTotalFieldingRequest) {
                            dispatch(getDashboardRequestProject({
                                filterStartDate: props.dateStart,
                                filterEndData: props.dateEnd,
                                status: props.status || 0
                            }))
                        } else {
                            dispatch(getDashboardProject({
                                filterStartDate: props.dateStart,
                                filterEndData: props.dateEnd,
                                status: props.status || 0
                            }))
                        }
                    })
            }
        })
    }

    const handleComplete = (projectId: string) => {
        dispatch(projectComplete(projectId))
            .unwrap()
            .then(() => {
                if (props?.isTotalFieldingRequest) {
                    dispatch(getDashboardRequestProject({
                        filterStartDate: props.dateStart,
                        filterEndData: props.dateEnd,
                        status: props.status || 0
                    }))
                } else {
                    dispatch(getDashboardProject({
                        filterStartDate: props.dateStart,
                        filterEndData: props.dateEnd,
                        status: props.status || 0
                    }))
                }
            })
    }

    return (
        <Box>
            {projectList.map((project, projectIndex) => {
                return (
                    <Box
                        borderRadius={1}
                        bgcolor={theme.palette.background.paper}
                        key={project.id}
                        sx={{
                            marginTop: projectIndex === 0 ? 0 : 2,
                            marginBottom: projectIndex === projectList.length - 1 ? 8 : 2
                        }}
                    >
                        <Box
                            padding={2}
                        >
                            <Grid container spacing={2} alignItems={"flex-start"}>
                                <Grid item md={2}>
                                    <Typography variant="body1" fontWeight={"bold"} marginLeft={1}>Project</Typography>
                                    <Button
                                        onClick={() => navigate(`/project/detail?id=${project.id}`)}
                                        style={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            width: "-webkit-fill-available"
                                        }}
                                        color={project.isHotJob ? "error" : "primary"}
                                    >
                                        <Box display={"flex"} alignItems={"center"} gap={1}>
                                            <span style={{textAlign: "left"}}>
                                                {project.projectNumber}
                                            </span>
                                            {project.isHotJob && (
                                                <img src={hot} alt="hot" style={{width: "20px"}}/>
                                            )}
                                        </Box>
                                    </Button>
                                    <Typography variant="caption" fontStyle={"italic"} color={"grey"} marginLeft={1}>Created
                                        by {project.createdByName} at {project.createdDateString}</Typography>
                                </Grid>
                                <Grid item md={3}>
                                    <Typography variant="body1" fontWeight={"bold"}>Project Customer Code</Typography>
                                    <Typography variant="body1">{project.customerCodeString}</Typography>
                                </Grid>
                                <Grid item md={3}>
                                    <Typography variant="body1" fontWeight={"bold"}>Location</Typography>
                                    <Typography variant="body1">{project.fpAddress}</Typography>
                                </Grid>
                                <Grid item md={4} lg={1}>
                                    <Typography variant="body1" fontWeight={"bold"}>Due Date</Typography>
                                    <Typography variant="body1">{project.dueDateString}</Typography>
                                </Grid>
                                <Grid item md={12} lg={3} display={"flex"} flexGrow={1} alignItems={"flex-end"}
                                      justifyContent={"flex-end"}>
                                    <Button
                                        color="error"
                                        startIcon={<Delete/>}
                                        onClick={() => handleDelete(project.id)}
                                    >
                                        Delete
                                    </Button>
                                    <Button
                                        color="success"
                                        startIcon={<Edit/>}
                                        onClick={() => navigate(`/project/detail?id=${project.id}`)}
                                    >
                                        Edit
                                    </Button>
                                    {
                                        project.fpProjectStatus === 0 && (
                                            <Button
                                                color="warning"
                                                startIcon={<Check/>}
                                                onClick={() => handleComplete(project.id)}
                                            >
                                                Complete Project
                                            </Button>
                                        )
                                    }
                                </Grid>
                            </Grid>
                        </Box>
                        <Divider/>
                        <Box
                            padding={2}
                            display={"flex"}
                            justifyContent={"space-between"}
                        >
                            <Box>
                                <Typography variant="body1" fontWeight={"light"} display={"inline"}>qty </Typography>
                                <Typography variant="body1" fontWeight={"bold"}
                                            display={"inline"}>{project.fieldingRequestCount} Request</Typography>
                            </Box>
                            <Box>
                                <Button
                                    startIcon={project.projectFielderIds.length === 0 ?
                                        <PersonRemove color="error"/> : undefined}
                                    endIcon={<East/>}
                                    onClick={() => props.onSelect(project.id)}
                                >
                                    View Request Detail
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                )
            })}
        </Box>
    )
}