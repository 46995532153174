import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../state";
import { userInfo } from "../../shared/utils";
import handleError from "../../shared/handleError";

export const getFieldingRequestByCalendar = createAsyncThunk('getFieldingRequestByCalendar', async (data: { fielderId?: string, month: number, year: number }) => {
    try {
        const response = await axios({
            method: 'post',
            url: baseUrl + '/newapi/FieldingRequest/GetListForCalendar',
            data: {
                token: userInfo().token,
                ...data
            }
        });
        return response;
    } catch (error: any) {
        handleError(error);
        return error.response;
    }
});