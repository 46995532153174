export interface AuthCompanyInterfaceCreate {
  name: string,
  phone: string,
  address: string,
  suite: string,
  unitNumber: string,
  city: string,
  zipCode: string,
  address2: string,
  suite2: string,
  unitNumber2: string,
  city2: string,
  zipCode2: string,
  email: string,
  password: string,
  passwordConfirm: string,
  firstName: string
}

export interface AuthCompanyActivate {
  token: string,
  email: string
}



export const AuthCompanyInitialCreate: AuthCompanyInterfaceCreate = {
  name: "",
  phone: "",
  address: "",
  suite: "",
  unitNumber: "",
  city: "",
  zipCode: "",
  address2: "",
  suite2: "",
  unitNumber2: "",
  city2: "",
  zipCode2: "",
  email: "",
  password: "",
  passwordConfirm: "",
  firstName: ""
}
