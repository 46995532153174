export interface InterfaceExpense {
    id: string,
    project: string,
    fieldingRequestId: string,
    fieldingRequest: string,
    createdDate: string,
    createdDateString: string,
    createdById: string,
    createdBy: string,
    status: number,
    statusString: string,
    notes: string,
    amount: number
    docNumber: string,
    processedDate: string,
    processedDateString: string,
}

export interface InterfaceExpenseCreate {
    fieldingRequestId?: string,
    amount: string,
    notes: string,
    docNumber?: string,
    processedDate?: string,
}

export interface InterfaceExpenseDetail extends InterfaceExpense {
    attachments: {
        id: string,
        fileName: string,
        filePath: string
    }[],
    modifiedDate: string,
    modifiedDateString: string,
    approvalMessage: string
}

export interface InterfaceExpenseGet {
    skip: number,
    take: number,
    startDateString: string,
    endDateString: string,
    filter: string,
    status: number
}

export const InitialExpenseGet: InterfaceExpenseGet = {
    skip: 0,
    take: 50,
    startDateString: '',
    endDateString: '',
    filter: '',
    status: 0
}

export const InitialExpenseCreate: InterfaceExpenseCreate = {
    amount: '0',
    notes: '',
    docNumber: '',
    processedDate: ''
}

export interface InterfaceMileage {
    project: string;
    fieldingRequest: string;
    assignedFielder: string;
    lastUpdate: string;
    mileage: string;
}

export interface InterfaceMileageGet {
    filter: string,
    filterStartDate: string,
    filterEndDate: string,
    fielderId: string,
    projectStatus?: number
}

export const InitialMileageGet: InterfaceMileageGet = {
    filter: '',
    filterStartDate: '',
    filterEndDate: '',
    fielderId: '',
    projectStatus: 0
}