import {useEffect, useRef, useState} from "react";
import {esriToken} from "../../../state";

import esriConfig from "@arcgis/core/config"
import esriMap from "@arcgis/core/Map"
import esriMapView from "@arcgis/core/views/MapView"
import esriGraphicsLayer from "@arcgis/core/layers/GraphicsLayer"
import esriZoom from "@arcgis/core/widgets/Zoom"
import esriTrack from "@arcgis/core/widgets/Track"
import esriSearch from "@arcgis/core/widgets/Search"
import esriGraphics from "@arcgis/core/Graphic"
import esriPoint from "@arcgis/core/geometry/Point"
import esriMarkerSymbol from "@arcgis/core/symbols/SimpleMarkerSymbol"
import esriTextSymbol from "@arcgis/core/symbols/TextSymbol"
import esriPictureMarkerSymbol from "@arcgis/core/symbols/PictureMarkerSymbol"
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store";
import {fieldingAssignedActiveGet, fieldingAssignedGet} from "../fielding.api";
import hot from "../../../assets/images/hot.png"
import {
    InterfaceFieldingAssignedActive,
    InterfaceFieldingData,
    InterfaceFieldingDataByDistance
} from "../fielding.interface";
import haversine from "haversine";
import Swal from "sweetalert2";
import {Box, Button, Chip, Grid, IconButton, Typography, useMediaQuery} from "@mui/material";
import progressColor from "../../../shared/progressColor";
import {Edit, Image, PlayArrowOutlined} from "@mui/icons-material";
import FieldingStart from "./FieldingStart";
import RoomIcon from "@mui/icons-material/Room";
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import CustomModal from "../../../shared/customModal/CustomModal";
import FieldingNotes from "./FieldingNotes";
import FieldingUploadImages from "./FieldingUploadImages";

export default function FieldingMap() {

    const mapRef = useRef<any>()
    const dispatch = useDispatch<any>()
    const isMobile = useMediaQuery("(max-width:768px)")

    const originDataList = useSelector((state: RootState) => state.fielding).dataMapList
    const [dataMapList, setDataMapList] = useState<Array<InterfaceFieldingAssignedActive & { distance: number }>>([])
    const [selectedDataMap, setSelectedDataMap] = useState<InterfaceFieldingAssignedActive & {
        distance: number
    } | null>(null)
    const [selectedId, setSelectedId] = useState<string>("")

    const [location, setLocation] = useState<{latitude: number, longitude: number} | null>(null);

    const [modalImages, setModalImages] = useState(false)
    const [modalNotes, setModalNotes] = useState(false)

    const [center, setCenter] = useState(dataMapList.length > 0
        ? {
            latitude: parseFloat(dataMapList[0]?.firstMarkerLocation?.split(',')[1]),
            longitude: parseFloat(dataMapList[0]?.firstMarkerLocation?.split(',')[0])
        }
        : null)

    const loading = useSelector((state: RootState) => state.fielding).loading
    // const center = dataMapList.length > 0
    //     ? {
    //         latitude: parseFloat(dataMapList[0].firstMarkerLocation.split(',')[1]),
    //         longitude: parseFloat(dataMapList[0].firstMarkerLocation.split(',')[0])
    //     }
    //     : null

    useEffect(() => {
        dispatch(fieldingAssignedActiveGet())
    }, [dispatch])

    const [layerMarker, setLayerMarker] = useState<any>()

    const [isNearestFeature, setIsNearestFeature] = useState(false)

    useEffect(() => {
        // initialize
        if (!loading) {
            esriConfig.apiKey = esriToken
            let map = new esriMap({
                basemap: "topo-vector" //"satellite"
            })
            let layerMarkerLocal = new esriGraphicsLayer()
            let layerMarkerTempLocal = new esriGraphicsLayer()
            map.add(layerMarkerLocal)
            map.add(layerMarkerTempLocal)
            let view = new esriMapView({
                container: mapRef.current,
                map: map,
                zoom: center !== null ? 15 : 11,
                center: center !== null ? [center.longitude, center.latitude] : [-99.09764528271961, 33.173506394266624],
            })
            // widget
            let viewZoom = new esriZoom({view: view})
            view.ui.remove(viewZoom)
            let viewSearch = new esriSearch({view: view, locationEnabled: false})
            view.ui.add(viewSearch, {position: "top-left"})
            // widget track with custom class name
            let viewTrack = new esriTrack({view: view, goToLocationEnabled: true})
            view.ui.add(viewTrack, {position: "top-left"})
            // listener
            view.on("layerview-create", () => {
                if (document.getElementById("searchbox-input")) {
                    (document.getElementById("searchbox-input") as HTMLInputElement).placeholder = "Enter adress or GPS"
                }
                // esri-track
                if (document.getElementsByClassName("esri-track")) {
                    let el = document.getElementsByClassName("esri-track")[0]
                    el.getElementsByClassName("esri-widget--button")[0].innerHTML = "<span>Track My Location</span>"
                }
            })

            setLayerMarker(layerMarkerLocal)
            return () => view?.destroy()
        }
    }, [loading, center])

    // useEffect(() => {
    //     // check if geolocation is supported
    //     navigator.permissions.query({name: "geolocation"}).then((result) => {
    //         console.log(result);
    //         if (result.state === "granted") {
    //
    //             if (navigator.geolocation.getCurrentPosition) {
    //                 navigator.geolocation.getCurrentPosition(function (position) {
    //                     const latitude = position.coords.latitude;
    //                     const longitude = position.coords.longitude;
    //                     console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
    //
    //
    //                     // add distance
    //                     let dataWithDistance: Array<InterfaceFieldingAssignedActive & {
    //                         distance: number
    //                     }> = originDataList.map((item: InterfaceFieldingAssignedActive) => ({
    //                         ...item,
    //                         distance: haversine({
    //                             latitude: parseFloat(item.firstMarkerLocation.split(',')[1]),
    //                             longitude: parseFloat(item.firstMarkerLocation.split(',')[0])
    //                         }, {
    //                             latitude: latitude,
    //                             longitude: longitude
    //                         }, {unit: 'mile'})
    //                     }))
    //
    //                     // sort dataWithDistance distance from lower to higher
    //                     dataWithDistance?.sort((a: any, b: any) => a.distance - b.distance);
    //
    //                     // default selectedId
    //                     setSelectedId(dataWithDistance[0]?.id)
    //                     setCenter({
    //                         latitude: latitude,
    //                         longitude: longitude
    //                     })
    //                     setDataMapList(dataWithDistance);
    //                     setIsNearestFeature(true);
    //                 });
    //             } else {
    //                 // add distance
    //                 let dataWithDistance: Array<InterfaceFieldingAssignedActive & {
    //                     distance: number
    //                 }> = originDataList.map((item: InterfaceFieldingAssignedActive) => ({
    //                     ...item,
    //                     distance: 0
    //                 }))
    //                 setDataMapList(dataWithDistance);
    //                 setSelectedId(dataWithDistance[0]?.id);
    //                 setCenter({
    //                     latitude: parseFloat(dataWithDistance[0].firstMarkerLocation.split(',')[1]),
    //                     longitude: parseFloat(dataWithDistance[0].firstMarkerLocation.split(',')[0])
    //                 })
    //                 setIsNearestFeature(false);
    //             }
    //
    //         } else if (result.state === "prompt") {
    //             console.log("Geolocation is not supported by this browser.");
    //             Swal.fire({
    //                 icon: "warning",
    //                 html: `To utilize the nearest feature, you'll need to grant your browser permission to access your location. `,
    //                 showConfirmButton: true,
    //                 showCancelButton: false,
    //                 confirmButtonText: "OK",
    //             })
    //             // add distance
    //             let dataWithDistance: Array<InterfaceFieldingAssignedActive & {
    //                 distance: number
    //             }> = originDataList.map((item: InterfaceFieldingAssignedActive) => ({
    //                 ...item,
    //                 distance: 0
    //             }))
    //             setDataMapList(dataWithDistance);
    //             setSelectedId(dataWithDistance[0]?.id);
    //             setCenter({
    //                 latitude: parseFloat(dataWithDistance[0].firstMarkerLocation.split(',')[1]),
    //                 longitude: parseFloat(dataWithDistance[0].firstMarkerLocation.split(',')[0])
    //             })
    //             setIsNearestFeature(false);
    //         } else if (result.state === "denied") {
    //             Swal.fire({
    //                 icon: "warning",
    //                 html: `To utilize the nearest feature, you'll need to grant your browser permission to access your location. `,
    //                 showConfirmButton: true,
    //                 showCancelButton: false,
    //                 confirmButtonText: "OK",
    //             })
    //             console.log("Geolocation is not supported by this browser.");
    //             // add distance
    //             let dataWithDistance: Array<InterfaceFieldingAssignedActive & {
    //                 distance: number
    //             }> = originDataList.map((item: InterfaceFieldingAssignedActive) => ({
    //                 ...item,
    //                 distance: 0
    //             }))
    //             setDataMapList(dataWithDistance);
    //             setSelectedId(dataWithDistance[0]?.id);
    //             setCenter({
    //                 latitude: parseFloat(dataWithDistance[0].firstMarkerLocation.split(',')[1]),
    //                 longitude: parseFloat(dataWithDistance[0].firstMarkerLocation.split(',')[0])
    //             })
    //             setIsNearestFeature(false);
    //         } else {
    //             console.log("Geolocation is not supported by this browser.");
    //             Swal.fire({
    //                 icon: "warning",
    //                 html: `To utilize the nearest feature, you'll need to grant your browser permission to access your location. `,
    //                 showConfirmButton: true,
    //                 showCancelButton: false,
    //                 confirmButtonText: "OK",
    //             })
    //             // add distance
    //             let dataWithDistance: Array<InterfaceFieldingAssignedActive & {
    //                 distance: number
    //             }> = originDataList.map((item: InterfaceFieldingAssignedActive) => ({
    //                 ...item,
    //                 distance: 0
    //             }))
    //             setDataMapList(dataWithDistance);
    //             setSelectedId(dataWithDistance[0]?.id);
    //             setCenter({
    //                 latitude: parseFloat(dataWithDistance[0].firstMarkerLocation.split(',')[1]),
    //                 longitude: parseFloat(dataWithDistance[0].firstMarkerLocation.split(',')[0])
    //             })
    //             setIsNearestFeature(false);
    //         }
    //     });
    // }, [originDataList, navigator]);

    useEffect(() => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setLocation({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    });
                },
                (err) => {
                    Swal.fire({
                        icon: "warning",
                        html: err.message,
                        showConfirmButton: true,
                        showCancelButton: false,
                        confirmButtonText: "OK",
                    })
                }
            );
        } else {
            Swal.fire({
                icon: "warning",
                html: `Geolocation is not supported by your browser`,
                showConfirmButton: true,
                showCancelButton: false,
                confirmButtonText: "OK",
            })
        }
    }, []);

    useEffect(() => {
        if (location) {
            // add distance
            // add distance
            let dataWithDistance: Array<InterfaceFieldingAssignedActive & {
                distance: number
            }> = originDataList.map((item: InterfaceFieldingAssignedActive) => ({
                ...item,
                distance: haversine({
                    latitude: parseFloat(item?.firstMarkerLocation?.split(',')[1]),
                    longitude: parseFloat(item?.firstMarkerLocation?.split(',')[0])
                }, {
                    latitude: location.latitude,
                    longitude: location.longitude
                }, {unit: 'mile'})
            }))

            // sort dataWithDistance distance from lower to higher
            dataWithDistance?.sort((a: any, b: any) => a.distance - b.distance);

            // default selectedId
            setSelectedId(dataWithDistance[0]?.id)
            setCenter({
                latitude: location.latitude,
                longitude: location.longitude
            })
            setDataMapList(dataWithDistance);
            setIsNearestFeature(true);
        } else {
            // add distance
            let dataWithDistance: Array<InterfaceFieldingAssignedActive & {
                distance: number
            }> = originDataList.map((item: InterfaceFieldingAssignedActive) => ({
                ...item,
                distance: 0
            }))
            setDataMapList(dataWithDistance);
            setSelectedId(dataWithDistance[0]?.id);
            setCenter({
                latitude: parseFloat(dataWithDistance[0]?.firstMarkerLocation?.split(',')[1]),
                longitude: parseFloat(dataWithDistance[0]?.firstMarkerLocation?.split(',')[0])
            })
            setIsNearestFeature(false);
        }
    }, [originDataList, location]);

    useEffect(() => {
        if (selectedDataMap !== null) {
            // set selecteddatamap if datamaplist is changed
            let selectedDataMapLocal = dataMapList.find(item => item.id === selectedDataMap.id)
            setSelectedId(prevState => selectedDataMapLocal?.id ?? prevState)
            setCenter(prevState => selectedDataMapLocal ? {
                latitude: parseFloat(selectedDataMapLocal?.firstMarkerLocation?.split(',')[1]),
                longitude: parseFloat(selectedDataMapLocal?.firstMarkerLocation?.split(',')[0])
            } : prevState)
            setSelectedDataMap(selectedDataMapLocal ?? null)
        }
    }, [dataMapList, selectedDataMap]);

    console.log(dataMapList);

    useEffect(() => {
        if (layerMarker) {
            let textMarkers: Array<any> = []
            let pointMarkers: Array<any> = []
            let hotMarkers: Array<any> = []
            pointMarkers = [...pointMarkers, ...dataMapList.map((project, index) => {
                textMarkers.push(new esriGraphics({
                    geometry: new esriPoint({
                        latitude: parseFloat(project?.firstMarkerLocation?.split(',')[1]),
                        longitude: parseFloat(project?.firstMarkerLocation?.split(',')[0])
                    }),
                    symbol: new esriTextSymbol({
                        color: "black",
                        text: project.project,
                        xoffset: 40,
                        yoffset: -25,
                        font: {
                            size: 12,
                            family: "Roboto"
                        }
                    }),
                    attributes: {
                        type: "PROJECT_TEXT",
                        markerId: project.id
                    }
                }))
                return new esriGraphics({
                    geometry: new esriPoint({
                        latitude: parseFloat(project?.firstMarkerLocation?.split(',')[1]),
                        longitude: parseFloat(project?.firstMarkerLocation?.split(',')[0])
                    }),
                    symbol: new esriMarkerSymbol({
                        style: "circle",
                        color: isNearestFeature && index === 0 ? "green" : selectedId === project.id ? "blue" : "red",
                    }),
                    attributes: {
                        type: "PROJECT_DOT",
                        markerId: project.id
                    }
                })
            })]
            hotMarkers = dataMapList.filter(project => project.isHotJob).map(project => {
                return new esriGraphics({
                    geometry: new esriPoint({
                        latitude: parseFloat(project?.firstMarkerLocation?.split(',')[1]),
                        longitude: parseFloat(project?.firstMarkerLocation?.split(',')[0])
                    }),
                    symbol: new esriPictureMarkerSymbol({
                        url: hot,
                        height: 20,
                        width: 20,
                        xoffset: 20
                    })
                })
            })
            layerMarker.removeAll()
            if (pointMarkers.length > 0) {
                layerMarker.addMany(pointMarkers)
            }
            if (textMarkers.length > 0) {
                layerMarker.addMany(textMarkers)
            }
            if (hotMarkers.length > 0) {
                layerMarker.addMany(hotMarkers)
            }
        }
    }, [layerMarker, dataMapList, isNearestFeature])

    return (
        <div className="arcgis-container">
            <div ref={mapRef} style={{height: "100%", width: "100%"}}/>
            <div style={{
                position: "absolute",
                bottom: 0,
                width: isMobile ? "100%" : "98%",
                margin: isMobile ? "0" : "20px"
            }}>
                {
                    dataMapList.map((project, index) => (
                        // show only with same index and selectedId
                        project.id === selectedId &&
                      <>
                      {isMobile ? (
                          <Box display={"flex"} flexDirection={"column"} gap={1} marginTop={2}>
                              <Box bgcolor={"#f8f8f8"} padding={2} borderRadius={1}>
                                  <Grid container spacing={2}>
                                      <Grid item xs={2} md={2} display={'flex'}>
                                          <IconButton aria-label="prev" disabled={index === 0} color={"primary"}
                                                      size={"medium"} onClick={() => {
                                              setSelectedId(dataMapList[index - 1]?.id)
                                              setCenter({
                                                  latitude: parseFloat(dataMapList[index - 1]?.firstMarkerLocation?.split(',')[1]),
                                                  longitude: parseFloat(dataMapList[index - 1]?.firstMarkerLocation?.split(',')[0])
                                              })
                                          }}>
                                              <ArrowCircleLeftIcon sx={{fontSize: '32px'}}/>
                                          </IconButton>
                                          {/*<Grid>*/}
                                          {/*    {*/}
                                          {/*        index > 0 && (*/}
                                          {/*            <>*/}
                                          {/*                <Typography fontWeight={"bold"}*/}
                                          {/*                            fontSize={14}>{dataMapList[index - 1]?.name}</Typography>*/}
                                          {/*                <Typography*/}
                                          {/*                    fontWeight={"bold"}>{dataMapList[index - 1]?.distance.toFixed(2)} Miles</Typography>*/}
                                          {/*                <Typography fontWeight={"light"}>From your current*/}
                                          {/*                    location</Typography>*/}
                                          {/*            </>*/}
                                          {/*        )*/}
                                          {/*    }*/}
                                          {/*</Grid>*/}
                                      </Grid>

                                      <Grid item xs={10} md={3} display={'flex'} justifyContent={'start'}>
                                          {
                                              index !== dataMapList.length - 1 && (
                                                  <Grid flexGrow={1}>
                                                      <Typography fontWeight={"bold"}
                                                                  fontSize={14}>{dataMapList[index]?.name}</Typography>
                                                      <Typography
                                                          fontWeight={"bold"}>{dataMapList[index]?.distance.toFixed(2)} Miles</Typography>
                                                      <Typography fontWeight={"light"}>From your current location</Typography>
                                                  </Grid>
                                              )
                                          }
                                          <Grid>
                                              <IconButton aria-label="next" disabled={
                                                  index === dataMapList.length - 1
                                              } color={"primary"} size={"medium"} onClick={() => {
                                                  setSelectedId(dataMapList[index + 1]?.id)
                                                  setCenter({
                                                      latitude: parseFloat(dataMapList[index + 1]?.firstMarkerLocation?.split(',')[1]),
                                                      longitude: parseFloat(dataMapList[index + 1]?.firstMarkerLocation?.split(',')[0])
                                                  })
                                              }}>
                                                  <ArrowCircleRightIcon sx={{fontSize: '32px'}}/>
                                              </IconButton>
                                          </Grid>
                                      </Grid>

                                      <Grid item xs={12} md={3}>
                                          {isNearestFeature && index === 0 &&
                                              <Chip icon={<RoomIcon/>} label={`Nearest`} size={isMobile ? 'small' : 'medium'} color={"success"}/>}
                                          <Grid display={'flex'}>
                                              <Typography fontWeight={"bold"} mt={1} fontSize={16}>{project.project}</Typography>
                                          </Grid>

                                          {/*<Typography*/}
                                          {/*    fontWeight={"light"}>20</Typography>*/}
                                      </Grid>
                                      <Grid item xs={12} md={4}>
                                          <Grid container spacing={1}>
                                              {/*{statusSelected !== 0 && (*/}
                                              {/*    <Grid item xs={12} lg={3}>*/}
                                              {/*    </Grid>*/}
                                              {/*)}*/}
                                              <Grid item xs={6} sm={4} md={6} lg={3}>
                                                  <Button fullWidth variant="contained" color="inherit"
                                                          startIcon={<Image/>} onClick={() => {
                                                      setSelectedDataMap(project);
                                                      setModalImages(true)
                                                  }}>Upload</Button>
                                              </Grid>
                                              <Grid item xs={6} sm={4} md={6} lg={3}>
                                                  <a href={`https://www.google.com/maps/search/?api=1&query=${project?.firstMarkerLocation?.split(',')[1]}%2C${project?.firstMarkerLocation?.split(',')[0]}`}
                                                     target="_blank">
                                                      <Button fullWidth variant="contained"
                                                              startIcon={<PlayArrowOutlined/>}
                                                              sx={{bgcolor: '#4ADEDE'}}>Direction</Button>
                                                  </a>
                                              </Grid>
                                              <Grid item xs={6} sm={4} md={6} lg={3}>
                                                  <Button fullWidth variant="contained"
                                                          startIcon={<Edit/>} sx={{bgcolor: '#1560BD'}}
                                                          onClick={() => {
                                                              setSelectedDataMap(project);
                                                              setModalNotes(true)
                                                          }}
                                                  >Notes</Button>
                                              </Grid>
                                              {/*{statusSelected === 0 && (*/}
                                              <Grid item xs={6} sm={4} md={6} lg={3}>
                                                  <FieldingStart id={project.id}/>
                                              </Grid>
                                              {/*)}*/}
                                          </Grid>
                                      </Grid>
                                      {/*{*/}
                                      {/*    index !== dataMapList.length - 1 && (*/}

                                      {/*<Grid item xs={2} md={1} display={'flex'} justifyContent={'end'}>*/}

                                      {/*</Grid>*/}
                                      {/*    )*/}
                                      {/*}*/}
                                  </Grid>
                              </Box>
                          </Box>
                          ) : (
                          <Box display={"flex"} flexDirection={"column"} gap={1} marginTop={2}>
                              <Box bgcolor={"#f8f8f8"} padding={2} borderRadius={1}>
                                  <Grid container spacing={2}>
                                      <Grid item xs={6} md={2} display={'flex'}>
                                          <IconButton aria-label="prev" disabled={index === 0} color={"primary"}
                                                      size={"medium"} onClick={() => {
                                              setSelectedId(dataMapList[index - 1]?.id)
                                              setCenter({
                                                  latitude: parseFloat(dataMapList[index - 1]?.firstMarkerLocation?.split(',')[1]),
                                                  longitude: parseFloat(dataMapList[index - 1]?.firstMarkerLocation?.split(',')[0])
                                              })
                                          }}>
                                              <ArrowCircleLeftIcon sx={{fontSize: '32px'}}/>
                                          </IconButton>
                                          <Grid>
                                              {
                                                  index > 0 && (
                                                      <>
                                                          <Typography fontWeight={"bold"}
                                                                      fontSize={14}>{dataMapList[index - 1]?.name}</Typography>
                                                          <Typography
                                                              fontWeight={"bold"}>{dataMapList[index - 1]?.distance.toFixed(2)} Miles</Typography>
                                                          <Typography fontWeight={"light"}>From your current
                                                              location</Typography>
                                                      </>
                                                  )
                                              }
                                          </Grid>
                                      </Grid>


                                      <Grid item xs={6} md={3}>
                                          {isNearestFeature && index === 0 &&
                                              <Chip icon={<RoomIcon/>} label={`Nearest`} color={"success"}/>}
                                          <Grid display={'flex'}>
                                              <Typography fontWeight={"bold"} mt={1} fontSize={16}>{project.project}</Typography>
                                              <Typography fontWeight={"bold"} mt={1} fontSize={16} marginX={1}>|</Typography>
                                              <Typography fontWeight={"bold"} mt={1} fontSize={16}>{project.name}</Typography>
                                          </Grid>

                                          {/*<Typography*/}
                                          {/*    fontWeight={"light"}>20</Typography>*/}
                                      </Grid>
                                      <Grid item xs={12} md={4}>
                                          <Grid container spacing={1}>
                                              {/*{statusSelected !== 0 && (*/}
                                              {/*    <Grid item xs={12} lg={3}>*/}
                                              {/*    </Grid>*/}
                                              {/*)}*/}
                                              <Grid item xs={6} sm={4} md={6} lg={3}>
                                                  <Button fullWidth variant="contained" color="inherit"
                                                          startIcon={<Image/>} onClick={() => {
                                                      setSelectedDataMap(project);
                                                      setModalImages(true)
                                                  }}>Upload</Button>
                                              </Grid>
                                              <Grid item xs={6} sm={4} md={6} lg={3}>
                                                  <a href={`https://www.google.com/maps/search/?api=1&query=${project?.firstMarkerLocation?.split(',')[1]}%2C${project?.firstMarkerLocation?.split(',')[0]}`}
                                                     target="_blank">
                                                      <Button fullWidth variant="contained"
                                                              startIcon={<PlayArrowOutlined/>}
                                                              sx={{bgcolor: '#4ADEDE'}}>Direction</Button>
                                                  </a>
                                              </Grid>
                                              <Grid item xs={6} sm={4} md={6} lg={3}>
                                                  <Button fullWidth variant="contained"
                                                          startIcon={<Edit/>} sx={{bgcolor: '#1560BD'}}
                                                          onClick={() => {
                                                              setSelectedDataMap(project);
                                                              setModalNotes(true)
                                                          }}
                                                  >Notes</Button>
                                              </Grid>
                                              {/*{statusSelected === 0 && (*/}
                                              <Grid item xs={6} sm={4} md={6} lg={3}>
                                                  <FieldingStart id={project.id}/>
                                              </Grid>
                                              {/*)}*/}
                                          </Grid>
                                      </Grid>
                                      {/*{*/}
                                      {/*    index !== dataMapList.length - 1 && (*/}
                                      <Grid item xs={6} md={3} display={'flex'} justifyContent={'end'}>
                                          {
                                              index !== dataMapList.length - 1 && (
                                                  <Grid>
                                                      <Typography fontWeight={"bold"}
                                                                  fontSize={14}>{dataMapList[index + 1]?.name}</Typography>
                                                      <Typography
                                                          fontWeight={"bold"}>{dataMapList[index + 1]?.distance.toFixed(2)} Miles</Typography>
                                                      <Typography fontWeight={"light"}>From your current location</Typography>
                                                  </Grid>
                                              )
                                          }
                                          <Grid>
                                              <IconButton aria-label="next" disabled={
                                                  index === dataMapList.length - 1
                                              } color={"primary"} size={"medium"} onClick={() => {
                                                  setSelectedId(dataMapList[index + 1]?.id)
                                                  setCenter({
                                                      latitude: parseFloat(dataMapList[index + 1]?.firstMarkerLocation?.split(',')[1]),
                                                      longitude: parseFloat(dataMapList[index + 1]?.firstMarkerLocation?.split(',')[0])
                                                  })
                                              }}>
                                                  <ArrowCircleRightIcon sx={{fontSize: '32px'}}/>
                                              </IconButton>
                                          </Grid>
                                      </Grid>
                                      {/*<Grid item xs={2} md={1} display={'flex'} justifyContent={'end'}>*/}

                                      {/*</Grid>*/}
                                      {/*    )*/}
                                      {/*}*/}
                                  </Grid>
                              </Box>
                          </Box>
                          )}
                      </>
                    ))
                }

            </div>
            <CustomModal
                open={Boolean(selectedDataMap) && modalNotes}
                onClose={() => {
                    setSelectedDataMap(null);
                    setModalNotes(false)
                }}
                title="Notes"
                size="sm"
                component={selectedDataMap !== null && <FieldingNotes fieldingRequestId={selectedDataMap?.id}
                                                                      notes={selectedDataMap?.fieldingRequestNotes}
                                                                      onUpdateData={() => dispatch(fieldingAssignedActiveGet())}/>}
            />
            <CustomModal
                open={Boolean(selectedDataMap) && modalImages}
                onClose={() => {
                    setSelectedDataMap(null);
                    setModalImages(false)
                }}
                title="Upload File"
                size="sm"
                component={selectedDataMap !== null &&
                    <FieldingUploadImages fieldingId={selectedDataMap?.id} onClose={() => {
                        setSelectedDataMap(null);
                        setModalImages(false)
                    }}/>}
            />
        </div>
    )
}