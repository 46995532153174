import { Box, Divider, Grid, Paper, useMediaQuery } from "@mui/material";
import CalendarLittle from "./CalendarLittle";
import CalendarMain from "./CalendarMain";
import CalendarOption from "./CalendarOption";
import CalendarSelect from "./CalendarSelect";

export default function Calendar() {

    const isVerticalTable = useMediaQuery("(max-width:1200px)")

    return (
        <Paper elevation={0} sx={{ margin: isVerticalTable ? 1 : 4, padding: 2, paddingRight: 0, height: isVerticalTable ? undefined : 'calc(100vh - 64px)' }}>
            <Grid container spacing={2} height={"100%"} alignItems={"flex-start"}>
                <Grid item xs={12} lg={3} sx={{ height: "100%" }}>
                    <Box height={"100%"} borderRight={isVerticalTable ? undefined : "1px solid gray"} paddingRight={1}>
                        <CalendarLittle />
                        <Divider />
                        <CalendarSelect />
                        <Divider />
                        <CalendarOption />
                    </Box>
                </Grid>
                <Grid item xs={12} lg={9} sx={{ height: "100%" }}>
                    <CalendarMain />
                </Grid>
            </Grid>
        </Paper>
    )
}