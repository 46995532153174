import { Autocomplete, Box, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { formatName } from "../../../shared/utils";
import { useEffect } from "react";
import { getAccountFielder } from "../../account/account.api";
import { setFielderIdSelected } from "../calendar.reducer";

export default function CalendarSelect() {

    const dispatch = useDispatch<any>()

    const { dataFielderList } = useSelector((state: RootState) => state.account)
    const { fielderIdSelected } = useSelector((state: RootState) => state.calendar)

    useEffect(() => {
        dispatch(getAccountFielder())
    }, [dispatch])

    return (
        <Box margin={2}>
            <Autocomplete
                fullWidth
                options={dataFielderList}
                getOptionLabel={(value: { id: string, fullName: string }) => formatName(value.fullName)}
                value={dataFielderList.find((fielder) => fielder.id === fielderIdSelected)}
                onChange={(event, newValue) => dispatch(setFielderIdSelected(newValue?.id))}
                renderInput={(params) => <TextField {...params} label="Fielder" />}
            />
        </Box>
    )
}