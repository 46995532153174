import React from 'react';
import Expense from './Expense';
import Mileage from "./Mileage";

const ExpensesPage = () => {
    return (
        <>
            <Expense/>
            <Mileage/>
        </>
    );
};

export default ExpensesPage;