import {
    ArrowBack,
    ArrowBackIos,
    ArrowForwardIos,
    Close,
    Delete,
    Download,
    Edit,
    NoteAdd,
    Save,
    Send,
    KeyboardArrowDown,
    KeyboardArrowUp,
} from "@mui/icons-material"
import {
    Autocomplete,
    Backdrop,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    Grid,
    IconButton,
    List,
    ListItem,
    TextField,
    Tooltip,
    Typography
} from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useSearchParams } from "react-router-dom"
import { RootState } from "../../../store"
import ProjectFormMap from "./ProjectFormMap"
import { FormEvent, Fragment, useEffect, useState } from "react"
import moment from "moment"
import { getAccountFielder } from "../../account/account.api"
import notificationSuccess from "../../../shared/notificationSuccess"
import { filterListLocationLog, formatName, userInfo } from "../../../shared/utils"
import {
    fieldingAttachmentDelete,
    fieldingDelete,
    fieldingDetailsGet,
    fieldingEdit,
    fieldingMarkerAdd,
    fieldingMarkerAttachmentDelete,
    fieldingNoteCommentCreate,
    fieldingNoteCreate,
    fieldingTypeGet
} from "../../fielding/fielding.api"
import {
    InitialFieldingMarker,
    InitialFieldingDetail,
    InterfaceFieldingDetail,
    InterfaceMarkerLine,
    InterfaceMarkerPoint,
    InterfaceFieldingMarker,
    InterfaceFieldingNoteCreate,
    InitialFieldingNoteCreate
} from "../../fielding/fielding.interface"
import FieldingTypeForm from "./shared/FieldingTypeForm"
import { markerDelete, projectEditMarker, projectGetDetail, testSendSignalR } from "../project.api"
import { InterfaceAccountFielderData } from "../../account/account.interface"
import ProjectFormFieldingUpload from "./ProjectFormFieldingUpload"
import ProjectFormFieldingMarkerUpload from "./ProjectFormFieldingMarkerUpload"
import { HubConnectionBuilder, HttpTransportType } from "@microsoft/signalr"
import { baseUrl } from "../../../state"
import axios from "axios"
import notificationError from "../../../shared/notificationError"
import ProjectFormFieldingImagePreview from "./ProjectFormFieldingImagePreview"
import ProjectFormFieldingMarkerImagePreview from "./ProjectFormFieldingMarkerImagePreview"
import CustomModal from "../../../shared/customModal/CustomModal"
import ModalAddMarker from "./modals/ModalAddMarker"
import ModalAddNotes from "./modals/ModalAddNotes"
import { setDataExpandedAddressList } from "../project.reducer"
import Swal from "sweetalert2"
import ProjectFormFieldingLogs, { FieldingLogsPanel } from "./ProjectFormFieldingLogs"
import JSZip from "jszip"
// @ts-ignore
import ButtonOutlined from "../../../shared/ButtonOutlined"
import useAddress from "../../../shared/useAddress"
// import { saveAs } from "file-saver"

export default function ProjectFormFielding() {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const dispatch = useDispatch<any>()
    const addressHook = useAddress()

    const detailProject = useSelector((state: RootState) => state.project).detail
    const { dataExpandedAddressList, loading } = useSelector((state: RootState) => state.project)
    const { dataFielderList } = useSelector((state: RootState) => state.account)

    const [fieldingRequest, setFieldingRequest] = useState<InterfaceFieldingDetail>(InitialFieldingDetail)
    const [fieldingRequestIndex, setFieldingRequestIndex] = useState<number | null>(null)
    const [fieldingRequestList, setFieldingRequestList] = useState<Array<any>>([])
    const [editedMarker, setEditedMarker] = useState<Array<any>>([])
    const [isEdit, setIsEdit] = useState(false)
    const [LoadingLocal, setLoadingLocal] = useState<any>(true)
    const [LoadingDownload, setLoadingDownload] = useState<any>(false)
    const [isEdittable, setIsEdittable] = useState<any>(false)
    const [imageFieldingSelectedIndex, setImageFieldingSelectedIndex] = useState<number | null>(null)
    const [imageFieldingSelectedIndexDetail, setImageFieldingSelectedIndexDetail] = useState<number | null>(null)
    const [imageMarkerSelectedIndex, setImageMarkerSelectedIndex] = useState<number | null>(null)
    const [center, setCenter] = useState<InterfaceMarkerPoint | null>(null)
    const [downloadFiles, setDownloadFiles] = useState({
        url: "",
        progressValue: 70
    })
    const [idAttachmentFieldingSelected, setIdAttachmentFieldingSelected] = useState<Array<string>>([])
    const [idAttachmentMarkerSelected, setIdAttachmentMarkerSelected] = useState<Array<string>>([])
    const [selectedMarkerId, setSelectedMarkerId] = useState<string>("")
    const [showNotes, setShowNotes] = useState(false)
    const [notesComment, setNotesComment] = useState("")
    const [markerTemp, setMarkerTemp] = useState<InterfaceFieldingMarker | null>(null)
    const [modalAddNotes, setModalAddNotes] = useState(false)
    const [showTimeStamp, setShowTimeStamp] = useState<any>(false)
    const [newDueDate, setNewDueDate] = useState("")

    const [note, setNote] = useState<InterfaceFieldingNoteCreate>(InitialFieldingNoteCreate)

    useEffect(() => {
        dispatch(projectGetDetail(searchParams.get("projectid") || ""))
        setFieldingRequestIndex(parseInt(searchParams.get("index") || "0"))
        dispatch(getAccountFielder())
        dispatch(fieldingTypeGet())
        initSignalR()
    }, [])

    const initSignalR = async () => {
        let url = `${baseUrl}/connectionHub`
        let connection = await new HubConnectionBuilder()
            .withUrl(url, {
                skipNegotiation: true,
                transport: HttpTransportType.WebSockets
            })
            .withAutomaticReconnect()
            .build()

        connection
            .start()
            .then((res) => {
                dispatch(testSendSignalR())
            })
            .catch((err) => {
            })
        connection.on("messageReceived", (username, message) => {
        })
    }

    useEffect(() => {
        if (detailProject?.projectFieldingRequests?.length > 0) {
            setSelectedMarkerId("")
            setIsEdittable(false)
            setEditedMarker([])
            setIsEdit(false)
            setShowTimeStamp(false)
            getDetails()
        }
    }, [detailProject?.projectFieldingRequests, fieldingRequestIndex])

    useEffect(() => {
        if (!showTimeStamp && fieldingRequest.firstMarkerLocation !== "") {
            setCenter({
                latitude: parseFloat(fieldingRequest.firstMarkerLocation.split(",")[1]),
                longitude: parseFloat(fieldingRequest.firstMarkerLocation.split(",")[0]),
                id: fieldingRequest.id
            })
            setFieldingRequestList((prev) => prev.filter((_, i) => i === 0))
            return
        } else {
            console.log("showTimeStamp", showTimeStamp)
        }
    }, [showTimeStamp])

    useEffect(() => {
        let findDotMarker = fieldingRequest.fieldingRequestMarkers.find((marker) => marker.id === selectedMarkerId)
        if (findDotMarker) {
            setIsEdittable(findDotMarker)
        }
    }, [selectedMarkerId])

    const getDetails = async (isNewMarker = "", doubleLoad = true) => {
        if (doubleLoad) setLoadingLocal(true)
        if (fieldingRequestIndex !== null) {
            await dispatch(
                fieldingDetailsGet(detailProject.projectFieldingRequests[fieldingRequestIndex]?.fieldingRequestId)
            )
                .unwrap()
                .then(async (response: any) => {
                    let data: any = response.data.data

                    let sortingNotes =
                        data.fieldingRequestNotes.length > 0
                            ? data.fieldingRequestNotes[0].comments.length > 0
                                ? data.fieldingRequestNotes[0].comments.sort(
                                    (a: any, b: any) =>
                                        new Date(b.dateTimeString).getTime() - new Date(a.dateTimeString).getTime()
                                )
                                : []
                            : []

                    let newData = {
                        ...data,
                        dueDateString: moment(data.dueDateString).format("YYYY-MM-DD"),
                        fieldingRequestNotes:
                            data.fieldingRequestNotes.length > 0
                                ? [
                                    {
                                        ...data.fieldingRequestNotes[0],
                                        comments: sortingNotes
                                    }
                                ]
                                : [],
                        fieldingRequestMarkers: data.fieldingRequestMarkers.map((marker: any) => {
                            return {
                                ...marker,
                                lineData: JSON.parse(marker.lineData),
                                shapeData: JSON.parse(marker.shapeData)
                            }
                        })
                    }
                    console.log("newData", newData)

                    setFieldingRequest(newData)
                    setNewDueDate(newData.dueDateString)
                    if (!showTimeStamp)
                        setFieldingRequestList([
                            {
                                id: newData.id,
                                name: newData.name,
                                color: newData.colorCode,
                                markers: newData.fieldingRequestMarkers
                            }
                        ])

                    const addressToAdd = await addressHook.fillAddressMultiple([
                        {
                            id: data.id,
                            markers: data.fieldingRequestMarkers.map((marker: any) => {
                                return {
                                    ...marker,
                                    lineData: JSON.parse(marker.lineData),
                                    shapeData: JSON.parse(marker.shapeData)
                                }
                            })
                        }
                    ])
                    await dispatch(setDataExpandedAddressList(addressToAdd))
                    if (editedMarker.length === 0) {
                        if (isNewMarker !== "") {
                            let newestMarker = data.fieldingRequestMarkers.find(
                                (marker: any) => marker.customTypeId === isNewMarker
                            )

                            if (newestMarker?.dotLongLat !== "") {
                                setSelectedMarkerId(newestMarker?.id)
                                setCenter({
                                    latitude: parseFloat(newestMarker?.dotLongLat.split(",")[1]),
                                    longitude: parseFloat(newestMarker?.dotLongLat.split(",")[0]),
                                    id: newestMarker?.id
                                })
                                return
                            }
                        }
                        if (data.firstMarkerLocation !== "") {
                            setCenter({
                                latitude: parseFloat(data.firstMarkerLocation.split(",")[1]),
                                longitude: parseFloat(data.firstMarkerLocation.split(",")[0]),
                                id: data.id
                            })
                            return
                        }

                        if ("geolocation" in navigator) {
                            navigator.geolocation.getCurrentPosition(function (position) {
                                setCenter({
                                    latitude: position.coords.latitude,
                                    longitude: position.coords.longitude,
                                    id: data.id
                                })
                            })
                        } else {
                            console.log("Geolocation is not available in your browser.")
                        }
                    } else {
                        setSelectedMarkerId(editedMarker[0]?.id)
                        setCenter({
                            latitude: parseFloat(editedMarker[0]?.firstMarkerLocation.split(",")[1]),
                            longitude: parseFloat(editedMarker[0]?.firstMarkerLocation.split(",")[0]),
                            id: editedMarker[0]?.id
                        })
                    }
                })
        }
        if (doubleLoad)
            setTimeout(() => {
                setLoadingLocal(false)
            }, 1400)
    }

    const handleChangeFieldingRequest = (e: any) => {
        const { name, value } = e.target
        setFieldingRequest({
            ...fieldingRequest,
            [name]: value
        })
    }

    const handleSave = async () => {
        if (note.content !== "") {
            return await dispatch(
                fieldingNoteCreate({
                    ...note,
                    fieldingRequestId: fieldingRequest.id,
                    token: userInfo().token
                })
            )
        }
        await handleSubmitEditedProject()

        if (isEdittable) {
            handleSubmitChangeLocation()
        }
        else {
            const newMarker = fieldingRequest.fieldingRequestMarkers.filter((f) => !Boolean(f.id))
            for (const i in newMarker) {
                await dispatch(
                    fieldingMarkerAdd({
                        fieldingRequestId: fieldingRequest.id,
                        id: "",
                        type: 0,
                        markerColor: 0,
                        subject: "",
                        notes: "",
                        dotLongLat: newMarker[i].dotLongLat,
                        requestedFielding: newMarker[i].requestedFielding,
                        lineData: JSON.stringify(newMarker[i].lineData),
                        shapeData: JSON.stringify(newMarker[i].shapeData),
                        colorCode: newMarker[i].colorCode,
                        timeOffset: moment().utcOffset() * -1,
                        customTypeId: newMarker[i].customTypeId
                    })
                )
            }
            setEditedMarker(newMarker)
            setSelectedMarkerId(fieldingRequest.id)
            await getDetails(newMarker[newMarker.length - 1].customTypeId, false)
        }
        setIsEdit(false)
    }

    const handleConfirmationDeletAttachment = (multiple: boolean = true, id: string = "") => {
        Swal.fire({
            title: "Delete attachment",
            text: `Are you sure want to delete ${multiple ? "selected" : ""} attachment ?`,
            showCancelButton: true,
            confirmButtonText: "Delete"
        }).then((result) => {
            if (result.isConfirmed) {
                if (multiple) handleDeleteFieldingAttachmentMultiple()
                else handleDeleteFieldingAttachment(id)
            }
        })
    }

    const handleDeleteFieldingAttachment = (id: string) => {
        dispatch(fieldingAttachmentDelete(id))
            .unwrap()
            .then((response: any) => {
                getDetails("", false)
                Swal.fire("Deleted!", "", "success")
            })
    }

    const handleDeleteFieldingAttachmentMultiple = async () => {
        for (const i in idAttachmentFieldingSelected) {
            await dispatch(fieldingAttachmentDelete(idAttachmentFieldingSelected[i]))
        }
        getDetails("", false)
        setIdAttachmentFieldingSelected([])
        Swal.fire("Deleted!", "", "success")
    }

    const handleDeleteFieldingMarkerAttachment = (id: string) => {
        dispatch(fieldingMarkerAttachmentDelete(id))
            .unwrap()
            .then((response: any) => {
                getDetails("", false)
            })
    }

    const handleDeleteMarkerAttachmentMultiple = async () => {
        for (const i in idAttachmentMarkerSelected) {
            await dispatch(fieldingMarkerAttachmentDelete(idAttachmentMarkerSelected[i]))
        }
        getDetails("", false)
        setIdAttachmentMarkerSelected([])
    }

    const handleDownload = (url: string, name: string) => {
        setDownloadFiles({
            url: url,
            progressValue: 0
        })
        axios({
            url: url,
            method: "GET",
            responseType: "blob",
            onDownloadProgress: (progressEvent) => {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / (progressEvent.total || 0))
                setDownloadFiles({
                    url: url,
                    progressValue: percentCompleted
                })
            }
        }).then((response) => {
            const downloadLink = document.createElement("a")
            downloadLink.href = window.URL.createObjectURL(new Blob([response.data]))
            downloadLink.setAttribute("download", name)
            document.body.appendChild(downloadLink)
            downloadLink.click()
            downloadLink.remove()
            setDownloadFiles({
                url: "",
                progressValue: 0
            })
        })
    }

    const handleAddMarker = (marker: {
        lineData: Array<InterfaceMarkerLine>
        shapeData: Array<InterfaceMarkerLine>
        dotLongLat: string
    }) => {
        if (Boolean(isEdittable)) handleEditMarker(marker)
        else {
            let newMarker = {
                ...InitialFieldingMarker,
                ...marker
            }

            setMarkerTemp(newMarker)
        }
    }

    const handleComment = (e: FormEvent) => {
        e.preventDefault()
        dispatch(
            fieldingNoteCommentCreate({
                token: userInfo().token,
                fieldingRequestNoteId: fieldingRequest.fieldingRequestNotes[0].id,
                comment: notesComment
            })
        )
            .unwrap()
            .then(() => {
                setNotesComment("")
                getDetails("", false)
            })
    }

    const handleChangeMarkerDetail = (name: string, value: any) => {
        setFieldingRequest({
            ...fieldingRequest,
            fieldingRequestMarkers: fieldingRequest.fieldingRequestMarkers.map((marker) => {
                if (marker.id === selectedMarkerId) {
                    return { ...marker, [name]: value }
                } else return marker
            })
        })
    }

    const attachmentFieldingDocumentList = fieldingRequest.fieldingRequestAttachments.filter(
        (attachment) =>
            !attachment.fileName.toLowerCase().includes(".png") &&
            !attachment.fileName.toLowerCase().includes(".jpg") &&
            !attachment.fileName.toLowerCase().includes(".jpeg")
    )
    const attachmentFieldingImageList = fieldingRequest.fieldingRequestAttachments.filter(
        (attachment) =>
            attachment.fileName.toLowerCase().includes(".png") ||
            attachment.fileName.toLowerCase().includes(".jpg") ||
            attachment.fileName.toLowerCase().includes(".jpeg")
    )

    const markerSelected =
        fieldingRequest.fieldingRequestMarkers.filter((marker) => marker.id === selectedMarkerId)[0] || null

    if (detailProject.projectFieldingRequests.length === 0 || center === null || fieldingRequestIndex === null) {
        return <></>
    }

    const handleLocationLog = (selectMarker: any, selectedLog?: any | undefined) => {
        let newField = [
            {
                id: fieldingRequest.id,
                name: fieldingRequest.name,
                color: fieldingRequest.colorCode,
                markers: fieldingRequest.fieldingRequestMarkers
            },
            selectMarker
        ]
        let tracingData = handleTracingLog()
        console.log("tracingData call", tracingData)
        if (tracingData) newField.push(tracingData)
        setFieldingRequestList(newField)
        setCenter({
            latitude: parseFloat(selectMarker.markers[0].latitude),
            longitude: parseFloat(selectMarker.markers[0].longitude),
            id: selectMarker.markers[0].id
        })
    }

    const handleEditPalete = (value: boolean) => {
        setIsEdit(value)
        if (value) {
            setFieldingRequestList([
                {
                    id: fieldingRequest.id,
                    name: fieldingRequest.name,
                    color: fieldingRequest.colorCode,
                    markers: fieldingRequest.fieldingRequestMarkers
                }
            ])
        }
        else {
            setSelectedMarkerId("")
            setIsEdittable(false)
            getDetails("", false)
        }
    }

    const handleDeleteMarker = async () => {
        let idm = isEdittable ? isEdittable?.id : selectedMarkerId
        Swal.fire({
            title: "Do you want to delete the marker?",
            showCancelButton: true,
            confirmButtonText: "Delete"
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(markerDelete(idm))
                    .unwrap()
                    .then((res: any) => {
                        if (res?.status)
                            Swal.fire("Deleted!", "", "success").then(() => {
                                handleEditPalete(false)
                            })
                    })
            }
        })
    }

    const handleEditMarker = async (marker: any, add: any = false) => {
        let newMarker = {
            id: fieldingRequest.id,
            name: add ? "Add Marker" : "New Location",
            color: "blue",
            markers: [{ ...isEdittable, ...marker }]
        }
        let selectedMarker = [...fieldingRequestList.filter((markList: any) => markList.id !== newMarker.id), newMarker]

        setFieldingRequestList(selectedMarker)
    }

    const handleSubmitEditedProject = async () => {
        let formBody = {
            token: userInfo().token,
            fieldingRequestId: fieldingRequest.id,
            dueDateString: isEdit ? moment(fieldingRequest.dueDateString).format("MM/DD/YYYY") : moment(newDueDate).format("MM/DD/YYYY"),
            colorCode: fieldingRequest.colorCode,
            customTypeId: fieldingRequest.customTypeId,
            fielderIds: fieldingRequest.fielderIds,
            requestedStartDateString:
                fieldingRequest.requestedStartDateString === "-" ? null : fieldingRequest.requestedStartDateString,
            requestedDueDateString:
                fieldingRequest.requestedDueDateString === "-" ? null : fieldingRequest.requestedDueDateString,
            editedFieldingRequestMarkers: fieldingRequest.fieldingRequestMarkers
                .filter((f) => Boolean(f.id))
                .map((marker) => {
                    return {
                        ...marker,
                        timeOffset: moment().utcOffset() * -1,
                        fieldingRequestMarkerId: marker.id || "",
                        lineData: JSON.stringify(marker.lineData),
                        shapeData: JSON.stringify(marker.shapeData)
                    }
                }),
            isEndMarker: false
        }

        const responseFieldingEdit = await dispatch(fieldingEdit(formBody)).unwrap()

        if (responseFieldingEdit.status === 200) {
            notificationSuccess("Field request successfully edited")
        }
        else {
            notificationError(responseFieldingEdit)
        }
        getDetails("", false)
    }

    const handleSubmitChangeLocation = async () => {
        let selectNewMarker = await Promise.all(fieldingRequestList.find((list) => list.name === "New Location")?.markers.map(async (list: any) => {
            let longLat: any = list.dotLongLat?.split(",")
            let convert = await addressHook.getAddress(parseFloat(longLat[1]), parseFloat(longLat[0]))
            return {
                fieldingRequestMarkerId: list.id,
                dotLongLat: list.dotLongLat,
                address: convert.address
            }
        }))
        if (selectNewMarker) {
            let formBody = {
                token: userInfo().token,
                editedFieldingRequestMarkers: selectNewMarker
            }
            setEditedMarker(selectNewMarker)
            await dispatch(projectEditMarker(formBody))
                .unwrap()
                .then(() => {
                    getDetails("", false)
                })
        }
    }

    const handleShowTimeStamp = (log: any) => {
        setIsEdittable(false)
        setEditedMarker([])
        setIsEdit(false)
        setShowTimeStamp(log)
    }

    const handleTracingLog = () => {
        if (showTimeStamp) {
            let listLocation = filterListLocationLog(showTimeStamp?.selected, showTimeStamp)

            let lineData = listLocation?.map((location: any) => ({
                latitude: parseFloat(location.longLat.split(",")[1]),
                longitude: parseFloat(location.longLat.split(",")[0])
            }))

            const lastLineData = lineData[lineData.length - 1]
            let tracingData = {
                id: showTimeStamp.downloadedDate + showTimeStamp.downloadedTime + "_tracingLine",
                name: "",
                color: "#00ff00",
                type: "tracingLine",
                markers: [
                    { lineData: [lineData], dotLongLat: "" },
                    {
                        dotLongLat: lastLineData.longitude + "," + lastLineData.latitude,
                        latitude: lastLineData.latitude,
                        longitude: lastLineData.longitude,
                        colorCode: "red"
                    }
                ]
            }

            return tracingData
        } else return null
    }

    const handleDownloadSelectedtoZip = async (type = "") => {
        setLoadingDownload(true)

        let files: any = []

        if (type === "fielding") {
            attachmentFieldingDocumentList
                .filter((document: any) => idAttachmentFieldingSelected.includes(document.id))
                .map((selected: any) => {
                    files.push({
                        name: selected.fileName,
                        content: selected.filePath,
                        type: "application/" + selected.fileName.split(".").slice(-1)[0]
                    })
                })
            attachmentFieldingImageList
                .filter((image: any) => idAttachmentFieldingSelected.includes(image.id))
                .map((selected: any) => {
                    files.push({
                        name: selected.fileName,
                        content: selected.filePath,
                        type: "image/" + selected.fileName.split(".").slice(-1)[0]
                    })
                })
        }
        if (type === "marker") {
            let queryImage = markerSelected?.images || []
            queryImage
                .filter((document: any) => idAttachmentMarkerSelected.includes(document.id))
                .map((selected: any) => {
                    files.push({
                        name: selected.fileName,
                        content: selected.filePath,
                        type: "image/" + selected.fileName.split(".").slice(-1)[0]
                    })
                })
        }

        const zip = new JSZip()
        const promises: any = []
        console.log("type", type)

        files.forEach(async (file: any, i: number) => {
            console.log("file", file)
            zip.file(file.name, file.content)
            promises.push(
                fetch(file.content)
                    .then((response) => response.blob())
                    .then((blob) => {
                        zip.file(file.name, blob)
                    })
            )
            return
        })
        console.log("promises", promises)

        await Promise.all(promises).then(() => {
            zip.generateAsync({ type: "blob" }).then((content) => {
                const link = document.createElement("a")
                link.href = URL.createObjectURL(content)
                link.download =
                    "Attachment " +
                    type +
                    " " +
                    detailProject.projectNumber +
                    " " +
                    detailProject.projectFieldingRequests[fieldingRequestIndex].fieldingRequest +
                    ".zip"
                link.click()
            })
        })
        setLoadingDownload(false)
    }

    const handleDeleteFieldingRequest = () => {
        if (detailProject.projectFieldingRequests.length > 1) {
            setFieldingRequestIndex(0)
            Swal.fire({
                title: "Do you want to delete the fielding request?",
                showCancelButton: true,
                confirmButtonText: "Delete"
            }).then(async (result) => {
                await dispatch(fieldingDelete(fieldingRequestList[0].id)).unwrap()
                await dispatch(projectGetDetail(searchParams.get("projectid") || ""))
            })
        }
        else {
            Swal.fire({
                title: "The project must have at least one fielding request",
                showCancelButton: false,
            })
        }
    }

    const getTotalActual = () => {
        const totalActualFieldingRequest = fieldingRequest.fieldingRequestMarkersProgress.find(marker => marker.customTypeId === markerSelected.customTypeId)?.fieldingRequestActualFielding || "0"
        const totalActualMarker = fieldingRequest.fieldingRequestMarkers.filter((marker) => marker.customTypeId === markerSelected.customTypeId).reduce((a, b) => a + parseInt(b.actualFielding || "0"), 0)
        if (parseInt(totalActualFieldingRequest) > totalActualMarker) {
            return totalActualFieldingRequest
        }
        else {
            return totalActualMarker
        }
    }

    console.log(markerSelected);

    return (
        <div className="fielding-form">
            <CustomModal
                open={Boolean(markerTemp)}
                onClose={() => setMarkerTemp(null)}
                title="Add Marker"
                component={
                    <ModalAddMarker
                        value={markerTemp || InitialFieldingMarker}
                        setValue={setMarkerTemp}
                        onSubmit={() => {
                            if (markerTemp !== null) {
                                setFieldingRequest({
                                    ...fieldingRequest,
                                    fieldingRequestMarkers: [...fieldingRequest.fieldingRequestMarkers, markerTemp]
                                })

                                setFieldingRequestList([
                                    {
                                        ...fieldingRequestList[0],
                                        markers: [...fieldingRequestList[0].markers, { ...markerTemp, isNew: true }]
                                    }
                                ])
                                setMarkerTemp(null)
                            }
                        }}
                    />
                }
            />
            <CustomModal
                open={modalAddNotes}
                onClose={() => setModalAddNotes(false)}
                title="Add Notes"
                component={
                    <ModalAddNotes
                        value={note}
                        setValue={(value) => setNote(value)}
                        onSubmit={() => setModalAddNotes(false)}
                        readOnly={false}
                        submitOnSave={false}
                    />
                }
            />
            {!loading && (
                <Backdrop sx={{ color: "#fff", zIndex: 5000, background: "rgba(0, 0, 0, 0.2)" }} open={LoadingLocal}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            )}
            <div className="map-container">
                <div className="menu-header">
                    <div className="back">
                        <span>
                            Project {">"} {detailProject.projectNumber} {">"}{" "}
                            {detailProject.projectFieldingRequests[fieldingRequestIndex].fieldingRequest}
                        </span>
                        <div>
                            <Button startIcon={<ArrowBack />} onClick={() => navigate(-1)}>
                                Back
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="map" style={{ position: "relative" }}>
                    {!LoadingLocal && (
                        <div
                            style={{
                                position: "absolute",
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                opacity: "0.6"
                            }}
                        >
                            <CircularProgress color="inherit" />
                        </div>
                    )}
                    {fieldingRequest.name !== "" && (
                        <ProjectFormMap
                            fieldingRequestList={fieldingRequestList}
                            onAddMarker={handleAddMarker}
                            editMarkerOnly={isEdittable}
                            onClickGraphic={({ markerId }) => markerId !== null && setSelectedMarkerId(markerId)}
                            editable={isEdit}
                            center={center}
                            selectedMarkerId={selectedMarkerId}
                            setSelectedMarkerId={setSelectedMarkerId}
                        />
                    )}
                    {showTimeStamp && (
                        <ProjectFormFieldingLogs
                            fieldingRequest={fieldingRequest}
                            showTimeStamp={showTimeStamp}
                            setShowTimeStamp={setShowTimeStamp}
                            handleLocationLog={handleLocationLog}
                        />
                    )}
                    <Grid container spacing={1} sx={{ padding: 1 }}>
                        <Grid item xs={12} md={6}>
                            <div className="selector">
                                <IconButton
                                    disabled={isEdit || fieldingRequestIndex <= 0}
                                    onClick={() => setFieldingRequestIndex(fieldingRequestIndex - 1)}
                                >
                                    <ArrowBackIos />
                                </IconButton>
                                <span>
                                    <Typography variant="h6">
                                        {detailProject.projectFieldingRequests.length > 0
                                            ? detailProject.projectFieldingRequests[fieldingRequestIndex]
                                                .fieldingRequest
                                            : ""}
                                    </Typography>
                                    <Typography variant="body1">
                                        <span style={{ color: "blue", fontSize: "large" }}>
                                            {detailProject.projectFieldingRequests.length}
                                        </span>{" "}
                                        Total Request
                                    </Typography>
                                </span>
                                <IconButton
                                    disabled={
                                        isEdit ||
                                        fieldingRequestIndex + 1 >= detailProject.projectFieldingRequests.length
                                    }
                                    onClick={() => setFieldingRequestIndex(fieldingRequestIndex + 1)}
                                >
                                    <ArrowForwardIos />
                                </IconButton>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box
                                display={"flex"}
                                height={"100%"}
                                alignItems={"center"}
                                justifyContent={"flex-end"}
                                gap={1}
                            >
                                {isEdit ? (
                                    <Fragment>
                                        <ButtonOutlined
                                            fullWidth
                                            startIcon={<NoteAdd />}
                                            onClick={() => setModalAddNotes(true)}
                                        >
                                            Add Notes
                                        </ButtonOutlined>
                                        <ButtonOutlined
                                            fullWidth
                                            color="warning"
                                            startIcon={<Save />}
                                            onClick={handleSave}
                                        >
                                            Save
                                        </ButtonOutlined>
                                        <ButtonOutlined
                                            fullWidth
                                            color="inherit"
                                            startIcon={<Close />}
                                            onClick={() => handleEditPalete(false)}
                                        >
                                            Cancel
                                        </ButtonOutlined>
                                    </Fragment>
                                ) : isEdittable ? (
                                    <Fragment>
                                        <ButtonOutlined
                                            fullWidth
                                            color="success"
                                            startIcon={<Edit />}
                                            onClick={() => handleEditPalete(true)}
                                        >
                                            Edit Marker
                                        </ButtonOutlined>
                                        <ButtonOutlined
                                            fullWidth
                                            color="inherit"
                                            startIcon={<Close />}
                                            onClick={() => {
                                                // handleEditPalete(false)
                                                setIsEdittable(false)
                                                setSelectedMarkerId("")
                                            }}
                                        >
                                            Cancel
                                        </ButtonOutlined>
                                        <ButtonOutlined
                                            fullWidth
                                            color="error"
                                            startIcon={<Edit />}
                                            onClick={() => handleDeleteMarker()}
                                        >
                                            Delete Marker
                                        </ButtonOutlined>
                                    </Fragment>
                                ) : selectedMarkerId === "" || showTimeStamp ? (
                                    <Box display="flex" flexGrow={1} gap={1}>
                                        <ButtonOutlined
                                            fullWidth
                                            color="primary"
                                            startIcon={<Edit />}
                                            onClick={() => setIsEdit(true)}
                                        >
                                            Add New Marker
                                        </ButtonOutlined>
                                        <ButtonOutlined
                                            fullWidth
                                            color="error"
                                            startIcon={<Delete />}
                                            onClick={handleDeleteFieldingRequest}
                                        >
                                            Delete Fielding Request
                                        </ButtonOutlined>
                                    </Box>
                                ) : (
                                    <>
                                        <ButtonOutlined
                                            fullWidth
                                            color="inherit"
                                            startIcon={<Close />}
                                            onClick={() => {
                                                setIsEdittable(false)
                                                setSelectedMarkerId("")
                                            }}
                                        >
                                            Cancel
                                        </ButtonOutlined>
                                        <ButtonOutlined
                                            fullWidth
                                            color="error"
                                            startIcon={<Edit />}
                                            onClick={() => handleDeleteMarker()}
                                        >
                                            Delete Marker
                                        </ButtonOutlined>
                                    </>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                    {/* {isEdit && ( */}
                    {/* position fixed center */}
                    <Box sx={{ position: "relative", top: "50%", left: "50%", transform: "translate(-50%, -50%)", paddingTop: "10px" }}>
                        lol
                    </Box>
                    {/* )} */}
                </div>
            </div>
            <div className="fielding-detail">
                <div className="fielding-detail-container">
                    <Typography variant="h5">Field Request Detail</Typography>
                    <TextField
                        margin="dense"
                        value={
                            selectedMarkerId === ""
                                ? dataExpandedAddressList[0]?.value
                                    ? dataExpandedAddressList[0].value
                                    : "Loading Address"
                                : dataExpandedAddressList.filter((address) => address.id === selectedMarkerId)[0]?.value
                                    ? dataExpandedAddressList.filter((address) => address.id === selectedMarkerId)[0]?.value
                                    : dataExpandedAddressList[0]?.value
                                        ? dataExpandedAddressList[0]?.value
                                        : " "
                        }
                        label="Location"
                        inputProps={{ readOnly: true }}
                    />
                    <Box display={"flex"} gap={1}>
                        <TextField
                            margin="dense"
                            type="date"
                            label="Due Date"
                            name="dueDateString"
                            value={isEdit ? fieldingRequest.dueDateString : newDueDate}
                            onChange={(e) => isEdit ? handleChangeFieldingRequest(e) : setNewDueDate(e.target.value)}
                            InputLabelProps={{ shrink: true }}
                        // inputProps={{ readOnly: !isEdit }}
                        />
                        {fieldingRequest.dueDateString !== newDueDate && (
                            <Box display={"flex"} alignItems={"center"}>
                                <Button variant="contained" onClick={handleSubmitEditedProject}>Save</Button>
                            </Box>
                        )}
                    </Box>
                    <Autocomplete
                        fullWidth
                        multiple
                        options={dataFielderList}
                        getOptionLabel={(option: InterfaceAccountFielderData) => `${formatName(option.fullName)}`}
                        value={dataFielderList.filter((d: InterfaceAccountFielderData) =>
                            fieldingRequest.fielderIds.includes(d.id)
                        )}
                        onChange={(e, value) =>
                            handleChangeFieldingRequest({
                                target: { name: "fielderIds", value: value.map((v) => v.id) }
                            })
                        }
                        renderInput={(params) => <TextField {...params} margin="dense" label="Assigned to" />}
                        readOnly
                        popupIcon={<></>}
                    />
                    <FieldingLogsPanel
                        fieldingRequest={fieldingRequest}
                        showTimeStamp={showTimeStamp}
                        setShowTimeStamp={handleShowTimeStamp}
                        handleLocationLog={handleLocationLog}
                    />
                    <Card variant="outlined" style={{ overflow: "auto", maxHeight: "200px", marginTop: 2 }}>
                        <CardHeader
                            title="Notes"
                            titleTypographyProps={{ variant: "h6" }}
                            sx={{ padding: "10px 16px" }}
                            subheader={
                                fieldingRequest.fieldingRequestNotes.length > 0 && (
                                    <Box>
                                        <Typography variant="body1" fontWeight={"bold"}>
                                            To: {fieldingRequest.fieldingRequestNotes[0].fielderNames.toString()}
                                        </Typography>
                                        <Typography variant="body2">
                                            {fieldingRequest.fieldingRequestNotes[0].createdDateString}
                                        </Typography>
                                        <Typography variant="body1" marginTop={1}>
                                            {fieldingRequest.fieldingRequestNotes[0].content}
                                        </Typography>
                                    </Box>
                                )
                            }
                            action={
                                <IconButton onClick={() => setShowNotes(!showNotes)}>
                                    {showNotes ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                </IconButton>
                            }
                        />
                        {showNotes && (
                            <List disablePadding>
                                {fieldingRequest.fieldingRequestNotes.length > 0 ? (
                                    fieldingRequest.fieldingRequestNotes[0].comments.length > 0 ? (
                                        fieldingRequest.fieldingRequestNotes[0].comments.map((comment, i) => (
                                            <ListItem key={i}>
                                                <Card variant="outlined" sx={{ width: "100%" }}>
                                                    <CardContent>
                                                        <Typography variant="body1" fontWeight={"bold"}>
                                                            Message:
                                                        </Typography>
                                                        <Typography variant="body1">{comment.comment}</Typography>
                                                        <Box display={"flex"} justifyContent={"flex-end"}>
                                                            <Box>
                                                                <Typography variant="body1">
                                                                    From: {comment.name}
                                                                </Typography>
                                                                <Typography variant="body1">
                                                                    {comment.dateTimeString}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </CardContent>
                                                </Card>
                                            </ListItem>
                                        ))
                                    ) : (
                                        <ListItem>No reply</ListItem>
                                    )
                                ) : (
                                    <ListItem>No notes</ListItem>
                                )}
                                {fieldingRequest.fieldingRequestNotes.length > 0 && (
                                    <ListItem>
                                        <form onSubmit={handleComment} style={{ display: "flex", flexGrow: 1 }}>
                                            <TextField
                                                label="Reply"
                                                value={notesComment}
                                                onChange={(e) => setNotesComment(e.target.value)}
                                            />
                                            <IconButton type="submit">
                                                <Send />
                                            </IconButton>
                                        </form>
                                    </ListItem>
                                )}
                            </List>
                        )}
                    </Card>
                    <br />
                    <Box
                        display={"flex"}
                        flexDirection={"row"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        sx={{ borderTop: "1px solid lightgray", paddingTop: "14px" }}
                    >
                        <Typography variant="body1">Attachment Field Request</Typography>
                        <ProjectFormFieldingUpload fieldingRequestId={fieldingRequest.id} onUpdate={getDetails} />
                    </Box>
                    {(attachmentFieldingDocumentList.length > 0 || attachmentFieldingImageList.length > 0) && (
                        <Box
                            sx={{
                                borderBottom: "1px solid lightgray",
                                marginTop: "10px",
                                padding: "10px 0"
                            }}
                        >
                            <Box
                                sx={{
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    marginBottom: "10px"
                                }}
                            >
                                <Box>
                                    {idAttachmentFieldingSelected.length > 0 && (
                                        <>
                                            <ButtonOutlined
                                                color="error"
                                                startIcon={<Delete />}
                                                onClick={() => handleConfirmationDeletAttachment()}
                                                sx={{
                                                    ":hover": {
                                                        bgcolor: "#d32f2f",
                                                        color: "white"
                                                    }
                                                }}
                                            >
                                                Delete selected
                                            </ButtonOutlined>
                                            {LoadingDownload ? (
                                                <ButtonOutlined
                                                    color={"secondary"}
                                                    disabled={true}
                                                    startIcon={
                                                        <CircularProgress
                                                            color="inherit"
                                                            style={{
                                                                width: "12px",
                                                                height: "12px"
                                                            }}
                                                        />
                                                    }
                                                    sx={{
                                                        ":hover": {
                                                            bgcolor: "gray",
                                                            color: "white"
                                                        }
                                                    }}
                                                >
                                                    Download selected
                                                </ButtonOutlined>
                                            ) : (
                                                <ButtonOutlined
                                                    color={"primary"}
                                                    startIcon={<Download />}
                                                    onClick={() => handleDownloadSelectedtoZip("fielding")}
                                                    sx={{
                                                        ":hover": {
                                                            bgcolor: "#093a93",
                                                            color: "white"
                                                        }
                                                    }}
                                                >
                                                    Download selected
                                                </ButtonOutlined>
                                            )}
                                        </>
                                    )}
                                </Box>
                                <Box
                                    onClick={(e) => {
                                        if (
                                            idAttachmentFieldingSelected.length ===
                                            attachmentFieldingDocumentList.length + attachmentFieldingImageList.length
                                        )
                                            setIdAttachmentFieldingSelected([])
                                        else
                                            setIdAttachmentFieldingSelected([
                                                ...attachmentFieldingDocumentList.map(
                                                    (attachment: any) => attachment.id
                                                ),
                                                ...attachmentFieldingImageList.map((attachment: any) => attachment.id)
                                            ])
                                    }}
                                    className={"text-nowrap"}
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        cursor: "pointer"
                                    }}
                                >
                                    <Checkbox
                                        checked={
                                            attachmentFieldingDocumentList.length +
                                            attachmentFieldingImageList.length !==
                                            0 &&
                                            idAttachmentFieldingSelected.length ===
                                            attachmentFieldingDocumentList.length +
                                            attachmentFieldingImageList.length
                                        }
                                        sx={{ width: "25px" }}
                                    />
                                    <Typography className={"text-nowrap"}>Select All</Typography>
                                </Box>
                            </Box>
                            <div className="attachments">
                                {attachmentFieldingDocumentList.map((attachment) => (
                                    <div
                                        key={attachment.fileName}
                                        className="item files"
                                        style={{ display: "flex", overflow: "hidden" }}
                                    >
                                        <div className="action start">
                                            <Checkbox
                                                checked={idAttachmentFieldingSelected.includes(attachment.id)}
                                                onChange={(e) =>
                                                    idAttachmentFieldingSelected.includes(attachment.id)
                                                        ? setIdAttachmentFieldingSelected(
                                                            idAttachmentFieldingSelected.filter(
                                                                (idSelected) => idSelected !== attachment.id
                                                            )
                                                        )
                                                        : setIdAttachmentFieldingSelected([
                                                            ...idAttachmentFieldingSelected,
                                                            attachment.id
                                                        ])
                                                }
                                            />
                                        </div>
                                        <div className="action">
                                            <Tooltip title="download">
                                                {downloadFiles.url === attachment.filePath ? (
                                                    <CircularProgress
                                                        variant="determinate"
                                                        value={downloadFiles.progressValue}
                                                    />
                                                ) : (
                                                    <IconButton
                                                        disabled={
                                                            downloadFiles.url !== "" &&
                                                            downloadFiles.url !== attachment.filePath
                                                        }
                                                        onClick={() =>
                                                            handleDownload(attachment.filePath, attachment.fileName)
                                                        }
                                                    >
                                                        <Download />
                                                    </IconButton>
                                                )}
                                            </Tooltip>
                                            <Tooltip title="delete">
                                                <IconButton
                                                    color="error"
                                                    onClick={() =>
                                                        handleConfirmationDeletAttachment(false, attachment.id)
                                                    }
                                                >
                                                    <Delete />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            <span
                                                style={{
                                                    alignSelf: "start"
                                                }}
                                            >
                                                {attachment.fileName}
                                            </span>
                                            <span
                                                style={{
                                                    alignSelf: "start",
                                                    fontSize: "10px"
                                                }}
                                            >
                                                Upload by : {attachment.uploadedBy}
                                            </span>
                                        </div>
                                    </div>
                                ))}
                                {imageFieldingSelectedIndex !== null && (
                                    <ProjectFormFieldingImagePreview
                                        data={attachmentFieldingImageList[imageFieldingSelectedIndex]}
                                        isOpen={imageFieldingSelectedIndex !== null}
                                        onClose={() => setImageFieldingSelectedIndex(null)}
                                        onLeft={
                                            imageFieldingSelectedIndex > 0
                                                ? () => setImageFieldingSelectedIndex(imageFieldingSelectedIndex - 1)
                                                : null
                                        }
                                        onRight={
                                            imageFieldingSelectedIndex < attachmentFieldingImageList.length - 1
                                                ? () => setImageFieldingSelectedIndex(imageFieldingSelectedIndex + 1)
                                                : null
                                        }
                                    />
                                )}
                                {imageFieldingSelectedIndexDetail !== null && markerSelected?.images !== undefined && (
                                    <ProjectFormFieldingImagePreview
                                        data={markerSelected?.images[imageFieldingSelectedIndexDetail]}
                                        isOpen={imageFieldingSelectedIndexDetail !== null}
                                        onClose={() => setImageFieldingSelectedIndexDetail(null)}
                                        onLeft={
                                            imageFieldingSelectedIndexDetail > 0
                                                ? () =>
                                                    setImageFieldingSelectedIndexDetail(
                                                        imageFieldingSelectedIndexDetail - 1
                                                    )
                                                : null
                                        }
                                        onRight={
                                            imageFieldingSelectedIndexDetail < markerSelected?.images.length - 1
                                                ? () =>
                                                    setImageFieldingSelectedIndexDetail(
                                                        imageFieldingSelectedIndexDetail + 1
                                                    )
                                                : null
                                        }
                                    />
                                )}
                                {attachmentFieldingImageList.map((attachment, i) => (
                                    <AttachmentItem
                                        key={attachment.fileName + i}
                                        idAttachmentSelected={idAttachmentFieldingSelected}
                                        setIdAttachmentSelected={setIdAttachmentFieldingSelected}
                                        handleConfirmationDeletAttachment={handleConfirmationDeletAttachment}
                                        attachment={attachment}
                                        setImageSelectedIndex={() => setImageFieldingSelectedIndex(i)}
                                    />
                                ))}
                            </div>
                        </Box>
                    )}
                    {Boolean(selectedMarkerId) && (
                        <Box
                            flexDirection={"column"}
                            sx={{
                                borderTop: "4px solid #093a93",
                                marginTop: "20px"
                            }}
                        >
                            <br />
                            <Typography variant="h5">Markers Detail</Typography>
                            <TextField
                                margin="dense"
                                inputProps={{ readOnly: !isEdit }}
                                value={markerSelected?.subject}
                                onChange={(e) => handleChangeMarkerDetail("subject", e.target.value)}
                                label="Subject"
                            />
                            <TextField
                                margin="dense"
                                inputProps={{ readOnly: !isEdit }}
                                value={markerSelected?.notes}
                                onChange={(e) => handleChangeMarkerDetail("notes", e.target.value)}
                                label="Notes"
                                multiline
                            />
                            <FieldingTypeForm
                                readOnly={!isEdit}
                                value={markerSelected?.customTypeId || ""}
                                onChange={(value) => handleChangeMarkerDetail("customTypeId", value)}
                            />
                            <Grid container padding={1}>
                                <Typography fontWeight={"bold"}>Progress: {markerSelected?.progressPercentage?.toFixed(2)}%</Typography>
                            </Grid>
                            <Grid container padding={1}>
                                <Grid item md={6}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={markerSelected?.requestedAmountType === 0}
                                                onChange={(e) =>
                                                    isEdit && handleChangeMarkerDetail("requestedAmountType", 0)
                                                }
                                            />
                                        }
                                        label="Footage"
                                    />
                                </Grid>
                                <Grid item md={6}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={markerSelected?.requestedAmountType === 1}
                                                onChange={(e) =>
                                                    isEdit && handleChangeMarkerDetail("requestedAmountType", 1)
                                                }
                                            />
                                        }
                                        label="Quantity"
                                    />
                                </Grid>
                            </Grid>
                            <TextField
                                margin="dense"
                                inputProps={{ readOnly: !isEdit }}
                                value={markerSelected?.requestedFielding || " "}
                                onChange={(e) => handleChangeMarkerDetail("requestedFielding", e.target.value)}
                                label="Request"
                            />
                            <TextField
                                margin="dense"
                                inputProps={{ readOnly: true }}
                                value={markerSelected?.frontyard ?? ""}
                                label="Frontyard"
                            />
                            <TextField
                                margin="dense"
                                inputProps={{ readOnly: true }}
                                value={markerSelected?.backyard ?? ""}
                                label="Backyard"
                            />
                            <TextField
                                margin="dense"
                                inputProps={{ readOnly: true }}
                                value={markerSelected?.noAccessPole ?? ""}
                                label="No Access Pole"
                            />
                            <TextField
                                margin="dense"
                                inputProps={{ readOnly: true }}
                                value={markerSelected?.underground ?? ""}
                                label="Underground"
                            />
                            <TextField
                                margin="dense"
                                inputProps={{ readOnly: true }}
                                value={markerSelected?.structures ?? ""}
                                label="Structures"
                            />
                            <TextField
                                margin="dense"
                                inputProps={{ readOnly: true }}
                                value={markerSelected?.noAccessStructures ?? ""}
                                label="No Access Structures"
                            />
                            <TextField
                                margin="dense"
                                inputProps={{ readOnly: true }}
                                value={markerSelected?.structuresNotInField ?? ""}
                                label="Structures Not In Field"
                            />
                            {markerSelected?.images !== undefined && (
                                <Box
                                    sx={{
                                        borderTop: "1px solid lightgray",
                                        borderBottom: "1px solid lightgray",
                                        marginTop: "10px",
                                        padding: "10px 0",
                                        marginBottom: "20px"
                                    }}
                                >
                                    <Box
                                        display={"flex"}
                                        flexDirection={"row"}
                                        alignItems={"center"}
                                        justifyContent={"space-between"}
                                        marginBottom={"10px"}
                                    >
                                        <Typography variant="body1">Attachment</Typography>
                                        <ProjectFormFieldingMarkerUpload
                                            fieldingRequestMarkerId={selectedMarkerId}
                                            onUpdate={getDetails}
                                        />
                                    </Box>
                                    {markerSelected?.images.length > 0 && (
                                        <Box
                                            sx={{
                                                width: "100%",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                marginBottom: "10px"
                                            }}
                                        >
                                            <Box>
                                                {idAttachmentMarkerSelected.length > 0 && (
                                                    <>
                                                        <Button
                                                            variant="outlined"
                                                            color="error"
                                                            startIcon={<Delete />}
                                                            onClick={handleDeleteMarkerAttachmentMultiple}
                                                            sx={{
                                                                ":hover": {
                                                                    bgcolor: "#d32f2f",
                                                                    color: "white"
                                                                }
                                                            }}
                                                        >
                                                            Delete selected
                                                        </Button>
                                                        {LoadingDownload ? (
                                                            <Button
                                                                variant="outlined"
                                                                color={"secondary"}
                                                                disabled={true}
                                                                startIcon={
                                                                    <CircularProgress
                                                                        color="inherit"
                                                                        style={{
                                                                            width: "12px",
                                                                            height: "12px"
                                                                        }}
                                                                    />
                                                                }
                                                                sx={{
                                                                    ":hover": {
                                                                        bgcolor: "gray",
                                                                        color: "white"
                                                                    }
                                                                }}
                                                            >
                                                                Download selected
                                                            </Button>
                                                        ) : (
                                                            <Button
                                                                variant="outlined"
                                                                color={"primary"}
                                                                startIcon={<Download />}
                                                                onClick={() => handleDownloadSelectedtoZip("marker")}
                                                                sx={{
                                                                    ":hover": {
                                                                        bgcolor: "#093a93",
                                                                        color: "white"
                                                                    }
                                                                }}
                                                            >
                                                                Download selected
                                                            </Button>
                                                        )}
                                                    </>
                                                )}
                                            </Box>
                                            <Box
                                                onClick={(e) => {
                                                    if (
                                                        idAttachmentMarkerSelected.length ===
                                                        markerSelected?.images?.length
                                                    )
                                                        setIdAttachmentMarkerSelected([])
                                                    else
                                                        setIdAttachmentMarkerSelected(
                                                            markerSelected?.images !== undefined
                                                                ? markerSelected?.images?.map(
                                                                    (attachment: any) => attachment.id
                                                                )
                                                                : []
                                                        )
                                                }}
                                                className={"text-nowrap"}
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    cursor: "pointer"
                                                }}
                                            >
                                                <Checkbox
                                                    checked={
                                                        markerSelected?.images?.length !== 0 &&
                                                        idAttachmentMarkerSelected.length ===
                                                        markerSelected?.images?.length
                                                    }
                                                    sx={{ width: "25px" }}
                                                />
                                                <Typography className={"text-nowrap"}>Select All</Typography>
                                            </Box>
                                        </Box>
                                    )}
                                    <div className="attachments">
                                        {imageMarkerSelectedIndex !== null && (
                                            <ProjectFormFieldingMarkerImagePreview
                                                data={markerSelected?.images[imageMarkerSelectedIndex]}
                                                isOpen={imageMarkerSelectedIndex !== null}
                                                onClose={() => setImageMarkerSelectedIndex(null)}
                                                onLeft={
                                                    imageMarkerSelectedIndex > 0
                                                        ? () =>
                                                            setImageMarkerSelectedIndex(imageMarkerSelectedIndex - 1)
                                                        : null
                                                }
                                                onRight={
                                                    imageMarkerSelectedIndex < markerSelected?.images.length - 1
                                                        ? () =>
                                                            setImageMarkerSelectedIndex(imageMarkerSelectedIndex + 1)
                                                        : null
                                                }
                                            />
                                        )}
                                        {markerSelected?.images.map((attachment, i) => (
                                            <AttachmentItem
                                                key={attachment.fileName + i}
                                                idAttachmentSelected={idAttachmentMarkerSelected}
                                                setIdAttachmentSelected={setIdAttachmentMarkerSelected}
                                                handleConfirmationDeletAttachment={handleDeleteFieldingMarkerAttachment}
                                                attachment={attachment}
                                                setImageSelectedIndex={() => setImageFieldingSelectedIndexDetail(i)}
                                                type={"marker"}
                                            />
                                        ))}
                                    </div>
                                </Box>
                            )}
                        </Box>
                    )}
                </div>
            </div>
        </div>
    )
}

const AttachmentItem = (props: any) => {
    const {
        idAttachmentSelected,
        setIdAttachmentSelected,
        handleConfirmationDeletAttachment,
        attachment,
        setImageSelectedIndex,
        type = "request"
    } = props
    const [IsLoading, setIsLoading] = useState(true)
    const [IsLoadingDownload, setIsLoadingDownload] = useState(false)

    const handleDownloadFiles = async () => {
        setIsLoadingDownload(true)
        await fetch(attachment.filePath)
            .then((response) => {
                console.log("response", response)

                return response.blob()
            })
            .then((blob) => {
                console.log("blob", blob)

                //   setFetching(false);
                const blobURL = URL.createObjectURL(blob)
                console.log("blobURL", blobURL)
                const a: any = document.createElement("a")
                a.href = blobURL
                a.style = "display: none"

                if (attachment.fileName && attachment.fileName.length) a.download = attachment.fileName
                document.body.appendChild(a)
                a.click()
            })
            .catch((err) => {
                console.log("err", err)
            })
        setIsLoadingDownload(false)
    }

    return (
        <Fragment>
            <div className="item">
                <div
                    className="action start text-nowrap"
                    style={{
                        zIndex: 2,
                        display: "flex",
                        justifyContent: "space-between"
                    }}
                >
                    <Checkbox
                        checked={idAttachmentSelected.includes(attachment.id)}
                        onChange={(e) =>
                            idAttachmentSelected.includes(attachment.id)
                                ? setIdAttachmentSelected(
                                    idAttachmentSelected.filter((idSelected: any) => idSelected !== attachment.id)
                                )
                                : setIdAttachmentSelected([...idAttachmentSelected, attachment.id])
                        }
                        style={{ padding: "2px" }}
                    />
                    <div
                        style={{
                            position: "relative",
                            zIndex: 1
                        }}
                    >
                        {IsLoadingDownload && (
                            <div
                                style={{
                                    position: "absolute",
                                    marginTop: "4px",
                                    marginLeft: "4px"
                                }}
                            >
                                <CircularProgress
                                    color="inherit"
                                    style={{
                                        width: "14px",
                                        height: "14px"
                                    }}
                                />
                            </div>
                        )}
                        <Tooltip title="download">
                            <IconButton
                                disabled={IsLoadingDownload}
                                onClick={() => !IsLoadingDownload && handleDownloadFiles()}
                                style={{ padding: "2px" }}
                            >
                                <Download color={IsLoadingDownload ? "secondary" : "primary"} />
                            </IconButton>
                            {/* </a> */}
                        </Tooltip>
                    </div>
                    <Tooltip title="delete">
                        <IconButton
                            onClick={() =>
                                type === "marker"
                                    ? handleConfirmationDeletAttachment(attachment.id)
                                    : handleConfirmationDeletAttachment(false, attachment.id)
                            }
                            style={{ padding: "2px" }}
                        >
                            <Delete color="error" />
                        </IconButton>
                    </Tooltip>
                </div>
                <div
                    className="action"
                    style={{
                        zIndex: 2
                    }}
                >
                </div>
                <div
                    style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        zIndex: 1
                    }}
                >
                    {IsLoading && (
                        <div
                            style={{
                                position: "absolute",
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                opacity: "0.6"
                            }}
                        >
                            <CircularProgress color="inherit" />
                        </div>
                    )}
                    <img
                        src={attachment.filePath + "?width=150"}
                        alt={attachment.fileName}
                        onClick={() => setImageSelectedIndex()}
                        onLoad={() => setIsLoading(false)}
                        onError={(err) => setIsLoading(false)}
                    />
                </div>
            </div>
        </Fragment>
    )
}
