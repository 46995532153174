import { Close, Notifications } from "@mui/icons-material"
import { Badge, Box, Button, IconButton, Modal, Typography, useMediaQuery } from "@mui/material"
import { Fragment, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
    fieldingAssignFtrAccept,
    fieldingAssignFtrDecline,
    fieldingAssignedGet,
    fieldingGetNotification,
    fieldingReadNotification
} from "../../pages/fielding/fielding.api"
import { RootState } from "../../store"
import { useNavigate } from "react-router-dom"
import receive_assign_logo from "../../assets/images/receive_assign_logo.png"
import notificationSuccess from "../../shared/notificationSuccess"

export default function HeaderNotifications() {
    const dispatch = useDispatch<any>()
    const navigate = useNavigate()
    const isMobile = useMediaQuery("(max-width:768px)")

    const { dataNotificationList } = useSelector((state: RootState) => state.fielding)

    useEffect(() => {
        dispatch(fieldingGetNotification())
        const i = setInterval(() => {
            dispatch(fieldingGetNotification())
        }, 60000)
        return () => clearInterval(i)
    }, [])

    useEffect(() => {
        clearLocalStorage()
    }, [])
    
    const clearLocalStorage = async () => {
        let lastLocalUser = localStorage.getItem("fielderProData")
        let lastLocalUser2 = localStorage.getItem("fielderProAccount")
        let lastLocalUser3 = localStorage.getItem("fielderProUserData")
        if (lastLocalUser || lastLocalUser2 || lastLocalUser3) {
            await localStorage.removeItem("fielderProData")
            await localStorage.removeItem("fielderProAccount")
            await localStorage.removeItem("fielderProUserData")
            await localStorage.removeItem("fielderProUserAccount")
            setTimeout(() => {
                window.location.href = window.location.href
            }, 1000);
        }
    }

    const handleAccept = (fieldingRequestId: string) => {
        dispatch(fieldingAssignFtrAccept(fieldingRequestId))
            .unwrap()
            .then(async (response: any) => {
                await dispatch(fieldingAssignedGet(0))
                if (response.status === 200) {
                    notificationSuccess("")
                    dispatch(fieldingGetNotification())
                }
            })
    }

    const handleDecline = (fieldingRequestId: string) => {
        dispatch(fieldingAssignFtrDecline(fieldingRequestId))
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    notificationSuccess("")
                    dispatch(fieldingGetNotification())
                }
            })
    }

    const handleRead = () => {
        dispatch(fieldingReadNotification())
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    dispatch(fieldingGetNotification())
                }
            })
    }

    const logoWidth = isMobile ? 100 : 200

    const dataNotificationListFiltered = dataNotificationList.filter((data) => !data.isRead && data.isFirstToReceive)

    return (
        <Fragment>
            <Modal open={dataNotificationListFiltered.length > 0}>
                {dataNotificationListFiltered.length > 0 ? (
                    <div className="custom-modal">
                        <Box bgcolor={"white"} borderRadius={1} width={"40vw"} minWidth={"300px"}>
                            <Box
                                display={"flex"}
                                justifyContent={"flex-end"}
                                alignItems={"flex-start"}
                                borderRadius={1}
                                padding={1}
                                bgcolor={"#093a93"}
                                height={`${logoWidth * 0.8}px`}
                            >
                                <IconButton onClick={handleRead}>
                                    <Close sx={{ color: "white" }} />
                                </IconButton>
                            </Box>
                            <Box
                                display={"flex"}
                                flexDirection={"column"}
                                alignItems={"center"}
                                padding={1}
                                paddingTop={`${logoWidth / 2}px`}
                                marginBottom={`${logoWidth / 6}px`}
                                position={"relative"}
                            >
                                <img
                                    src={receive_assign_logo}
                                    alt="logo"
                                    style={{
                                        height: `${logoWidth}px`,
                                        position: "absolute",
                                        top: `-${logoWidth / 2}px`
                                    }}
                                />
                                <Typography variant="h4" color={"#093a93"} marginY={3} sx={{textAlign:"center"}}>
                                    You Receive New Request
                                </Typography>
                                <Typography sx={{textAlign:"center"}}>
                                    You receive request fielding{" "}
                                    <b>
                                        {dataNotificationListFiltered[0].project} -{" "}
                                        {dataNotificationListFiltered[0].fieldingRequest}
                                    </b>
                                </Typography>
                                <Typography>would you like to accept it?</Typography>
                                <Box sx={{ paddingTop: "8px" }}>
                                    <Box sx={{ display: "flex" }}>
                                        <Typography sx={{ width: "70px", fontWeight: "bold" }}>Due date </Typography>
                                        <Typography>
                                            : {dataNotificationListFiltered[0].fieldingRequestDueDate}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: "flex" }}>
                                        <Typography sx={{ width: "70px", fontWeight: "bold" }}>Date range </Typography>
                                        <Typography>
                                            :{" "}
                                            {dataNotificationListFiltered[0].fieldingRequestRequestedStartDate !==
                                                "-" &&
                                            dataNotificationListFiltered[0].fieldingRequestRequestedDate !== "-" ? (
                                                <>
                                                    {dataNotificationListFiltered[0]
                                                        .fieldingRequestRequestedStartDate !== "-"
                                                        ? dataNotificationListFiltered[0]
                                                              .fieldingRequestRequestedStartDate
                                                        : ""}{" "}
                                                    -{" "}
                                                    {dataNotificationListFiltered[0].fieldingRequestRequestedDate !==
                                                    "-"
                                                        ? dataNotificationListFiltered[0].fieldingRequestRequestedDate
                                                        : ""}
                                                </>
                                            ) : (
                                                "-"
                                            )}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Box display={"flex"} gap={1} padding={1}>
                                <Button
                                    fullWidth
                                    color="error"
                                    variant="contained"
                                    onClick={() => handleDecline(dataNotificationListFiltered[0].fieldingRequestId)}
                                >
                                    Decline
                                </Button>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    onClick={() => handleAccept(dataNotificationListFiltered[0].fieldingRequestId)}
                                >
                                    Accept
                                </Button>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="success"
                                    onClick={() =>
                                        navigate(
                                            `/fielding/detail?preview=true&id=${dataNotificationListFiltered[0].fieldingRequestId}`
                                        )
                                    }
                                >
                                    View On Map
                                </Button>
                            </Box>
                        </Box>
                    </div>
                ) : (
                    <></>
                )}
            </Modal>
            <IconButton color="inherit" onClick={() => navigate("/notification")}>
                <Badge badgeContent={dataNotificationList.filter((data) => !data.isRead).length} color="error">
                    <Notifications />
                </Badge>
            </IconButton>
        </Fragment>
    )
}
