import {useCallback, useEffect, useRef, useState} from "react";
import { esriToken } from "../../../state";

import esriConfig from "@arcgis/core/config"
import esriMap from "@arcgis/core/Map"
import esriMapView from "@arcgis/core/views/MapView"
import esriGraphicsLayer from "@arcgis/core/layers/GraphicsLayer"
import esriZoom from "@arcgis/core/widgets/Zoom"
import esriSearch from "@arcgis/core/widgets/Search"
import esriGraphics from "@arcgis/core/Graphic"
import esriPoint from "@arcgis/core/geometry/Point"
import esriMarkerSymbol from "@arcgis/core/symbols/SimpleMarkerSymbol"
import esriPictureMarkerSymbol from "@arcgis/core/symbols/PictureMarkerSymbol"
import esriTextSymbol from "@arcgis/core/symbols/TextSymbol"
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { projectGet } from "../project.api";
import hot from "../../../assets/images/hot.png"
import esriSketch from "@arcgis/core/widgets/Sketch";
import {webMercatorToGeographic} from "@arcgis/core/geometry/support/webMercatorUtils";
import {InterfaceMarkerLine, InterfaceMarkerPoint} from "../../fielding/fielding.interface";
import CropIcon from '@mui/icons-material/Crop';
import EditNoteIcon from '@mui/icons-material/EditNote';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';

import * as turf from '@turf/turf';
import {Button, useTheme} from "@mui/material";
import {Done} from "@mui/icons-material";
import {InterfaceProjectDataList} from "../project.interface";
import esriPolygon from "@arcgis/core/geometry/Polygon";
import esriSimpleFillSymbol from "@arcgis/core/symbols/SimpleFillSymbol";
import CustomModal from "../../../shared/customModal/CustomModal";
import ModalAddNotes from "./modals/ModalAddNotes";
import ModalMultiProjectForm from "./modals/ModalMultiProjectForm";
import Swal from "sweetalert2";
import {deleteAccount, getAccount} from "../../account/account.api";
import notificationSuccess from "../../../shared/notificationSuccess";
import notificationError from "../../../shared/notificationError";

export default function ProjectMap(props: {
    onClickGraphic: any,
}) {

    const mapRef = useRef<any>()
    const dispatch = useDispatch<any>()
    const theme = useTheme()

    const projectList = useSelector((state: RootState) => state.project).dataList
    const parameterGetData = useSelector((state: RootState) => state.project).parameterGetData
    const center = projectList.length > 0
        ? {
            latitude: parseFloat(projectList[0].firstMarkerLocation.split(",")[1]),
            longitude: parseFloat(projectList[0].firstMarkerLocation.split(",")[0])
        }
        : null

    const [view, setView] = useState<esriMapView>()
    const [layerMarker, setLayerMarker] = useState<any>()

    // selected area marker
    const [sketch, setSketch] = useState<esriSketch>()
    const [layerMarkerTemp, setLayerMarkerTemp] = useState<any>()
    const [tempPolygon, setTempPolygon] = useState<InterfaceMarkerLine>([])
    const [onMarkArea, setOnMarkArea] = useState<boolean>(false)

    const [selectedProject, setSelectedProject] = useState<InterfaceProjectDataList>([])

    const [saveState, setSaveState] = useState<"idle" | "save">("idle");

    useEffect(() => {
        dispatch(projectGet({
            ...parameterGetData,
            take: 999999
        }))
    }, [dispatch, parameterGetData])

    useEffect(() => {
        // initialize
        esriConfig.apiKey = esriToken
        let map = new esriMap({
            basemap: "topo-vector" //"satellite"
        })
        let layerMarkerLocal = new esriGraphicsLayer()
        let layerMarkerTempLocal = new esriGraphicsLayer()
        map.add(layerMarkerLocal)
        map.add(layerMarkerTempLocal)
        let viewLocal = new esriMapView({
            container: mapRef.current,
            map: map,
            zoom: center !== null ? 15 : 5,
            center: center !== null ? [center.longitude, center.latitude] : [-99.09764528271961, 33.173506394266624],
        })
        // widget
        let viewZoom = new esriZoom({ view: viewLocal })
        viewLocal.ui.remove(viewZoom)
        let viewSearch = new esriSearch({ view: viewLocal, locationEnabled: false })
        viewLocal.ui.add(viewSearch, { position: "top-left" })
        const sketchLocal = new esriSketch({
            layer: layerMarkerTempLocal,
            view: viewLocal
        })
        setSketch(sketchLocal)
        // listener
        viewLocal.on("click", (event) => {
            viewLocal.hitTest(event)
                .then((response) => {
                    let firstResult = response.results[0]
                    if (firstResult.type === "graphic") {
                        if (firstResult.graphic.attributes?.type === "PROJECT_DOT" || firstResult.graphic.attributes?.type === "PROJECT_NAME") {
                            props.onClickGraphic(firstResult.graphic.attributes?.markerId)
                        }
                    }
                })
        })
        viewLocal.on("layerview-create", () => {
            if (document.getElementById("searchbox-input")) {
                (document.getElementById("searchbox-input") as HTMLInputElement).placeholder = "Enter adress or GPS"
            }
        })
        setView(viewLocal)
        setLayerMarker(layerMarkerLocal)
        setLayerMarkerTemp(layerMarkerTempLocal)
        return () => viewLocal?.destroy()
    }, [])

    // useEffect(() => {
    //     if (view && !onMarkArea) {
    //         view.goTo({
    //             zoom: center !== null ? 15 : 5,
    //             center: center !== null ? [center.longitude, center.latitude] : [-99.09764528271961, 33.173506394266624]
    //         })
    //     }
    // }, [center, onMarkArea])

    const onAreaClicked = useCallback(() => {
        sketch?.create("polygon")
        setOnMarkArea(true)
        sketch?.on("create", function (event) {
            console.log(event.state)
            if (event.state === "complete") {
                layerMarkerTemp.removeAll()
                var polygonGeometry = event.graphic.geometry
                var wgs84Geometry = webMercatorToGeographic(polygonGeometry)
                const geometry: any = wgs84Geometry
                if (geometry.rings && geometry.rings.length > 0) {
                    const newShapeData: InterfaceMarkerLine = geometry.rings[0].map((latlng: any) => {
                        return {
                            latitude: latlng[1],
                            longitude: latlng[0]
                        }
                    })
                    setTempPolygon(newShapeData)
                }
                sketch?.cancel()
                setOnMarkArea(false)
            } else if (event.state === "cancel") {
                layerMarkerTemp.removeAll()
                setOnMarkArea(false)
            }
        })
    }, [sketch])

    useEffect(() => {
        if (tempPolygon.length > 0 && projectList.length > 0) {

            if (tempPolygon.length <= 4) {
                setSelectedProject([])
                alert("Please draw a polygon with at least 4 points")
                return
            }
            // filter projectList by firstMarkerLocation is ""
            let filteredProjectList = projectList.filter(project => project.firstMarkerLocation !== "")

            let project = filteredProjectList.filter(project => {
                let point = turf.point([ parseFloat(project.firstMarkerLocation.split(',')[0]), parseFloat(project.firstMarkerLocation.split(',')[1])])
                let polygon = turf.polygon([tempPolygon.map(point => [point.longitude, point.latitude])])
                return turf.booleanPointInPolygon(point, polygon)
            })

            setSelectedProject(project)
        }
    }, [tempPolygon, projectList]);

    useEffect(() => {
        if (layerMarkerTemp) {
            layerMarkerTemp.removeAll()
            if (tempPolygon.length > 0) {
                let polygon = new esriGraphics({
                    geometry: new esriPolygon({
                        rings: [tempPolygon.map(point => [point.longitude, point.latitude])]
                    }),
                    symbol: new esriSimpleFillSymbol({
                        color: [227, 139, 79, 0.8],
                        outline: {
                            color: [255, 255, 255],
                            width: 1
                        }
                    }),
                    attributes: {
                        type: "FIELDING_SHAPE",
                    }
                })
                layerMarkerTemp.add(polygon)
            }
        }
    }, [tempPolygon]);

    useEffect(() => {
        if (layerMarker) {
            let textMarkers: Array<any> = []
            let pointMarkers: Array<any> = []
            let hotMarkers: Array<any> = []

            // filter projectList by firstMarkerLocation is ""
            let filteredProjectList = projectList.filter(project => project.firstMarkerLocation !== "")

            pointMarkers = [...pointMarkers, ...filteredProjectList.map(project => {
                textMarkers.push(new esriGraphics({
                    geometry: new esriPoint({
                        latitude: parseFloat(project.firstMarkerLocation.split(',')[1]),
                        longitude: parseFloat(project.firstMarkerLocation.split(',')[0])
                    }),
                    symbol: new esriTextSymbol({
                        color: "black",
                        text: project.projectNumber,
                        xoffset: 40,
                        yoffset: -25,
                        font: {
                            size: 12,
                            family: "Roboto"
                        }
                    }),
                    attributes: {
                        type: "PROJECT_TEXT",
                        markerId: project.id
                    }
                }))
                return new esriGraphics({
                    geometry: new esriPoint({
                        latitude: parseFloat(project.firstMarkerLocation.split(',')[1]),
                        longitude: parseFloat(project.firstMarkerLocation.split(',')[0])
                    }),
                    symbol: new esriMarkerSymbol({
                        style: "circle",
                        color: "red"
                    }),
                    attributes: {
                        type: "PROJECT_DOT",
                        markerId: project.id
                    }
                })
            })]
            hotMarkers = projectList.filter(project => project.isHotJob).map(project => {
                return new esriGraphics({
                    geometry: new esriPoint({
                        latitude: parseFloat(project.firstMarkerLocation.split(',')[1]),
                        longitude: parseFloat(project.firstMarkerLocation.split(',')[0])
                    }),
                    symbol: new esriPictureMarkerSymbol({
                        url: hot,
                        height: 20,
                        width: 20,
                        xoffset: 20
                    })
                })
            })
            layerMarker.removeAll()
            if (pointMarkers.length > 0) {
                layerMarker.addMany(pointMarkers)
            }
            if (textMarkers.length > 0) {
                layerMarker.addMany(textMarkers)
            }
            if (hotMarkers.length > 0) {
                layerMarker.addMany(hotMarkers)
            }
        }
    }, [layerMarker, projectList])

    console.log('tempPolygon', tempPolygon)
    console.log('projectList', projectList)

    const handleFinish = () => {
        sketch?.complete()
        setOnMarkArea(false)
    }

    const [modalMultiProject, setModalMultiProject] = useState(false)

    const onMultipleEditClose = () => {
            Swal.fire({
                icon: 'question',
                title: 'Do you want to save the changes?',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
            }).then((result) => {
                if (result.isConfirmed) {
                    setSaveState("save")
                } else {
                    setSaveState("idle")

                    setModalMultiProject(false)
                    // setTempPolygon([])
                    dispatch(projectGet({
                        ...parameterGetData,
                        take: 999999
                    }))
                }
            });
    }

    return (
        <>
            <CustomModal
                open={modalMultiProject}
                onClose={onMultipleEditClose}
                title={`Edit ${selectedProject.length} Project`}
                component={<ModalMultiProjectForm saveState={saveState} resetSaveState={() => setSaveState('idle')} projectList={selectedProject} onClose={() => {
                    setModalMultiProject(false)
                    // setTempPolygon([])
                    dispatch(projectGet({
                        ...parameterGetData,
                        take: 999999
                    }))
                    setSaveState("idle")
                }} />}
                size={'xl'}
                fullHeight={true}
            />
            <div className="arcgis-container">
                <div ref={mapRef} style={{height: "100%", width: "100%"}}/>
                {
                   selectedProject.length === 0 ? (
                       <div className="map-pallete end" style={{backgroundColor: theme.palette.primary.main}}>
                           <Button
                               onClick={onMarkArea ? handleFinish : onAreaClicked}
                               startIcon={onMarkArea ? <Done/> : <CropIcon/>}
                               sx={{color: "white"}}
                           >
                               {onMarkArea ? "Finish" : "Mark Project"}
                           </Button>
                       </div>
                   ) : (
                       <div className="map-pallete end" style={{backgroundColor: theme.palette.primary.main}}>
                           <Button
                               onClick={() => {
                                      setSelectedProject([])
                                      setTempPolygon([])
                               }}
                               startIcon={<CancelPresentationIcon />}
                               sx={{color: "white"}}
                           >
                               {"Clear Marking"}
                           </Button>
                       </div>
                   )
                }
                {
                    selectedProject.length > 0 && (
                        <div className="map-pallete end"
                             style={{backgroundColor: theme.palette.primary.main, top: '270px'}}>
                            <Button
                                onClick={() => setModalMultiProject(true)}
                                startIcon={<EditNoteIcon/>}
                                sx={{color: "white"}}
                            >
                                Edit {selectedProject.length} Project
                            </Button>
                        </div>
                    )
                }
            </div>
        </>
    )
}