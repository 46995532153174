import { NearMe } from "@mui/icons-material";
import { Autocomplete, Box, Button, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";
import Swal from "sweetalert2";
import CustomModal from "../../../shared/customModal/CustomModal";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAccountFielder } from "../../account/account.api";
import { RootState } from "../../../store";
import { formatName, userInfo } from "../../../shared/utils";
import { fieldingDetailsGet, startFielding } from "../fielding.api";
import { InterfaceFieldingDetail } from "../fielding.interface";

export default function FieldingStart(props: { id: string }) {

    const navigate = useNavigate()
    const dispatch = useDispatch<any>()

    const { dataFielderList } = useSelector((state: RootState) => state.account)

    const [modalTeam, setModalTeam] = useState(false)
    const [fieldingIdList, setFieldingIdList] = useState<string[]>([])
    const [fieldingAssignedIdList, setFieldingAssignedIdList] = useState<string[]>([])

    useEffect(() => {
        if (modalTeam) {
            dispatch(getAccountFielder())
            dispatch(fieldingDetailsGet(props.id))
                .unwrap()
                .then((response: any) => {
                    let data: InterfaceFieldingDetail = response.data.data
                    setFieldingAssignedIdList(data.fielderIds)
                })
        }
    }, [modalTeam, props.id, dispatch])

    useEffect(() => {
        if (modalTeam) {
            localStorage.setItem("startMateIdList", JSON.stringify(fieldingIdList.filter(d => d !== "none" && d !== "")))
        }
    }, [modalTeam, fieldingIdList])

    const handleStart = async () => {
        await Swal.fire({
            icon: "question",
            title: "Are you driving?",
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No"
        })
        setModalTeam(true)
    }

    const options: { id: string, fullName: string }[] = [
        { id: "none", fullName: "-" },
        ...dataFielderList.filter(data => fieldingAssignedIdList.includes(data.id) === true && data.id !== userInfo().user.id)
    ]

    const handleFinishStart = async () => {
        await dispatch(startFielding(props.id)).unwrap()
        navigate(`/fielding/detail?id=${props.id}`)
    }

    return (
        <Fragment>
            <CustomModal
                open={modalTeam}
                onClose={() => setModalTeam(false)}
                title="Who is your teammate"
                component={(
                    <Box>
                        {fieldingIdList.some(data => data === "none")
                            ? (
                                <Autocomplete
                                    readOnly={false}
                                    fullWidth
                                    options={options}
                                    getOptionLabel={(value: { id: string, fullName: string }) => formatName(value.fullName)}
                                    onChange={(e, value) => setFieldingIdList([value?.id || ''])}
                                    value={options.find((data) => fieldingIdList.includes(data.id))}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            )
                            : (
                                <Autocomplete
                                    readOnly={false}
                                    fullWidth
                                    multiple
                                    options={options}
                                    getOptionLabel={(value: { id: string, fullName: string }) => formatName(value.fullName)}
                                    onChange={(e, value) => value.some(v => v.id === "none") ? setFieldingIdList(["none"]) : setFieldingIdList(value.map((v) => v.id || ''))}
                                    value={options.filter((data) => fieldingIdList.includes(data.id))}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            )
                        }
                        <Button fullWidth sx={{ marginTop: 1 }} variant="contained" onClick={handleFinishStart}>Done</Button>
                    </Box>
                )}
            />
            <Button fullWidth variant="contained" startIcon={<NearMe />} sx={{ bgcolor: '#0443F2' }} onClick={handleStart}>Start</Button>
        </Fragment>
    )
}