import { ArrowBackIos, ArrowForwardIos, Close, Download } from "@mui/icons-material";
import {Box, Button, IconButton, LinearProgress, Modal, Tooltip, Typography} from "@mui/material";
import axios from "axios";
import { useState } from "react";
import {supportedImageTypes} from "../state";
import {defaultStyles, FileIcon} from "react-file-icon";

export default function ImagePreview(props: {
    src: string,
    name: string,
    isOpen: boolean,
    onClose: any,
    onLeft: any,
    onRight: any
}) {

    const [downloadImages, setDownloadImages] = useState<any>({
        isDownloading: false,
        progressValue: 70
    })

    const handleDownload = () => {
        if (!downloadImages.isDownloading) {
            setDownloadImages({
                isDownloading: true,
                progressValue: 0
            })
            axios({
                url: props.src,
                method: "GET",
                responseType: "blob",
                onDownloadProgress: (progressEvent) => {
                    let percentCompleted = Math.round((progressEvent.loaded * 100) / (progressEvent.total || 0));
                    setDownloadImages({
                        isDownloading: true,
                        progressValue: percentCompleted
                    })
                }
            })
                .then(response => {
                    const downloadLink = document.createElement('a')
                    downloadLink.href = window.URL.createObjectURL(new Blob([response.data]))
                    downloadLink.setAttribute('download', props.name)
                    document.body.appendChild(downloadLink)
                    downloadLink.click()
                    downloadLink.remove()
                    setDownloadImages({
                        isDownloading: false,
                        progressValue: 0
                    })
                })
        }
    }

    return (
        <Modal
            className='custom_modal'
            open={props.isOpen}
        >
            <div className="image_preview">
                <div className="content">
                    <div className="close">
                        <Tooltip title="Close">
                            <IconButton onClick={props.onClose} color="inherit">
                                <Close />
                            </IconButton>
                        </Tooltip>
                    </div>
                    {supportedImageTypes.includes(props.name.split('.').pop() as string) ? (
                    <img src={props.src} alt="image" />
                    ) : (
                        <Box sx={{cursor: 'pointer'}} m={'30px'}>
                            <FileIcon
                                extension={props.name.split('.').pop() || ''} {...(defaultStyles[props.name.split('.').pop() as keyof typeof defaultStyles] || {})} />
                            <Typography color={'white'} noWrap my={1}>{props.name}</Typography>
                        </Box>
                    )}
                    <div className="download">
                        <Button startIcon={<Download />} color="inherit" onClick={handleDownload}>
                            Download File
                        </Button>
                        {downloadImages.isDownloading && (<LinearProgress variant="determinate" value={downloadImages.progressValue} />)}
                    </div>
                    {props.onLeft !== null && (
                        <div className="left">
                            <IconButton color="inherit" onClick={props.onLeft}>
                                <ArrowBackIos />
                            </IconButton>
                        </div>
                    )}
                    {props.onRight !== null && (
                        <div className="right">
                            <IconButton color="inherit" onClick={props.onRight}>
                                <ArrowForwardIos />
                            </IconButton>
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    )
}