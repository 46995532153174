import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../state";
import { AuthCompanyActivate, AuthCompanyInterfaceCreate } from "./auth.interface";
import handleError from "../../shared/handleError";

export const login = createAsyncThunk('/newapi/account/FPWebLogin', async (data: any) => {
    try {
        const response = await axios({
            method: 'post',
            url: baseUrl + '/newapi/account/FPWebLogin',
            data: data
        })
        return response
    } catch (error: any) {
        handleError(error)
        return error.response
    }
})

export const createCompany = createAsyncThunk('createCompany', async (data: AuthCompanyInterfaceCreate) => {
    try {
        const response = await axios({
            method: 'post',
            url: baseUrl + '/api/Company/FPCreate',
            data: data
        })
        return response
    } catch (error: any) {
        handleError(error)
        return error.response
    }
})

export const accountSendVerification = createAsyncThunk('accountSendVerification', async (email: string) => {
    try {
        const response = await axios({
            method: 'post',
            url: baseUrl + '/api/Account/SendVerificationFP',
            params: {
                email: email
            }
        })
        return response
    } catch (error: any) {
        handleError(error)
        return error.response
    }
})

export const accountActivate = createAsyncThunk('accountActivate', async (data: AuthCompanyActivate) => {
    try {
        const response = await axios({
            method: 'get',
            url: baseUrl + '/api/Account/ConfirmEmail',
            params: data
        })
        return response
    } catch (error: any) {
        handleError(error)
        return error.response
    }
})