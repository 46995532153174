import { Card, CardContent, CardHeader, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useEffect, useState } from "react";
import moment from "moment";
import { getDashboardFielderNotes } from "../dashboard.api";
import CustomDatePicker from "../../../shared/customDatePicker/CustomDatePicker";
import { formatName } from "../../../shared/utils";

export default function DashboardNotes() {

    const dispatch = useDispatch<any>()

    const { dataNoteList } = useSelector((state: RootState) => state.dashboard)

    const [dateStart, setDateStart] = useState(moment().add(-7, 'days').format('YYYY-MM-DD'))
    const [dateEnd, setDateEnd] = useState(moment().format('YYYY-MM-DD'))

    useEffect(() => {
        dispatch(getDashboardFielderNotes({ filterStartDate: dateStart, filterEndData: dateEnd }))
    }, [dispatch, dateStart, dateEnd])

    return (
        <Card variant="outlined">
            <CardHeader
                title={
                    <Typography variant="h5">
                        Notes
                    </Typography>
                }
                action={
                    <CustomDatePicker dateStart={dateStart} dateEnd={dateEnd} setDateStart={setDateStart} setDateEnd={setDateEnd} />
                }
            />
            <CardContent sx={{ height: 335, overflow: "auto" }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>From</TableCell>
                            <TableCell>To</TableCell>
                            <TableCell>Notes</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataNoteList.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell>{item.date}</TableCell>
                                <TableCell>{formatName(item.from)}</TableCell>
                                <TableCell>{formatName(item.to)}</TableCell>
                                <TableCell>{item.notes}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    )
}