import { createSlice } from '@reduxjs/toolkit'
import { InitialProfileData, InterfaceProfileData } from './myAccount.interface'
import { changePassword, linkToUf, profileGet, profileUpdate, unLink } from './myAccount.api'

export interface MyAccountState {
    loading: boolean,
    dataProfile: InterfaceProfileData
}
const initialState: MyAccountState = {
    loading: false,
    dataProfile: InitialProfileData
}

export const myAccountSlice = createSlice({
    name: 'myAccount',
    initialState,
    reducers: {},
    extraReducers: (builder) => {

        builder.addCase(profileGet.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(profileGet.fulfilled, (state, action) => {
            state.loading = false
            if (action.payload.data.status) {
                state.dataProfile = action.payload.data.data
            }
        })
        builder.addCase(profileGet.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(profileUpdate.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(profileUpdate.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(profileUpdate.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(linkToUf.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(linkToUf.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(linkToUf.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(unLink.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(unLink.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(unLink.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(changePassword.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(changePassword.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(changePassword.rejected, (state, action) => {
            state.loading = false
        })

    },
})

export default myAccountSlice.reducer