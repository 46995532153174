import { Logout, Menu, Person } from "@mui/icons-material"
import { IconButton, List, ListItemButton, ListItemText, Popover, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { userInfo } from "../../shared/utils"
import icon from "../../assets/images/icon.png"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../store"
import CustomLoading from "../../shared/CustomLoading"
import { profileGet } from "../../pages/myaccount/myAccount.api"
import HeaderNotifications from "./HeaderNotifications"
import HeaderNotificationsProject from "./HeaderNotificationsProject"

export default function Header(props: { toggleExpand: any }) {
    const navigate = useNavigate()
    const dispatch = useDispatch<any>()

    const { dataProfile } = useSelector((state: RootState) => state.myAccount)

    const [popAccount, setPopAccount] = useState<HTMLButtonElement | null>(null)

    useEffect(() => {
        dispatch(profileGet(userInfo().token))
    }, [])

    const handleLogout =async () => {
        await localStorage.removeItem("fielderProData")
        await localStorage.removeItem("fielderProAccount")
        await localStorage.removeItem("fielderProUserData")
        await localStorage.removeItem("fielderProUserAccount")
        window.location.href = "/signin"
    }

    return (
        <header>
            <CustomLoading />
            <Popover
                open={Boolean(popAccount)}
                anchorEl={popAccount}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                onClose={() => setPopAccount(null)}
                onClick={() => setPopAccount(null)}
            >
                <List>
                    <ListItemButton onClick={() => navigate("/myaccount")}>
                        <ListItemText primary="My Account" />
                    </ListItemButton>
                    <ListItemButton onClick={handleLogout}>
                        <ListItemText primary="Logout" />
                        <Logout color="primary" />
                    </ListItemButton>
                </List>
            </Popover>
            <div className="brand">
                <IconButton onClick={props.toggleExpand}>
                    <Menu />
                </IconButton>
                <img src={icon} alt="icon" />
                <span>Fielder Pro</span>
            </div>
            <div className="profile">
                <div>
                    <Typography variant="caption">{dataProfile.email}</Typography>
                    <IconButton color="inherit" onClick={(e) => setPopAccount(e.currentTarget)}>
                        <Person />
                    </IconButton>
                    {window.location.hash === "#/fielding" ? <HeaderNotifications /> : <HeaderNotificationsProject />}
                </div>
            </div>
        </header>
    )
}
