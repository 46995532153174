import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import { Box, IconButton, MenuItem, TextField, Typography } from "@mui/material";

export default function Pagination(props: { skip: number, take: number, dataTotal: number, onChangePerPage: (value: number) => void, onBack: () => void, onNext: () => void }) {

    const pageTotal = Math.ceil(props.dataTotal / props.take)
    const pagePosition = (props.skip + props.take) / props.take
    const lastRows = props.dataTotal % props.take

    return (
        <Box>
            <Box display={"flex"} alignItems={"center"} justifyContent={"flex-end"}>
                <span style={{ marginRight: 10 }}>Rows per page :</span>
                <TextField
                    fullWidth={false}
                    size='small'
                    select
                    className='options'
                    value={props.take}
                    onChange={(e) => props.onChangePerPage(Number(e.target.value))}
                >
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                    <MenuItem value={150}>150</MenuItem>
                </TextField>
                <IconButton
                    disabled={props.skip === 0}
                    onClick={props.onBack}
                >
                    <ArrowLeft />
                </IconButton>
                Page{' '}
                {pagePosition}/{pageTotal}
                <IconButton
                    disabled={pagePosition === pageTotal}
                    onClick={props.onNext}
                >
                    <ArrowRight />
                </IconButton>
            </Box>
            <Typography marginTop={1} align="right">Showing {props.skip + 1} to {props.skip + (pageTotal === pagePosition ? lastRows : props.take)} of {props.dataTotal} entries</Typography>
        </Box>
    )
}