import {Cancel, Check} from "@mui/icons-material";
import {
    Autocomplete,
    Box, Button,
    CardContent, Grid,
    IconButton,
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow, TextField
} from "@mui/material";
import {useEffect, useState} from "react";
import axios from "axios";
import {userInfo} from "../../../shared/utils";
import {baseUrl} from "../../../state";
import notificationError from "../../../shared/notificationError";
import {useDispatch} from "react-redux";
import notificationSuccess from "../../../shared/notificationSuccess";
import {Dropzone} from "@dropzone-ui/react";
import {useNavigate} from "react-router-dom";
import Papa from "papaparse";
import {InitialProjectCreate} from "../project.interface";
import {projectCreate, projectMultiCreate} from "../project.api";

type HeaderOption = {
    display: string, value: number, order: number
}

type SelectedHeaderIndex = {
    requestName: null | number;
    projectName: null | number;
    siteName: null | number;
    customerJobNumber: null | number;
    expenseProjectID: null | number;
    poNumber: null | number;
    fielderCodeAndArea: null | number;
}

export default function ProjectFormImport(props: { onClose: any }) {

    const dispatch: any = useDispatch()
    const navigate = useNavigate()

    const [filesQueue, setFilesQueue] = useState<any>([])
    const [isUploading, setIsUploading] = useState(false)

    const [csvData, setCsvData] = useState<any>([])
    const [isParsed, setIsParsed] = useState<boolean>(false)
    const [headerOptions, setHeaderOptions] = useState<HeaderOption[]>([])

    const [selectedHeaderIndex, setSelectedHeaderIndex] = useState<SelectedHeaderIndex>({
        requestName: null,
        projectName: null,
        siteName: null,
        customerJobNumber: null,
        expenseProjectID: null,
        poNumber: null,
        fielderCodeAndArea: null,
    })

    useEffect(() => {
        if (!isUploading && filesQueue.length > 0) {
            setIsUploading(true)
            uploading()
        }
    }, [filesQueue])

    const onChangeImage = async (filesLocal: any) => {
        // convert to blob
        console.log(filesLocal)
        setIsParsed(false)
        if (filesLocal.length > 0) {
            Papa.parse(filesLocal[0].file, {
                complete: function (results: any) {
                    setCsvData(results.data)
                    setHeaderOptions(results.data[0].map((item: string, index: number) => ({
                        display: item,
                        value: index,
                        order: index
                    })))
                    setIsParsed(true)
                }
            })
            // setFilesQueue([
            //     ...filesQueue,
            //     ...Array.from(filesLocal).map((file: any) => {
            //         return {
            //             name: file.name,
            //             uploadProgress: 0,
            //             file: file.file
            //         }
            //     })
            // ])
        }
    }

    console.log(selectedHeaderIndex)


    const uploading = async () => {
        let filesLocal: any = filesQueue
        let filesToUpload: any = filesLocal.filter((file: any) => file.uploadProgress === 0)
        if (filesToUpload.length > 0) {
        }
        setIsUploading(false)
        notificationSuccess("Uploaded successfully").then(() => {
            navigate(`/project`)
        })
        props.onClose()
    }

    const handleSubmitProject =  () => {
        let _csvData = csvData;

        // filter if csv data only contain array with all data ""
        _csvData = _csvData.filter((item: any) => item.filter((i: any) => i !== "").length > 0)

        let _selectedHeaderIndex = selectedHeaderIndex;

        // convert csv data to array of object based on selected header index, don't forget to skip index of 0
        _csvData = _csvData.map((item: any, index: number) => {
            if (index === 0) return item;
            return {
                ...InitialProjectCreate,
                token: userInfo().token,
                companyId: userInfo().user.companyId,
                requestName: _selectedHeaderIndex.requestName !== null ? item[_selectedHeaderIndex.requestName] : "",
                projectName: _selectedHeaderIndex.projectName !== null ? item[_selectedHeaderIndex.projectName] : "",
                siteName: _selectedHeaderIndex.siteName !== null ? item[_selectedHeaderIndex.siteName] : "",
                customerJobNumber: _selectedHeaderIndex.customerJobNumber !== null ? item[_selectedHeaderIndex.customerJobNumber] : "",
                expenseProjectID: _selectedHeaderIndex.expenseProjectID !== null ? item[_selectedHeaderIndex.expenseProjectID] : "",
                poNumber: _selectedHeaderIndex.poNumber !== null ? item[_selectedHeaderIndex.poNumber] : "",
                fielderCodeAndArea: _selectedHeaderIndex.fielderCodeAndArea !== null ? item[_selectedHeaderIndex.fielderCodeAndArea] : "",
            }
        })

        // filter index 0
        _csvData = _csvData.filter((item: any, index: number) => index !== 0)

        dispatch(projectMultiCreate(_csvData)).unwrap().then(async (response: any) => {
            console.log(response);
            if (response.data.status) {
                notificationSuccess("Imported successfully").then(() => {
                    navigate(`/project`)
                })
            } else {
                notificationError(response)
            }
        })

    }

    return (
        <>
            {
                !isParsed ? (
                    <Box>
                        <Dropzone onChange={onChangeImage} header={false} accept="csv" disabled={isUploading}/>
                        {filesQueue.map((file: any) => (
                            <Box key={file.name}>
                                <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                                    {file.name}
                                    <IconButton>
                                        {file.uploadProgress < 100
                                            ? (
                                                <Cancel/>
                                            )
                                            : (
                                                <Check color="success"/>
                                            )
                                        }
                                    </IconButton>
                                </Box>
                                <LinearProgress variant="determinate" value={file.uploadProgress}/>
                            </Box>
                        ))}
                    </Box>
                ) : (

                    <Box>
                        <Grid container spacing={1} mb={3}>
                            <Grid item xs={12} md={6} display={"flex"}>
                                <Autocomplete
                                    sx={{width: '100%'}}
                                    options={headerOptions}
                                    getOptionLabel={(option) => option.display}
                                    onChange={(e, value) => setSelectedHeaderIndex({...selectedHeaderIndex, fielderCodeAndArea: value?.value ?? null})}
                                    renderInput={(params) => <TextField {...params} label="Fielder Code + Area"/>}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} display={"flex"}>
                                <Autocomplete
                                    sx={{width: '100%'}}
                                    options={headerOptions}
                                    getOptionLabel={(option) => option.display}
                                    onChange={(e, value) => setSelectedHeaderIndex({...selectedHeaderIndex, requestName: value?.value ?? null})}
                                    renderInput={(params) => <TextField {...params} label="Request Name"/>}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} display={"flex"}>
                                <Autocomplete
                                    sx={{width: '100%'}}
                                    options={headerOptions}
                                    getOptionLabel={(option) => option.display}
                                    onChange={(e, value) => setSelectedHeaderIndex({...selectedHeaderIndex, projectName: value?.value ?? null})}
                                    renderInput={(params) => <TextField {...params} label="Project"/>}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} display={"flex"}>
                                <Autocomplete
                                    sx={{width: '100%'}}
                                    options={headerOptions}
                                    getOptionLabel={(option) => option.display}
                                    onChange={(e, value) => setSelectedHeaderIndex({...selectedHeaderIndex, siteName: value?.value ?? null})}
                                    renderInput={(params) => <TextField {...params} label="Site Name"/>}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} display={"flex"}>
                                <Autocomplete
                                    sx={{width: '100%'}}
                                    options={headerOptions}
                                    getOptionLabel={(option) => option.display}
                                    onChange={(e, value) => setSelectedHeaderIndex({...selectedHeaderIndex, customerJobNumber: value?.value ?? null})}
                                    renderInput={(params) => <TextField {...params} label="Customer Job Number"/>}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} display={"flex"}>
                                <Autocomplete
                                    sx={{width: '100%'}}
                                    options={headerOptions}
                                    getOptionLabel={(option) => option.display}
                                    onChange={(e, value) => setSelectedHeaderIndex({...selectedHeaderIndex, expenseProjectID: value?.value ?? null})}
                                    renderInput={(params) => <TextField {...params} label="Expense Project ID"/>}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} display={"flex"}>
                                <Autocomplete
                                    sx={{width: '100%'}}
                                    options={headerOptions}
                                    getOptionLabel={(option) => option.display}
                                    onChange={(e, value) => setSelectedHeaderIndex({...selectedHeaderIndex, poNumber: value?.value ?? null})}
                                    renderInput={(params) => <TextField {...params} label="PO Number"/>}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} display={"flex"}>
                                <Button variant="contained" sx={{width: '200px'}} onClick={handleSubmitProject}>
                                    Import Project
                                </Button>
                            </Grid>
                        </Grid>
                        <CardContent sx={{height: 400, overflow: "auto"}}>

                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {
                                            csvData[0].map((item: string, index: number) => (
                                                <TableCell key={index}>{item}</TableCell>
                                            ))
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {/* skip index of 0*/}
                                    {
                                        csvData.map((item: any, index: number) => {
                                            if (index === 0) return null
                                            return (
                                                <TableRow key={index}>
                                                    {
                                                        item.map((cell: any, cellIndex: number) => (
                                                            <TableCell key={cellIndex}>{cell}</TableCell>
                                                        ))
                                                    }
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </CardContent>
                    </Box>
                )
            }
        </>

    )
}