import { ArrowLeft, ArrowRight, Check, Close, Description } from "@mui/icons-material";
import { Button, IconButton, MenuItem, TextField, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { useState } from "react";
import CustomModal from "../../../shared/customModal/CustomModal";
import CreditTopup from "./CreditTopup";
import CustomDatePicker from "../../../shared/customDatePicker/CustomDatePicker";

export default function Credit() {

    const [dateStart, setDateStart] = useState("")
    const [dateEnd, setDateEnd] = useState("")
    const [modalTopup, setModalTopup] = useState(false)

    return (
        <div className="credit">
            <CustomModal
                open={modalTopup}
                onClose={() => setModalTopup(false)}
                title="Payment"
                component={
                    <CreditTopup onClose={() => setModalTopup(false)} />
                }
            />
            <div className="menu-header">
                <div>
                    <Typography variant="body1">
                        Transaction
                    </Typography>
                </div>
                <div>
                    <CustomDatePicker setDateStart={setDateStart} dateStart={dateStart} setDateEnd={setDateEnd} dateEnd={dateEnd} />
                </div>
                <div style={{ marginLeft: "auto" }}>
                    <Button variant="contained" onClick={() => setModalTopup(true)}>
                        Payment
                    </Button>
                </div>
                <div>
                    <Typography variant="body1" align="right">
                        Credit
                    </Typography>
                    <Typography variant="h6">
                        $0
                    </Typography>
                </div>
            </div>
            <Container maxWidth="xl">
                <table className="custom-table">
                    <thead>
                        <tr>
                            <th>Transaction ID</th>
                            <th>Date</th>
                            <th>Amount</th>
                            <th>Payment Method</th>
                            <th>Payment Status</th>
                            <th>Invoice</th>
                        </tr>
                        <tr>
                            <th className="space" colSpan={6}></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>amet consequat consequat.</td>
                            <td>ea elit voluptate sunt.</td>
                            <td>labore.</td>
                            <td>Lorem anim</td>
                            <td>
                                <Button endIcon={<Close />} color="error">
                                    Denied
                                </Button>
                            </td>
                            <td>
                                <Button startIcon={<Description />}>
                                    Download Invoice
                                </Button>
                            </td>
                        </tr>
                        <tr>
                            <td>Anim mollit</td>
                            <td>Aliqua duis</td>
                            <td>Veniam sunt deserunt</td>
                            <td>Et eiusmod cillum</td>
                            <td>
                                <Button endIcon={<Check />} color="success">
                                    Success
                                </Button>
                            </td>
                            <td>
                                <Button startIcon={<Description />}>
                                    Download Invoice
                                </Button>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={6}>
                                <div className="foot">
                                    <span style={{ marginRight: 10 }}>
                                        Show Result:
                                    </span>
                                    <TextField fullWidth={false} size="small" select className="options" value={10}>
                                        <MenuItem value={10}>10</MenuItem>
                                        <MenuItem value={20}>20</MenuItem>
                                        <MenuItem value={30}>30</MenuItem>
                                    </TextField>
                                    <IconButton><ArrowLeft /></IconButton>
                                    Page 1/1
                                    <IconButton><ArrowRight /></IconButton>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Container>
        </div>
    )
}