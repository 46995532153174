import { Box, Button, Divider, IconButton, InputAdornment, TextField } from "@mui/material";
import { InitialAccountResetPassword, InterfaceAccountData, InterfaceAccountResetPassword } from "../account.interface";
import { useDispatch } from "react-redux";
import { resetPassword } from "../account.api";
import notificationSuccess from "../../../shared/notificationSuccess";
import notificationError from "../../../shared/notificationError";
import { FormEvent, useState } from "react";
import { userInfo } from "../../../shared/utils";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export default function AccountResetPassword(props: { data: InterfaceAccountData, onClose: any }) {

    const dispatch = useDispatch<any>()

    const [values, setValues] = useState<InterfaceAccountResetPassword>(InitialAccountResetPassword)
    const [showPasswordA, setShowPasswordA] = useState(false)
    const [showPasswordB, setShowPasswordB] = useState(false)
    const [showPasswordC, setShowPasswordC] = useState(false)

    const handleChange = (e: any) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
    }

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault()
        dispatch(resetPassword({
            ...values,
            userId: props.data.id,
            token: userInfo().token
        }))
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    notificationSuccess("")
                        .then(() => {
                            props.onClose()
                        })
                }
                else {
                    notificationError(response)
                }
            })
    }

    return (
        <Box>
            <form onSubmit={handleSubmit}>
                <TextField
                    type={showPasswordA ? "text" : "password"}
                    margin="dense"
                    label="New Password"
                    name="newPassword"
                    value={values.newPassword}
                    onChange={handleChange}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() => setShowPasswordA(!showPasswordA)}
                                >
                                    {showPasswordA ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    required
                />
                <TextField
                    type={showPasswordB ? "text" : "password"}
                    margin="dense"
                    label="Confirm New Password"
                    name="newPasswordConfirm"
                    value={values.newPasswordConfirm}
                    onChange={handleChange}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() => setShowPasswordB(!showPasswordB)}
                                >
                                    {showPasswordB ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    required
                />
                <br />
                <br />
                <Divider />
                <br />
                <TextField
                    type={showPasswordC ? "text" : "password"}
                    margin="dense"
                    label="Your Password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() => setShowPasswordC(!showPasswordC)}
                                >
                                    {showPasswordC ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    required
                />
                <br />
                <br />
                <Button type="submit" fullWidth variant="contained">
                    Submit
                </Button>
            </form>
        </Box>
    )
}