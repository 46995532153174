import {
    Accordion,
    AccordionDetails,
    AccordionSummary, Alert,
    Box,
    Button, Grid, IconButton, InputAdornment, LinearProgress,
    TextField,
    Typography,
} from "@mui/material"
import {InterfaceFieldingDetail} from "../fielding.interface"
import {useEffect, useState} from "react"
import {useDispatch} from "react-redux"
import {userInfo} from "../../../shared/utils"
import {fieldingSubmitProgress} from "../fielding.api"
import Swal from "sweetalert2"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {AddCircle, RemoveCircle} from "@mui/icons-material";

interface InterfaceValues {
    customTypeId: string
    customTypeString: string | null,
    progressPercentage: number | undefined
    actualFielding: number
    requestFielding: number
    finalActualFielding: number
    frontyard: number | null
    backyard: number | null
    noAccessPole: number | null
    underground: number | null
    structures: number | null
    noAccessStructures: number | null
    structuresNotInField: number | null
}

export default function FieldingProgress(props: {
    details: InterfaceFieldingDetail;
    isComplete: boolean;
    onUpdateData: () => void;
    onClose: () => void
}) {

    const dispatch = useDispatch<any>()
    // const isMobile = useMediaQuery("(max-width:768px)")

    const [values, setValues] = useState<InterfaceValues[]>([])
    const [completeWarningCount, setCompleteWarningCount] = useState(0)
    // const [indexToEdit, setIndexToEdit] = useState<undefined | number>()
    // const [tempEditValue, setTempEditValue] = useState<number>(0)

    const [expanded, setExpanded] = useState<string[]>([]);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? [...expanded, panel] : expanded.filter((item) => item !== panel));
        };

    useEffect(() => {
        if (props.details) {
            initValues()
        }
    }, [props.details])

    const initValues = () => {
        const fieldingIdList = props.details.fieldingRequestMarkers.map((marker) => ({
            customTypeId: marker.customTypeId,
            customTypeString: marker.customTypeString
        }))
        const fieldingIdListUnique = fieldingIdList.filter((value, index, self) => self.map(d => d.customTypeId).indexOf(value.customTypeId) === index)
        let mapValue: InterfaceValues[] = fieldingIdListUnique.map((data) => {
            const actual = props.details.fieldingRequestMarkers.filter((marker) => marker.customTypeId === data.customTypeId).reduce((a, b) => a + parseInt(b.actualFielding || "0"), 0)
            const request = props.details.fieldingRequestMarkers.filter((marker) => marker.customTypeId === data.customTypeId).reduce((a, b) => a + parseInt(b.requestedFielding || "0"), 0)
            const slideProgress = props.details.fieldingRequestMarkersProgress.find(progress => progress.customTypeId === data.customTypeId)?.sliderProgressPercentage || 0
            const totalActual = props.details.fieldingRequestMarkersProgress.find(progress => progress.customTypeId === data.customTypeId)?.fieldingRequestActualFielding || '0'

            const frontyard = props.details.fieldingRequestMarkersProgress.find(progress => progress.customTypeId === data.customTypeId)?.frontyard || 0
            const backyard = props.details.fieldingRequestMarkersProgress.find(progress => progress.customTypeId === data.customTypeId)?.backyard || 0
            const noAccessPole = props.details.fieldingRequestMarkersProgress.find(progress => progress.customTypeId === data.customTypeId)?.noAccessPole || 0
            const underground = props.details.fieldingRequestMarkersProgress.find(progress => progress.customTypeId === data.customTypeId)?.underground || 0
            const structures = props.details.fieldingRequestMarkersProgress.find(progress => progress.customTypeId === data.customTypeId)?.structures || 0
            const noAccessStructures = props.details.fieldingRequestMarkersProgress.find(progress => progress.customTypeId === data.customTypeId)?.noAccessStructures || 0
            const structuresNotInField = props.details.fieldingRequestMarkersProgress.find(progress => progress.customTypeId === data.customTypeId)?.structuresNotInField || 0

            const calculationProgress = Math.round((actual / request) * 100)

            return {
                customTypeId: data.customTypeId,
                customTypeString: data.customTypeString,
                // progressPercentage: slideProgress || (calculationProgress > 100 ? 100 : calculationProgress),
                progressPercentage: slideProgress || calculationProgress,
                actualFielding: actual,
                requestFielding: request,
                finalActualFielding: parseInt(totalActual) > actual ? parseInt(totalActual) : actual,

                frontyard: frontyard,
                backyard: backyard,
                noAccessPole: noAccessPole,
                underground: underground,

                structures: structures,
                noAccessStructures: noAccessStructures,
                structuresNotInField: structuresNotInField
            }
        })
        setValues(mapValue)
    }

    const submitComplete = async (isForce?: boolean) => {
        await dispatch(fieldingSubmitProgress(values.map(value => ({
            token: userInfo().token,
            customTypeId: value.customTypeId,
            progressPercentage: isForce ? 100 : Math.round(value.progressPercentage ?? 0) || 0,
            sliderProgressPercentage: Math.round(value.progressPercentage ?? 0) || 0,
            fieldingRequestId: props.details.id,
            // fieldingRequestActualFielding: value.finalActualFielding > value.actualFielding ? String(value.finalActualFielding) : String(value.actualFielding),
            fieldingRequestActualFielding: ((value.frontyard ?? 0) + (value.backyard ?? 0) + (value.noAccessPole ?? 0) + (value.underground ?? 0) + (value.structures ?? 0) + (value.noAccessStructures ?? 0) + (value.structuresNotInField ?? 0))?.toString(),
            frontyard: value.frontyard ?? 0,
            backyard: value.backyard ?? 0,
            noAccessPole: value.noAccessPole ?? 0,
            underground: value.underground ?? 0,
            structures: value.structures ?? 0,
            noAccessStructures: value.noAccessStructures ?? 0,
            structuresNotInField: value.structuresNotInField ?? 0
        }))))
            .unwrap()
            .then((res: any) => {
                if (res.status === 200) {
                    Swal.fire("Success!", "Progress updated!", "success").then(() => props.onUpdateData())
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Failed!",
                        text: "Failed update Progress"
                    })
                }
            })

        // await dispatch(fieldingSubmitProgress(values.map(value => ({
        //     token: userInfo().token,
        //     customTypeId: value.customTypeId,
        //     progressPercentage: isForce ? 100 : value.progressPercentage || 0,
        //     sliderProgressPercentage: value.progressPercentage || 0,
        //     fieldingRequestId: props.details.id,
        //     fieldingRequestActualFielding: value.finalActualFielding > value.actualFielding ? String(value.finalActualFielding) : String(value.actualFielding),
        //     frontyard: value.frontyard,
        //     backyard: value.backyard,
        //     noAccessPole: value.noAccessPole,
        //     underground: value.underground
        // }))))
        //     .unwrap()
        //     .then((res: any) => {
        //         if (res.status === 200) {
        //             Swal.fire("Success!", "Progress updated!", "success").then(() => props.onUpdateData())
        //         } else {
        //             Swal.fire({
        //                 icon: "error",
        //                 title: "Failed!",
        //                 text: "Failed update Progress"
        //             })
        //         }
        //     })
    }

    const handlerSubmit = async () => {
        if (props.isComplete && values.some((v) => v.progressPercentage && v.progressPercentage < 100) && completeWarningCount === 0) {
            Swal.fire({
                icon: "error",
                title: "Failed!",
                text: "Fielding progress must be 100% in order to be completed"
            })
            setCompleteWarningCount(1)
        } else if (completeWarningCount === 1) {
            Swal.fire({
                icon: "question",
                title: "Are you sure to complete this request?",
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: "Yes",
                cancelButtonText: "No"
            })
                .then((response) => {
                    if (response.isConfirmed) {
                        submitComplete(true)
                    }
                })
        } else {
            submitComplete(false)
        }
    }

    // const isContainedLess80 = (customTypeId: string) => {
    //     return props.details.fieldingRequestMarkers.filter(marker => marker.customTypeId === customTypeId).some(marker => {
    //         const actual = typeof marker.actualFielding === 'string' ? parseInt(marker.actualFielding || '0') : 0
    //         const requested = typeof marker.requestedFielding === 'string' ? parseInt(marker.requestedFielding || '0') : 0
    //         return actual < 0.8 * requested
    //     })
    // }

    const average = values.reduce((acc, curr) => acc + (curr.progressPercentage || 0), 0) / values.length

    // values[i].requestFielding
    // const average = values.reduce((acc, curr) => acc + (curr.frontyard + curr.backyard + curr.noAccessPole + curr.underground + curr.structures + curr.noAccessStructures + curr.structuresNotInField), 0) / values.reduce((acc, curr) => acc + curr.requestFielding, 0) * 100


    console.log(values);

    return (
        <div
            style={{
                display: "flex",
                // justifyContent: "center",
                // alignItems: "center",
                flexDirection: "column",
                position: "relative",
            }}
        >
            <Box
                sx={{
                    // position: "absolute",
                    // right: "22px",
                    // top: 0,
                    display: "flex",
                    width: "100%",
                    borderBottom: "1px solid rgba(224, 224, 224, 1)",
                    paddingBottom: "20px",
                    justifyContent: "end"
                }}
            >
                <Typography sx={{fontSize: 18, mr: 1}}>{`Average`}</Typography>
                <Typography fontWeight={"bold"} sx={{fontSize: 18}}>{`${average.toFixed(2)}`}</Typography>
                <Typography fontWeight={"bold"} sx={{fontSize: 18}}>%</Typography>
            </Box>
            {values?.map((value, index) => (
                <Accordion disableGutters key={value.customTypeId} sx={{
                    marginY: "10px", borderRadius: "10px", ".MuiCollapse-root": {
                        minHeight: "100px !important"
                    }
                }} expanded={
                    expanded.includes(`panel${index + 1}`)
                } onChange={handleChange(`panel${index + 1}`)}>
                    <AccordionSummary
                        expandIcon={<div className={'blinking-circle'}><ExpandMoreIcon/></div>}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        sx={{borderBottom: "1px solid rgba(224, 224, 224, 1)", backgroundColor: '#87CEEB'}}
                    >
                        <Typography fontWeight={"bold"}
                                    sx={{
                                        fontSize: 18,
                                        mr: 1,
                                        flexGrow: 1
                                    }}>{value.customTypeString}</Typography><Typography
                        sx={{fontSize: 14, mr: 1, display: "flex", alignItems: "center"}}><span
                        style={{marginRight: "10px"}}>{`Total Request`}</span><b>{value.requestFielding}</b></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {
                            expanded.includes(`panel${index + 1}`) &&
                            <Grid>
                                <Grid display={'flex'} mb={2}>
                                    <Typography
                                        sx={{fontSize: 14, mr: 1, flexGrow: 1}}>Frontyard</Typography>
                                    <TextField
                                        type="number"
                                        value={value.frontyard}
                                        onChange={(e) => {
                                            // if NaN then set to 0
                                            const val = parseInt(e.target.value)
                                            const percentage = (((isNaN(val) ? 0 : val) + (value.backyard ?? 0) + (value.noAccessPole ?? 0) + (value.underground ?? 0) + (value.structures ?? 0) + (value.noAccessStructures ?? 0) + (value.structuresNotInField ?? 0)) / value.requestFielding * 100).toFixed(2)

                                            if (isNaN(val)) {
                                                setValues(values.map((v, i) => i === index ? ({
                                                    ...v,
                                                    frontyard: null,
                                                    progressPercentage: parseFloat(percentage)
                                                }) : v))
                                            } else {
                                                setValues(values.map((v, i) => i === index ? ({
                                                        ...v,
                                                        frontyard: val,
                                                        progressPercentage: parseFloat(percentage)
                                                    }) : v)
                                                )
                                            }
                                        }}
                                        sx={{
                                            width: 150,
                                            ".MuiInputBase-root": {padding: 0},
                                            ".MuiInputBase-input::-webkit-inner-spin-button": {'-webkit-appearance': 'none'}
                                        }}
                                        inputProps={{min: 0, style: {textAlign: 'right',}}}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position='start'>
                                                    <IconButton aria-label="min" onClick={() => {
                                                        // if more than 0 then decrease
                                                        if (value.frontyard !== null && value.frontyard > 0) {
                                                            setValues(values.map((v, i) => i === index ? ({
                                                                ...v,
                                                                frontyard: v.frontyard !== null ? v.frontyard - 1 : 0,
                                                                progressPercentage: parseFloat((((v.frontyard !== null ? v.frontyard - 1 : 0) + (v.backyard ?? 0) + (v.noAccessPole ?? 0) + (v.underground ?? 0) + (v.structures ?? 0) + (v.noAccessStructures ?? 0) + (v.structuresNotInField ?? 0)) / v.requestFielding * 100).toFixed(2))
                                                            }) : v))
                                                        }
                                                    }}>
                                                        <RemoveCircle sx={{color: 'lightgrey', fontSize: "24px"}}/>
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    <IconButton aria-label="add" onClick={() => {
                                                        setValues(values.map((v, i) => i === index ? ({
                                                            ...v,
                                                            frontyard: v.frontyard !== null ? v.frontyard + 1 : 0,
                                                            progressPercentage: parseFloat((((v.frontyard !== null ? v.frontyard + 1 : 0) + (v.backyard ?? 0) + (v.noAccessPole ?? 0) + (v.underground ?? 0) + (v.structures ?? 0) + (v.noAccessStructures ?? 0) + (v.structuresNotInField ?? 0)) / v.requestFielding * 100).toFixed(2))
                                                        }) : v))
                                                    }}>
                                                        <AddCircle sx={{color: 'lightgrey', fontSize: "24px"}}/>
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                                <Grid display={'flex'} mb={2}>
                                    <Typography
                                        sx={{fontSize: 14, mr: 1, flexGrow: 1}}>Backyard</Typography>
                                    <TextField
                                        type="number"
                                        value={value.backyard}
                                        onChange={(e) => {
                                            // if NaN then set to 0
                                            const val = parseInt(e.target.value)
                                            const percentage = (((value.frontyard ?? 0) + (isNaN(val) ? 0 : val) + (value.noAccessPole ?? 0) + (value.underground ?? 0) + (value.structures ?? 0) + (value.noAccessStructures ?? 0) + (value.structuresNotInField ?? 0)) / value.requestFielding * 100).toFixed(2)
                                            if (isNaN(val)) {
                                                setValues(values.map((v, i) => i === index ? ({
                                                    ...v,
                                                    backyard: null,
                                                    progressPercentage: parseFloat(percentage)
                                                }) : v))
                                            } else {
                                                setValues(values.map((v, i) => i === index ? ({
                                                        ...v,
                                                        backyard: val,
                                                        progressPercentage: parseFloat(percentage)
                                                    }) : v)
                                                )
                                            }
                                        }}
                                        sx={{
                                            width: 150,
                                            ".MuiInputBase-root": {padding: 0},
                                            ".MuiInputBase-input::-webkit-inner-spin-button": {'-webkit-appearance': 'none'}
                                        }}
                                        inputProps={{style: {textAlign: 'right',}}}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position='start'>
                                                    <IconButton aria-label="min" onClick={() => {
                                                        // if more than 0 then decrease
                                                        if (value.backyard !== null && value.backyard > 0) {
                                                            setValues(values.map((v, i) => i === index ? ({
                                                                ...v,
                                                                backyard: v.backyard !== null ? v.backyard - 1 : 0,
                                                                progressPercentage: parseFloat((((v.frontyard ?? 0) + (v.backyard !== null ? v.backyard - 1 : 0) + (v.noAccessPole ?? 0) + (v.underground ?? 0) + (v.structures ?? 0) + (v.noAccessStructures ?? 0) + (v.structuresNotInField ?? 0)) / v.requestFielding * 100).toFixed(2))
                                                            }) : v))
                                                        }
                                                    }}>
                                                        <RemoveCircle sx={{color: 'lightgrey', fontSize: "24px"}}/>
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    <IconButton aria-label="add" onClick={() => {
                                                        setValues(values.map((v, i) => i === index ? ({
                                                            ...v,
                                                            backyard: v.backyard !== null ? v.backyard + 1 : 0,
                                                            progressPercentage: parseFloat((((v.frontyard ?? 0) + (v.backyard !== null ? v.backyard + 1 : 0) + (v.noAccessPole ?? 0) + (v.underground ?? 0) + (v.structures ?? 0) + (v.noAccessStructures ?? 0) + (v.structuresNotInField ?? 0)) / v.requestFielding * 100).toFixed(2))
                                                        }) : v))
                                                    }}>
                                                        <AddCircle sx={{color: 'lightgrey', fontSize: "24px"}}/>
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                                <Grid display={'flex'} mb={2}>
                                    <Typography
                                        sx={{fontSize: 14, mr: 1, flexGrow: 1}}>No Access Pole</Typography>
                                    <TextField
                                        type="number"
                                        value={value.noAccessPole}
                                        onChange={(e) => {
                                            // if NaN then set to 0
                                            const val = parseInt(e.target.value)
                                            const percentage = (((value.frontyard ?? 0) + (value.backyard ?? 0) + (isNaN(val) ? 0 : val) + (value.underground ?? 0) + (value.structures ?? 0) + (value.noAccessStructures ?? 0) + (value.structuresNotInField ?? 0)) / value.requestFielding * 100).toFixed(2)
                                            if (isNaN(val)) {
                                                setValues(values.map((v, i) => i === index ? ({
                                                    ...v,
                                                    noAccessPole: null,
                                                    progressPercentage: parseFloat(percentage)
                                                }) : v))
                                            } else {
                                                setValues(values.map((v, i) => i === index ? ({
                                                        ...v,
                                                        noAccessPole: val,
                                                        progressPercentage: parseFloat(percentage)
                                                    }) : v)
                                                )
                                            }
                                        }}
                                        sx={{
                                            width: 150,
                                            ".MuiInputBase-root": {padding: 0},
                                            ".MuiInputBase-input::-webkit-inner-spin-button": {'-webkit-appearance': 'none'}
                                        }}
                                        inputProps={{min: 0, style: {textAlign: 'right',}}}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position='start'>
                                                    <IconButton aria-label="min" onClick={() => {
                                                        // if more than 0 then decrease
                                                        if (value.noAccessPole !== null && value.noAccessPole > 0) {
                                                            setValues(values.map((v, i) => i === index ? ({
                                                                ...v,
                                                                noAccessPole: v.noAccessPole !== null ? v.noAccessPole - 1 : 0,
                                                                progressPercentage: parseFloat((((v.frontyard ?? 0) + (v.backyard ?? 0) + (v.noAccessPole !== null ? v.noAccessPole - 1 : 0) + (v.underground ?? 0) + (v.structures ?? 0) + (v.noAccessStructures ?? 0) + (v.structuresNotInField ?? 0)) / v.requestFielding * 100).toFixed(2))
                                                            }) : v))
                                                        }
                                                    }}>
                                                        <RemoveCircle sx={{color: 'lightgrey', fontSize: "24px"}}/>
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    <IconButton aria-label="add" onClick={() => {
                                                        setValues(values.map((v, i) => i === index ? ({
                                                            ...v,
                                                            noAccessPole: v.noAccessPole !== null ? v.noAccessPole + 1 : 0,
                                                            progressPercentage: parseFloat((((v.frontyard ?? 0) + (v.backyard ?? 0) + (v.noAccessPole !== null ? v.noAccessPole + 1 : 0) + (v.underground ?? 0) + (v.structures ?? 0) + (v.noAccessStructures ?? 0) + (v.structuresNotInField ?? 0)) / v.requestFielding * 100).toFixed(2))
                                                        }) : v))
                                                    }}>
                                                        <AddCircle sx={{color: 'lightgrey', fontSize: "24px"}}/>
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                                <Grid display={'flex'} mb={2}>
                                    <Typography
                                        sx={{fontSize: 14, mr: 1, flexGrow: 1}}>Underground</Typography>
                                    <TextField
                                        type="number"
                                        value={value.underground}
                                        onChange={(e) => {
                                            // if NaN then set to 0
                                            const val = parseInt(e.target.value)
                                            const percentage = (((value.frontyard ?? 0) + (value.backyard ?? 0) + (value.noAccessPole ?? 0) + (isNaN(val) ? 0 : val) + (value.structures ?? 0) + (value.noAccessStructures ?? 0) + (value.structuresNotInField ?? 0)) / value.requestFielding * 100).toFixed(2)
                                            if (isNaN(val)) {
                                                setValues(values.map((v, i) => i === index ? ({
                                                    ...v,
                                                    underground: null,
                                                    progressPercentage: parseFloat(percentage)
                                                }) : v))
                                            } else {
                                                setValues(values.map((v, i) => i === index ? ({
                                                        ...v,
                                                        underground: val,
                                                        progressPercentage: parseFloat(percentage)
                                                    }) : v)
                                                )
                                            }
                                        }}
                                        sx={{
                                            width: 150,
                                            ".MuiInputBase-root": {padding: 0},
                                            ".MuiInputBase-input::-webkit-inner-spin-button": {'-webkit-appearance': 'none'}
                                        }}
                                        inputProps={{min: 0, style: {textAlign: 'right',}}}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position='start'>
                                                    <IconButton aria-label="min" onClick={() => {
                                                        // if more than 0 then decrease
                                                        if (value.underground !== null && value.underground > 0) {
                                                            setValues(values.map((v, i) => i === index ? ({
                                                                ...v,
                                                                underground: v.underground !== null ? v.underground - 1 : 0,
                                                                progressPercentage: parseFloat((((v.frontyard ?? 0) + (v.backyard ?? 0) + (v.noAccessPole ?? 0) + (v.underground !== null ? v.underground - 1 : 0) + (v.structures ?? 0) + (v.noAccessStructures ?? 0) + (v.structuresNotInField ?? 0)) / v.requestFielding * 100).toFixed(2))
                                                            }) : v))
                                                        }
                                                    }}>
                                                        <RemoveCircle sx={{color: 'lightgrey', fontSize: "24px"}}/>
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    <IconButton aria-label="add" onClick={() => {
                                                        setValues(values.map((v, i) => i === index ? ({
                                                            ...v,
                                                            underground: v.underground !== null ? v.underground + 1 : 0,
                                                            progressPercentage: parseFloat((((v.frontyard ?? 0) + (v.backyard ?? 0) + (v.noAccessPole ?? 0) + (v.underground !== null ? v.underground + 1 : 0) + (v.structures ?? 0) + (v.noAccessStructures ?? 0) + (v.structuresNotInField ?? 0)) / v.requestFielding * 100).toFixed(2))
                                                        }) : v))
                                                    }}>
                                                        <AddCircle sx={{color: 'lightgrey', fontSize: "24px"}}/>
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                                <Grid display={'flex'} mb={2}>
                                    <Typography
                                        sx={{fontSize: 14, mr: 1, flexGrow: 1}}>Structures</Typography>
                                    <TextField
                                        type="number"
                                        value={value.structures}
                                        onChange={(e) => {
                                            // if NaN then set to 0
                                            const val = parseInt(e.target.value)
                                            const percentage = (((value.frontyard ?? 0) + (value.backyard ?? 0) + (value.noAccessPole ?? 0) + (value.underground ?? 0) + (isNaN(val) ? 0 : val) + (value.noAccessStructures ?? 0) + (value.structuresNotInField ?? 0)) / value.requestFielding * 100).toFixed(2)
                                            if (isNaN(val)) {
                                                setValues(values.map((v, i) => i === index ? ({
                                                    ...v,
                                                    structures: null,
                                                    progressPercentage: parseFloat(percentage)
                                                }) : v))
                                            } else {
                                                setValues(values.map((v, i) => i === index ? ({
                                                        ...v,
                                                    structures: val,
                                                        progressPercentage: parseFloat(percentage)
                                                    }) : v)
                                                )
                                            }
                                        }}
                                        sx={{
                                            width: 150,
                                            ".MuiInputBase-root": {padding: 0},
                                            ".MuiInputBase-input::-webkit-inner-spin-button": {'-webkit-appearance': 'none'}
                                        }}
                                        inputProps={{min: 0, style: {textAlign: 'right',}}}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position='start'>
                                                    <IconButton aria-label="min" onClick={() => {
                                                        // if more than 0 then decrease
                                                        if (value.structures !== null && value.structures > 0) {
                                                            setValues(values.map((v, i) => i === index ? ({
                                                                ...v,
                                                                structures: v.structures !== null ? v.structures - 1 : 0,
                                                                progressPercentage: parseFloat((((v.frontyard ?? 0) + (v.backyard ?? 0) + (v.noAccessPole ?? 0) + (v.underground ?? 0) + (v.structures !== null ? v.structures - 1 : 0) + (v.noAccessStructures ?? 0) + (v.structuresNotInField ?? 0)) / v.requestFielding * 100).toFixed(2))
                                                            }) : v))
                                                        }
                                                    }}>
                                                        <RemoveCircle sx={{color: 'lightgrey', fontSize: "24px"}}/>
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    <IconButton aria-label="add" onClick={() => {
                                                        setValues(values.map((v, i) => i === index ? ({
                                                            ...v,
                                                            structures: v.structures !== null ? v.structures + 1 : 0,
                                                            progressPercentage: parseFloat((((v.frontyard ?? 0) + (v.backyard ?? 0) + (v.noAccessPole ?? 0) + (v.underground ?? 0) + (v.structures !== null ? v.structures + 1 : 0) + (v.noAccessStructures ?? 0) + (v.structuresNotInField ?? 0)) / v.requestFielding * 100).toFixed(2))
                                                        }) : v))
                                                    }}>
                                                        <AddCircle sx={{color: 'lightgrey', fontSize: "24px"}}/>
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                                <Grid display={'flex'} mb={2}>
                                    <Typography
                                        sx={{fontSize: 14, mr: 1, flexGrow: 1}}>No Access Structures</Typography>
                                    <TextField
                                        type="number"
                                        value={value.noAccessStructures}
                                        onChange={(e) => {
                                            // if NaN then set to 0
                                            const val = parseInt(e.target.value)
                                            // no access structure
                                            const percentage = (((value.frontyard ?? 0) + (value.backyard ?? 0) + (value.noAccessPole ?? 0) + (value.underground ?? 0) + (value.structures ?? 0) + (isNaN(val) ? 0 : val) + (value.structuresNotInField ?? 0)) / value.requestFielding * 100).toFixed(2)
                                            if (isNaN(val)) {
                                                setValues(values.map((v, i) => i === index ? ({
                                                    ...v,
                                                    noAccessStructures: null,
                                                    progressPercentage: parseFloat(percentage)
                                                }) : v))
                                            } else {
                                                setValues(values.map((v, i) => i === index ? ({
                                                        ...v,
                                                        noAccessStructures: val,
                                                        progressPercentage: parseFloat(percentage)
                                                    }) : v)
                                                )
                                            }
                                        }}
                                        sx={{
                                            width: 150,
                                            ".MuiInputBase-root": {padding: 0},
                                            ".MuiInputBase-input::-webkit-inner-spin-button": {'-webkit-appearance': 'none'}
                                        }}
                                        inputProps={{min: 0, style: {textAlign: 'right',}}}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position='start'>
                                                    <IconButton aria-label="min" onClick={() => {
                                                        // if more than 0 then decrease
                                                        if (value.noAccessStructures !== null && value.noAccessStructures > 0) {
                                                            setValues(values.map((v, i) => i === index ? ({
                                                                ...v,
                                                                noAccessStructures: v.noAccessStructures !== null ? v.noAccessStructures - 1 : 0,
                                                                progressPercentage: parseFloat((((v.frontyard ?? 0) + (v.backyard ?? 0) + (v.noAccessPole ?? 0) + (v.underground ?? 0) + (v.structures ?? 0) + (v.noAccessStructures !== null ? v.noAccessStructures - 1 : 0) + (v.structuresNotInField ?? 0)) / v.requestFielding * 100).toFixed(2))
                                                            }) : v))
                                                        }
                                                    }}>
                                                        <RemoveCircle sx={{color: 'lightgrey', fontSize: "24px"}}/>
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    <IconButton aria-label="add" onClick={() => {
                                                        setValues(values.map((v, i) => i === index ? ({
                                                            ...v,
                                                            noAccessStructures: v.noAccessStructures !== null ? v.noAccessStructures + 1 : 0,
                                                            progressPercentage: parseFloat((((v.frontyard ?? 0) + (v.backyard ?? 0) + (v.noAccessPole ?? 0) + (v.underground ?? 0) + (v.structures ?? 0) + (v.noAccessStructures !== null ? v.noAccessStructures + 1 : 0) + (v.structuresNotInField ?? 0)) / v.requestFielding * 100).toFixed(2))
                                                        }) : v))
                                                    }}>
                                                        <AddCircle sx={{color: 'lightgrey', fontSize: "24px"}}/>
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                                <Grid display={'flex'} mb={2}>
                                    <Typography
                                        sx={{fontSize: 14, mr: 1, flexGrow: 1}}>Structure Not In Field</Typography>
                                    <TextField
                                        type="number"
                                        value={value.structuresNotInField}
                                        onChange={(e) => {
                                            // if NaN then set to 0
                                            const val = parseInt(e.target.value)
                                            // structureNotInField
                                            const percentage = (((value.frontyard ?? 0) + (value.backyard ?? 0) + (value.noAccessPole ?? 0) + (value.underground ?? 0) + (value.structures ?? 0) + (value.noAccessStructures ?? 0) + (isNaN(val) ? 0 : val)) / value.requestFielding * 100).toFixed(2)
                                            if (isNaN(val)) {
                                                setValues(values.map((v, i) => i === index ? ({
                                                    ...v,
                                                    structuresNotInField: null,
                                                    progressPercentage: parseFloat(percentage)
                                                }) : v))
                                            } else {
                                                setValues(values.map((v, i) => i === index ? ({
                                                        ...v,
                                                        structuresNotInField: val,
                                                        progressPercentage: parseFloat(percentage)
                                                    }) : v)
                                                )
                                            }
                                        }}
                                        sx={{
                                            width: 150,
                                            ".MuiInputBase-root": {padding: 0},
                                            ".MuiInputBase-input::-webkit-inner-spin-button": {'-webkit-appearance': 'none'}
                                        }}
                                        inputProps={{min: 0, style: {textAlign: 'right',}}}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position='start'>
                                                    <IconButton aria-label="min" onClick={() => {
                                                        // if more than 0 then decrease
                                                        if (value.structuresNotInField !== null && value.structuresNotInField > 0) {
                                                            setValues(values.map((v, i) => i === index ? ({
                                                                ...v,
                                                                structuresNotInField: v.structuresNotInField !== null ? v.structuresNotInField - 1 : 0,
                                                                progressPercentage: parseFloat((((v.frontyard ?? 0) + (v.backyard ?? 0) + (v.noAccessPole ?? 0) + (v.underground ?? 0) + (v.structures ?? 0) + (v.noAccessStructures ?? 0) + (v.structuresNotInField !== null ? v.structuresNotInField - 1 : 0)) / v.requestFielding * 100).toFixed(2))
                                                            }) : v))
                                                        }
                                                    }}>
                                                        <RemoveCircle sx={{color: 'lightgrey', fontSize: "24px"}}/>
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    <IconButton aria-label="add" onClick={() => {
                                                        setValues(values.map((v, i) => i === index ? ({
                                                            ...v,
                                                            structuresNotInField: v.structuresNotInField !== null ? v.structuresNotInField + 1 : 0,
                                                            progressPercentage: parseFloat((((v.frontyard ?? 0) + (v.backyard ?? 0) + (v.noAccessPole ?? 0) + (v.underground ?? 0) + (v.structures ?? 0) + (v.noAccessStructures ?? 0) + (v.structuresNotInField !== null ? v.structuresNotInField + 1 : 0)) / v.requestFielding * 100).toFixed(2))
                                                        }) : v))
                                                    }}>
                                                        <AddCircle sx={{color: 'lightgrey', fontSize: "24px"}}/>
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        }
                        <Grid sx={{
                            borderBottom: "1px solid rgba(224, 224, 224, 1)",
                            marginY: expanded.includes(`panel${index + 1}`) ? 2 : "2px"
                        }}></Grid>
                        <Grid sx={{visibility: "visible"}}>
                            <Grid sx={{display: "flex", marginBottom: 1}}>
                                <Typography fontWeight={"bold"}
                                            sx={{fontSize: 14, mr: 1}}>{`Progress Review`}</Typography><Typography
                                sx={{fontSize: 12}}>{`(Automated)`}</Typography>
                            </Grid>
                            <Grid sx={{display: "flex", marginBottom: 1}}>
                                <Typography sx={{fontSize: 12, mr: 1}}>{`Total Actual: `}</Typography> <Typography
                                fontWeight={"bold"}
                                sx={{fontSize: 14, mr: 1, flexGrow: 1}}>{
                                (value.frontyard ?? 0) + (value.backyard ?? 0) + (value.noAccessPole ?? 0) + (value.underground ?? 0) + (value.structures ?? 0) + (value.noAccessStructures ?? 0) + (value.structuresNotInField ?? 0)
                            }</Typography><Typography
                                sx={{fontSize: 14}} fontWeight={"bold"}>{
                                // percentage of total actual / request
                                `${(((value.frontyard ?? 0) + (value.backyard ?? 0) + (value.noAccessPole ?? 0) + (value.underground ?? 0) + (value.structures ?? 0) + (value.noAccessStructures ?? 0) + (value.structuresNotInField ?? 0)) / value.requestFielding * 100).toFixed(2)}%`
                            }</Typography>
                            </Grid>
                            <Grid>
                                <LinearProgress color="success" variant="determinate"
                                                value={(value.frontyard ?? 0) + (value.backyard ?? 0) + (value.noAccessPole ?? 0) + (value.underground ?? 0) + (value.structures ?? 0) + (value.noAccessStructures ?? 0) + (value.structuresNotInField ?? 0) > value.requestFielding ? 100 : (((value.frontyard ?? 0) + (value.backyard ?? 0) + (value.noAccessPole ?? 0) + (value.underground ?? 0) + (value.structures ?? 0) + (value.noAccessStructures ?? 0) + (value.structuresNotInField ?? 0)) / value.requestFielding * 100)}/>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            ))}
            <Alert severity="error" sx={{fontSize: '15px', textTransform: 'uppercase'}}>Total <b>quantity completion Input</b> is mandatory for <b>mileage claim</b></Alert>
            {/*{values?.map((value, index) => (*/}
            {/*    */}
            {/*))}*/}
            {/*{values?.map((value, index) => (*/}
            {/*    <Box sx={{ padding: "20px", width: "100%" }} key={value.customTypeId}>*/}
            {/*        <Typography*/}
            {/*            sx={{*/}
            {/*                fontSize: "16px"*/}
            {/*            }}*/}
            {/*        >*/}
            {/*            {value.customTypeString}*/}
            {/*        </Typography>*/}
            {/*        <Box display={"flex"} justifyContent={"space-between"} flexWrap={"wrap"} alignItems={"center"} gap={1}>*/}
            {/*            <Typography>Total Request: {value.requestFielding}</Typography>*/}
            {/*            <Box display={"flex"} gap={1} alignItems={"center"} justifyContent={"flex-end"}>*/}
            {/*                <Typography sx={{ whiteSpace: "nowrap" }}>Total Actual:</Typography>*/}
            {/*                {index === indexToEdit*/}
            {/*                    ? (*/}
            {/*                        <Fragment>*/}
            {/*                            <TextField*/}
            {/*                                type="number"*/}
            {/*                                value={tempEditValue}*/}
            {/*                                sx={{ width: 70 }}*/}
            {/*                                onChange={(e) => setTempEditValue(parseInt(e.target.value))}*/}
            {/*                            />*/}
            {/*                            <Box display={"flex"} gap={1}>*/}
            {/*                                <Button size="small" variant="outlined" onClick={() => setIndexToEdit(undefined)}>Cancel</Button>*/}
            {/*                                <Button size="small" variant="contained" onClick={() => { setIndexToEdit(undefined); setValues(values.map((value, i) => i === index ? ({ ...value, finalActualFielding: tempEditValue }) : value)) }}>Done</Button>*/}
            {/*                            </Box>*/}
            {/*                        </Fragment>*/}
            {/*                    )*/}
            {/*                    : (*/}
            {/*                        <Typography>{value.finalActualFielding}</Typography>*/}
            {/*                    )*/}
            {/*                }*/}
            {/*                {isContainedLess80(value.customTypeId) && <img src={warning} alt="wrning" height={42} width={42} />}*/}
            {/*                {index !== indexToEdit && (*/}
            {/*                    <Button size="small" variant="contained" onClick={() => { setIndexToEdit(index); setTempEditValue(value.finalActualFielding) }}>Edit</Button>*/}
            {/*                )}*/}
            {/*            </Box>*/}
            {/*            <Box display={"flex"} gap={1} width={isMobile ? "100%" : 200} alignItems={"center"}>*/}
            {/*                <Box*/}
            {/*                    sx={{*/}
            {/*                        height: 24,*/}
            {/*                        borderRadius: 1,*/}
            {/*                        bgcolor: progressColor(value.progressPercentage || 0),*/}
            {/*                        display: "flex",*/}
            {/*                        alignItems: "center",*/}
            {/*                        justifyContent: "center",*/}
            {/*                        paddingLeft: 1,*/}
            {/*                        paddingRight: 4,*/}
            {/*                        marginRight: -4*/}
            {/*                    }}*/}
            {/*                >*/}
            {/*                    <Typography color={"white"}>*/}
            {/*                        {value.progressPercentage}%*/}
            {/*                    </Typography>*/}
            {/*                </Box>*/}
            {/*                <Box*/}
            {/*                    sx={{*/}
            {/*                        borderWidth: 1,*/}
            {/*                        borderColor: progressColor(value.progressPercentage || 0),*/}
            {/*                        borderStyle: "solid",*/}
            {/*                        bgcolor: "white",*/}
            {/*                        width: "100%",*/}
            {/*                        height: 24,*/}
            {/*                        borderRadius: 1,*/}
            {/*                        padding: "3px"*/}
            {/*                    }}*/}
            {/*                >*/}
            {/*                    <Box*/}
            {/*                        sx={{*/}
            {/*                            bgcolor: progressColor(value.progressPercentage || 0),*/}
            {/*                            width: (value.progressPercentage || 0) + "%",*/}
            {/*                            height: "100%",*/}
            {/*                            borderRadius: 1,*/}
            {/*                        }}*/}
            {/*                    >*/}
            {/*                    </Box>*/}
            {/*                </Box>*/}
            {/*                /!* <Slider*/}
            {/*                    sx={{ color: progressColor(value.progressPercentage || 0) }}*/}
            {/*                    defaultValue={0}*/}
            {/*                    value={value.progressPercentage || 0}*/}
            {/*                /> *!/*/}
            {/*            </Box>*/}
            {/*        </Box>*/}
            {/*    </Box>*/}
            {/*// ))}*/}
            <Grid sx={{display: "flex", width: "100%"}}>
                <Grid>
                    <Button variant="contained" size="large"
                            sx={{margin: "16px 0"}} color="secondary" onClick={props.onClose}>Cancel</Button>
                </Grid>
                <Grid sx={{display: "flex", justifyContent: "end", width: "100%"}}>
                    <Button
                        variant="contained"
                        onClick={handlerSubmit}
                        size="large"
                        sx={{margin: "16px 0"}}
                    >
                        Submit
                    </Button>
                </Grid>
            </Grid>

            {/*<Typography color={"error"} variant="h6" align="center">*/}
            {/*    WARNING: PROGRESS UPDATE & QUANTITY COMPLETION IS MANDATORY FOR MILAGE CLAIM TO BE ACKNOWLEDGED*/}
            {/*</Typography>*/}
        </div>
    )
}