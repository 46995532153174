import { Box, Button, Container, FormControlLabel, MenuItem, Select } from "@mui/material";
import { useRef, useState } from "react";
import FieldingList from "./FieldingList";
import FieldingClock from "./FIeldingClock";
import FieldingMap from "./FieldingMap";
import CustomModal from "../../../shared/customModal/CustomModal";
import ExpenseCreate from "../../expense/component/ExpenseCreate";

export default function Fielding() {

    const mapContainerRef = useRef<any>()

    const [viewMode, setViewMode] = useState<"LIST" | "MAP">("LIST")
    const [modalExpense, setModalExpense] = useState(false)

    return (
        <Box>
            <CustomModal
                open={modalExpense}
                onClose={() => setModalExpense(false)}
                title="Add Expense"
                component={<ExpenseCreate onSubmit={() => setModalExpense(false)} />}
                size={"md"}
            />
            <Container
                maxWidth={"xl"}
                sx={{
                    marginY: 2,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexWrap: "wrap",
                    gap: 1
                }}
            >
                <FormControlLabel
                    label="View Mode: "
                    labelPlacement="start"
                    componentsProps={{ typography: { whiteSpace: "nowrap" } }}
                    control={
                        <Select
                            fullWidth
                            size="small"
                            value={viewMode}
                            onChange={(e) => setViewMode(e.target.value as unknown as "LIST" | "MAP")}
                            sx={{ marginLeft: 1 }}
                        >
                            <MenuItem value={"LIST"}>List View</MenuItem>
                            <MenuItem value={"MAP"}>Map View</MenuItem>
                        </Select>
                    }
                />
                <Button sx={{ marginLeft: 1 }} variant="contained" onClick={() => setModalExpense(true)}>Expenses</Button>
                <Box display={"flex"} flexGrow={1}></Box>
                <FieldingClock />
            </Container>
            {viewMode === "LIST" && (
                <Box ref={mapContainerRef}>
                    <FieldingList />
                </Box>
            )}
            {viewMode === "MAP" && (
                <Box
                    ref={mapContainerRef}
                    display={"flex"}
                    flexGrow={1}
                    minHeight={window.innerHeight - mapContainerRef.current?.getBoundingClientRect().y}
                >
                    <FieldingMap />
                </Box>
            )}
        </Box>
    )
}