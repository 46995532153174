import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material"
import { Button, MenuItem, Select, Card, Box, Typography, IconButton, List, ListItem } from "@mui/material"
import { Fragment, useEffect, useState } from "react"
import { filterListLocationLog, secondsFormat, useWindowSize } from "../../../shared/utils"
import { InterfaceDownloadLog, InterfaceFieldingDetail } from "../../fielding/fielding.interface"
import moment from "moment"
import Slider from "@mui/material/Slider"
import { styled } from "@mui/material/styles"
import Swal from "sweetalert2"

export default function ProjectFormFieldingLogs(props: {
    fieldingRequest: InterfaceFieldingDetail
    showTimeStamp: InterfaceDownloadLog
    setShowTimeStamp: any
    handleLocationLog: any
}) {

    const { width } = useWindowSize()
    const [SelectedLogs, setSelectedLogs] = useState<any>(props.showTimeStamp)
    const [ListTravelLog, setListTravelLog] = useState<Array<InterfaceDownloadLog>>([])
    const [SelectedTravelLogs, setSelectedTravelLogs] = useState<any>(props.showTimeStamp.selected)
    const [ListLocationLogs, setListLocationLogs] = useState<any>(props.showTimeStamp?.locationLogs)
    const [MarkSlider, setMarkSlider] = useState<any>([])
    const [InitialSlider, setInitialSlider] = useState<any>([])
    const [ValueSlider, setValueSlider] = useState<any>(1)

    useEffect(() => {
        setSelectedLogs(props?.showTimeStamp)
        let getAllTravelLog: any = []
        props.fieldingRequest.mobileDownloadLogs?.map(
            (moblog) =>
                moblog.locationLogs.length > 0 &&
                moblog.travelLogs?.map((log: any) => {
                    if (log?.miles > 0) getAllTravelLog.push(log)
                })
        )
        setListTravelLog(getAllTravelLog)
        if (props?.showTimeStamp?.selected) {
            let propSelect = props?.showTimeStamp?.selected ?? {}
            setSelectedTravelLogs(propSelect)
            let listLocationLog = filterListLocationLog(propSelect, props?.showTimeStamp)
            setListLocationLogs(listLocationLog)
        }
    }, [props.showTimeStamp])

    useEffect(() => {
        initMarker()
    }, [ListLocationLogs])

    const handleChange = (e: any) => {
        props.setShowTimeStamp({ ...props.showTimeStamp, selected: ListTravelLog[e.target.value] })
    }

    const initMarker = () => {
        let __tempDate = ""
        let mark = ListLocationLogs.map((log: any, i: number) => {
            if (__tempDate !== log.dateTime.split(" ")[0]) {
                __tempDate = log.dateTime.split(" ")[0]
                return { value: i + 1, label: __tempDate }
            }
        })
            .filter((log: any) => log !== undefined)
            .map((log: any) => log)
        setMarkSlider(mark)
        const sliderId =
            props.showTimeStamp.userName +
            "_" +
            props.showTimeStamp.downloadedDate +
            "_" +
            props.showTimeStamp.downloadedTime
        const newValuesSlider =
            InitialSlider.length === 0
                ? [
                    {
                        sliderId,
                        value: ListLocationLogs.length === 1 ? ListLocationLogs.length : 1
                    }
                ]
                : InitialSlider.map((val: any) => {
                    if (val.sliderId === sliderId) return val
                    else {
                        console.log("its new")

                        return {
                            sliderId,
                            value: ListLocationLogs.length === 1 ? ListLocationLogs.length : 1
                        }
                    }
                })
        setInitialSlider(newValuesSlider)
        handleChangeSlider(null, 1)
        setValueSlider(1)
    }

    const handleChangeSlider = (event: Event | null, newValue: number | number[]) => {
        if (typeof newValue === "number" && newValue !== 0) {
            setValueSlider(newValue)
            let marker = ListLocationLogs[ListLocationLogs.length > 1 ? newValue - 1 : 0]
            console.log("marker", ListLocationLogs.length > 1 ? newValue - 1 : 1, marker)
            if (marker)
                props.handleLocationLog({
                    id: SelectedLogs.downloadedDate + SelectedLogs.downloadedTime,
                    name: moment(marker.dateTime).format("hh:mm A"),
                    color: "green",
                    markers: [
                        {
                            dotLongLat: marker.longLat,
                            latitude: marker.longLat.split(",")[1],
                            longitude: marker.longLat.split(",")[0]
                        }
                    ]
                })
        }
    }

    return (
        <Fragment>
            <div style={{ padding: 2, paddingTop: 4 }} className="fielding-logs">
                <div style={{ width: width < 900 ? "100%" : "30%", display: "flex", flexDirection: "column" }}>
                    <label htmlFor="log-select">Fielding Logs</label>
                    {/*<Select*/}
                    {/*    defaultValue={10}*/}
                    {/*    id="fielder-log-select"*/}
                    {/*    name="log-select"*/}
                    {/*    onChange={handleChange}*/}
                    {/*    value={ListTravelLog.findIndex(*/}
                    {/*        (fieldLog: any) =>*/}
                    {/*            SelectedTravelLogs.miles === fieldLog.miles &&*/}
                    {/*            SelectedTravelLogs.dateTime === fieldLog.dateTime*/}
                    {/*    )}*/}
                    {/*>*/}
                    {/*    {ListTravelLog.map((log: any, i) => (*/}
                    {/*        <MenuItem key={log.miles + log.dateTime} value={i}>*/}
                    {/*            {props.showTimeStamp?.userName},/!* {log.miles} miles, *!/ {log.dateTime}*/}
                    {/*        </MenuItem>*/}
                    {/*    ))}*/}
                    {/*</Select>*/}
                </div>
                <div
                    style={{
                        width: width < 900 ? "100%" : "70%",
                        padding: "20px 24px",
                        marginTop: "35px"
                    }}
                >
                    {/*<CustomSlider*/}
                    {/*    valueLabelDisplay="auto"*/}
                    {/*    aria-label="Custom marks"*/}
                    {/*    getAriaValueText={(v) => "Date Log  " + v}*/}
                    {/*    sx={{ flexGrow: 1, padding: 0 }}*/}
                    {/*    color="primary"*/}
                    {/*    marks={MarkSlider}*/}
                    {/*    step={1}*/}
                    {/*    min={1}*/}
                    {/*    value={ValueSlider}*/}
                    {/*    max={ListLocationLogs.length === 1 ? 2 : ListLocationLogs.length}*/}
                    {/*    onChange={handleChangeSlider}*/}
                    {/*    valueLabelFormat={(i) =>*/}
                    {/*        moment(ListLocationLogs[i - 1]?.dateTime).format("hh:mm A") ?? "--:--:--"*/}
                    {/*    }*/}
                    {/*/>*/}
                </div>
            </div>
        </Fragment>
    )
}

const FieldingLogsPanel = (props: {
    fieldingRequest: InterfaceFieldingDetail
    setShowTimeStamp: any
    showTimeStamp: any
    handleLocationLog: any
}) => {
    const [showLogs, setShowLog] = useState(false)
    const [TabDownloadLog, setTabDownloadLog] = useState(false)
    const [TotalDownloadLog, setTotalDownloadLog] = useState(0)

    useEffect(() => {
        const downloadLog = props.fieldingRequest.mobileDownloadLogs
        if (downloadLog.length > 0) {
            let checkTotalMiles: any = downloadLog
                .map((moblog: any) => ({
                    miles: moblog.travelLogs.reduce((total: number, data: any) => total + data.miles, 0)
                }))
                .reduce((total: number, data: any) => total + data.miles, 0)
            checkTotalMiles = checkTotalMiles % 1 !== 0 ? checkTotalMiles.toFixed(3) : checkTotalMiles
            setTotalDownloadLog(checkTotalMiles)
        }
    }, [props.fieldingRequest])

    const checkNewOrOldFeature = (log: any) => {
        if (log.travelLogs.length > 1) {
            let total = 1
            let __tempTime: any = null
            log.travelLogs.map((log: any) => {
                let check = new Date(log.dateTime).getTime()
                if (__tempTime) {
                    if (__tempTime === check) total = total + 1
                    else __tempTime = check
                } else {
                    __tempTime = check
                }
            })
            if (total === log.travelLogs.length) {
                setTimeout(() => {
                    Swal.fire({
                        title: "Information",
                        icon: "info",
                        text: "There's an update in our mobile apps, please make sure to install newest apk"
                    })
                }, 3000)
            }
        }
    }

    const handleSelectTimestamp = (selected: any, moblog: any, log: any) => {
        if (!selected) {
            props.setShowTimeStamp((prev: any) => {
                checkNewOrOldFeature(moblog)
                return { ...moblog, selected: log }
            })
        }
        else {
            props.setShowTimeStamp(false)
        }
    }

    const averageperJob = (TotalDownloadLog / props.fieldingRequest.mobileDownloadLogs.reduce((total, log) => total + log.travelLogs.filter(travel => travel.miles && travel.miles > 0).length, 0))

    return (
        <Card variant="outlined" style={{ margin: "4px 0" }}>
            <Box width={"100%"} display={"flex"}>
                <Button
                    sx={{
                        borderRadius: 0,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                        width: "50%",
                        cursor: "pointer",
                        backgroundColor: TabDownloadLog ? "white" : "lightgray",
                        padding: "10px"
                    }}
                    onClick={() => setTabDownloadLog(true)}
                >
                    <Typography sx={{ fontWeight: "600", color: TabDownloadLog ? "" : "gray" }}>
                        Travel Logs
                    </Typography>
                </Button>
                <Button
                    sx={{
                        borderRadius: 0,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                        width: "50%",
                        cursor: "pointer",
                        backgroundColor: TabDownloadLog ? "lightgray" : "white",
                        padding: "10px"
                    }}
                    onClick={() => setTabDownloadLog(false)}
                >
                    <Typography sx={{ fontWeight: "600", color: TabDownloadLog ? "gray" : "" }}>
                        Fielding Logs
                    </Typography>
                </Button>
            </Box>
            <Box display={"flex"} justifyContent={"space-between"} padding={"4px 12px"}>
                <Box display={"flex"}>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    Total {TabDownloadLog ? "Miles" : "Hours"}
                                </td>
                                <td>:</td>
                                <td style={{ fontWeight: "bold" }}>
                                    {TabDownloadLog
                                        ? TotalDownloadLog
                                        : ` ${secondsFormat(
                                            props.fieldingRequest.fieldingRequestMarkerFieldingLogs.reduce(
                                                (total, log) =>
                                                    total + moment(log.endDate).diff(moment(log.startDate), "seconds"),
                                                0
                                            )
                                        )}`}
                                    {TabDownloadLog ? " Miles" : ""}
                                </td>
                            </tr>
                            {TabDownloadLog && (
                                <tr>
                                    <td>Average per Job</td>
                                    <td>:</td>
                                    <td style={{ fontWeight: "bold" }}>
                                        {
                                            isNaN(averageperJob) ? "-" : averageperJob.toFixed(2)
                                        } Miles
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </Box>
                <IconButton onClick={() => setShowLog(!showLogs)}>
                    {showLogs ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                </IconButton>
            </Box>
            {showLogs && (
                <List disablePadding>
                    {(props.fieldingRequest.fieldingRequestMarkerFieldingLogs.length > 0 && !TabDownloadLog) ||
                        (props.fieldingRequest.mobileDownloadLogs.length > 0 && TabDownloadLog) ? (
                        <div
                            style={{
                                borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                                maxHeight: "200px",
                                overflowY: "auto",
                                padding: 10,
                                paddingTop: "0px",
                                display: "grid"
                            }}
                        >
                            {TabDownloadLog
                                ? props.fieldingRequest.mobileDownloadLogs.filter((moblog: any) => moblog.travelLogs.some((log: any) => log?.miles && log.miles > 0)).map((moblog: any, i: number) => {
                                    return (
                                        <ListItem
                                            key={i}
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                                                padding: "10px 6px"
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "space-between",
                                                    width: "100%",
                                                    marginBottom: "2px"
                                                }}
                                            >
                                                <Typography sx={{ color: "black", fontWeight: 500 }}>
                                                    {moblog.userName}
                                                </Typography>

                                                <Typography sx={{ color: "gray", textAlign: "right" }}>
                                                    {moment(moblog.downloadedDate).format("MM/DD/YYYY")},{" "}
                                                    {moblog.downloadedTime}
                                                </Typography>
                                            </Box>
                                            {moblog.travelLogs.filter((log: any) => log?.miles && log.miles > 0).map((log: any, i: number) => {
                                                let selected = false
                                                if (props.showTimeStamp) {
                                                    let logTimestamp = log?.miles.toString() + log?.dateTime
                                                    let currentTimestamp =
                                                        props.showTimeStamp?.selected.miles.toString() +
                                                        props.showTimeStamp?.selected.dateTime
                                                    if (logTimestamp === currentTimestamp) selected = true
                                                }
                                                return (
                                                    <Box
                                                        key={i}
                                                        sx={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "space-between",
                                                            width: "100%",
                                                            marginTop: "6px"
                                                        }}
                                                    >
                                                        <Typography>
                                                            {log?.miles} Miles
                                                        </Typography>
                                                        {/*{log?.miles > 0*/}
                                                        {/*    ? (*/}
                                                        {/*        <Button*/}
                                                        {/*            variant={"outlined"}*/}
                                                        {/*            onClick={() => {*/}
                                                        {/*                handleSelectTimestamp(selected, moblog, log)*/}
                                                        {/*            }}*/}
                                                        {/*            sx={{*/}
                                                        {/*                position: "relative",*/}
                                                        {/*                right: "-4px",*/}
                                                        {/*                padding: "2px 8px",*/}
                                                        {/*                minWidth: "120px",*/}
                                                        {/*                background: selected ? "#093a93" : "",*/}
                                                        {/*                color: selected ? "white" : "",*/}
                                                        {/*                ":hover": {*/}
                                                        {/*                    bgcolor: !selected ? "" : "#062a6c"*/}
                                                        {/*                }*/}
                                                        {/*            }}*/}
                                                        {/*        >*/}
                                                        {/*            Check Timestamp*/}
                                                        {/*        </Button>*/}
                                                        {/*    )*/}
                                                        {/*    : (*/}
                                                        {/*        ""*/}
                                                        {/*    )*/}
                                                        {/*}*/}
                                                    </Box>
                                                )
                                            })}
                                        </ListItem>
                                    )
                                })
                                : props.fieldingRequest.fieldingRequestMarkerFieldingLogs.map((log, i) => (
                                    <ListItem
                                        key={i}
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                                            padding: "10px 6px"
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                width: "100%",
                                                marginBottom: "2px"
                                            }}
                                        >
                                            <Typography sx={{ color: "black", fontWeight: 500 }}>
                                                {log.createdBy}
                                            </Typography>

                                            <Typography sx={{ color: "gray", textAlign: "right" }}>
                                                {moment(log.startDate).format("MM/DD/YYYY")}
                                            </Typography>
                                        </Box>

                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                width: "100%",
                                                marginTop: "6px"
                                            }}
                                        >
                                            <Typography>{TabDownloadLog ? "... Miles" : ""}</Typography>
                                            {TabDownloadLog ? (
                                                <Button
                                                    variant="outlined"
                                                    onClick={() => props.setShowTimeStamp(log)}
                                                    sx={{
                                                        position: "relative",
                                                        right: "-4px",
                                                        padding: "2px 8px",
                                                        minWidth: "120px"
                                                    }}
                                                >
                                                    Check Timestamp
                                                </Button>
                                            ) : (
                                                <Typography sx={{ fontWeight: "bold" }}>
                                                    {secondsFormat(
                                                        moment(log.endDate).diff(moment(log.startDate), "seconds")
                                                    )}
                                                </Typography>
                                            )}
                                        </Box>
                                    </ListItem>
                                ))}
                        </div>
                    ) : (
                        <ListItem>No data</ListItem>
                    )}
                    { }
                </List>
            )}
        </Card>
    )
}

export { FieldingLogsPanel }

const iOSBoxShadow = "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)"

const CustomSlider = styled(Slider)(({ theme }) => ({
    color: "#093a93",
    height: 10,
    padding: "15px 0",
    "& .MuiSlider-thumb": {
        height: 28,
        width: 28,
        backgroundColor: "#fff",
        boxShadow: iOSBoxShadow,
        "&:focus, &:hover, &.Mui-active": {
            boxShadow: "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
            // Reset on touch devices, it doesn't add specificity
            "@media (hover: none)": {
                boxShadow: iOSBoxShadow
            }
        }
    },
    "& .MuiSlider-valueLabel": {
        fontSize: 12,
        fontWeight: "normal",
        top: -6,
        backgroundColor: "unset",
        color: theme.palette.text.primary,
        "&:before": {
            display: "none"
        },
        "& *": {
            background: "transparent",
            color: theme.palette.mode === "dark" ? "#fff" : "#000"
        }
    },
    "& .MuiSlider-track": {
        border: "none"
    },
    "& .MuiSlider-step": {
        border: "1px solid red",
        backgroundColor: "red",
        width: 10,
        height: 10
    },
    "& .MuiSlider-rail": {
        opacity: 0.5,
        backgroundColor: "#bfbfbf"
    },
    "& .MuiSlider-markLabel": {
        fontWeight: "bold",
        fontSize: 14,
        color: "black",
        "&.MuiSlider-markLabelActive": {
            color: "#3f82fb",
            opacity: 1
            // backgroundColor: "currentColor"
        }
    },
    "& .MuiSlider-mark": {
        backgroundColor: "#bfbfbf",
        height: 30,
        width: 3,
        "&.MuiSlider-markActive": {
            opacity: 1,
            backgroundColor: "currentColor"
        }
    }
}))
