import { Send } from "@mui/icons-material";
import { Box, IconButton, TextField } from "@mui/material";
import { FormEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { fieldingNoteCommentCreate } from "../../fielding/fielding.api";
import { userInfo } from "../../../shared/utils";

export default function NotificationNoteReply(props: { noteId: string, onSubmit: () => void }) {

    const dispatch = useDispatch<any>()

    const [notesComment, setNotesComment] = useState("")

    const handleComment = (e: FormEvent) => {
        e.preventDefault()
        dispatch(fieldingNoteCommentCreate({
            token: userInfo().token,
            fieldingRequestNoteId: props.noteId,
            comment: notesComment
        }))
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    setNotesComment("")
                    props.onSubmit()
                }
            })
    }

    return (
        <Box>
            <form onSubmit={handleComment} style={{ display: "flex", flexGrow: 1 }}>
                <TextField label="Reply" value={notesComment} onChange={(e) => setNotesComment(e.target.value)} />
                <IconButton type="submit">
                    <Send />
                </IconButton>
            </form>
        </Box>
    )
}