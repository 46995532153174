import { createSlice } from '@reduxjs/toolkit';
import {
  markerDelete,
  projectAttachmentDelete,
  projectComplete,
  projectCreate,
  projectCustomerCodeCreate,
  projectCustomerCodeDelete,
  projectCustomerCodeGet,
  projectDelete,
  projectEdit,
  projectEditMarker,
  projectFieldingAdd,
  projectGet,
  projectGetDetail,
  projectGetEnum,
  projectGetForStorage,
  projectLinkAppend,
  projectMoveToActive,
  projectMoveToArchive,
  ratingFielderAdd,
} from './project.api';
import {
  InitialProjectDetail,
  InitialProjectEnum,
  InitialProjectGetParams,
  InterfaceProjectCustomerCodeList,
  InterfaceProjectDataList,
  InterfaceProjectDetail,
  InterfaceProjectEnum,
  InterfaceProjectGetParams,
} from './project.interface';

export interface ProjectState {
  loading: boolean;
  parameterGetData: InterfaceProjectGetParams;
  dataList: InterfaceProjectDataList;
  dataExpandedList: Array<InterfaceProjectDetail>;
  dataExpandedAddressList: Array<{ id: string; value: string }>;
  dataTotal: number;
  enumProject: InterfaceProjectEnum;
  detail: InterfaceProjectDetail;
  dataCustomerCodeList: InterfaceProjectCustomerCodeList;
}
const initialState: ProjectState = {
  loading: false,
  parameterGetData: InitialProjectGetParams,
  dataList: [],
  dataExpandedList: [],
  dataExpandedAddressList: [],
  dataTotal: 0,
  enumProject: InitialProjectEnum,
  detail: InitialProjectDetail,
  dataCustomerCodeList: [],
};

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setParameterGetData: (state, action) => {
      state.parameterGetData = action.payload;
    },
    setDataExpandedList: (state, action) => {
      state.dataExpandedList = action.payload;
    },
    setDataExpandedAddressList: (state, action) => {
      state.dataExpandedAddressList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(projectCreate.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(projectCreate.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(projectCreate.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(projectEdit.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(projectEdit.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(projectEdit.rejected, (state, action) => {
      state.loading = false;
    });

    // builder.addCase(projectEditMarker.pending, (state, action) => {
    //   state.loading = true;
    // });
    // builder.addCase(projectEditMarker.fulfilled, (state, action) => {
    //   state.loading = false;
    // });
    // builder.addCase(projectEditMarker.rejected, (state, action) => {
    //   state.loading = false;
    // });

    builder.addCase(projectGet.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(projectGet.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.data.status) {
        state.dataList = action.payload.data.data.data;
        state.dataTotal = action.payload.data.data.total;
      }
    });
    builder.addCase(projectGet.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(projectGetForStorage.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(projectGetForStorage.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(projectGetForStorage.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(projectDelete.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(projectDelete.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(projectDelete.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(markerDelete.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(markerDelete.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(markerDelete.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(projectGetEnum.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(projectGetEnum.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.data.status) {
        state.enumProject = action.payload.data.data;
      }
    });
    builder.addCase(projectGetEnum.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(projectGetDetail.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(projectGetDetail.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.data.status) {
        state.detail = action.payload.data.data;
      }
    });
    builder.addCase(projectGetDetail.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(projectCustomerCodeCreate.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(projectCustomerCodeCreate.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(projectCustomerCodeCreate.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(projectCustomerCodeGet.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(projectCustomerCodeGet.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.data.status) {
        state.dataCustomerCodeList = action.payload.data.data;
      }
    });
    builder.addCase(projectCustomerCodeGet.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(projectCustomerCodeDelete.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(projectCustomerCodeDelete.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(projectCustomerCodeDelete.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(projectLinkAppend.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(projectLinkAppend.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(projectLinkAppend.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(projectAttachmentDelete.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(projectAttachmentDelete.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(projectAttachmentDelete.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(projectComplete.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(projectComplete.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(projectComplete.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(projectMoveToActive.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(projectMoveToActive.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(projectMoveToActive.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(projectMoveToArchive.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(projectMoveToArchive.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(projectMoveToArchive.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(projectFieldingAdd.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(projectFieldingAdd.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(projectFieldingAdd.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(ratingFielderAdd.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(ratingFielderAdd.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(ratingFielderAdd.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const {
  setParameterGetData,
  setDataExpandedList,
  setDataExpandedAddressList,
} = projectSlice.actions;

export default projectSlice.reducer;
