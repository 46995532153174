import { ImportExport, North, South } from "@mui/icons-material";
import { IconButton, List, ListItemButton, ListItemIcon, ListItemText, Popover } from "@mui/material";
import { useState } from "react";
import { Fragment } from "react/jsx-runtime";

export default function TableHeader(props: { name: string, field: string, fieldSelected: string, sortTypeSelected: number, onAscending: () => void, onDescending: () => void }) {

    const [popAnchor, setPopAnchor] = useState<HTMLButtonElement | null>(null)

    return (
        <Fragment>
            <Popover
                open={Boolean(popAnchor)}
                anchorEl={popAnchor}
                onClose={() => setPopAnchor(null)}
                onClick={() => setPopAnchor(null)}
            >
                <List>
                    <ListItemButton onClick={props.onAscending}>
                        <ListItemIcon>
                            <North />
                        </ListItemIcon>
                        <ListItemText primary="Sort A to Z" />
                    </ListItemButton>
                    <ListItemButton onClick={props.onDescending}>
                        <ListItemIcon>
                            <South />
                        </ListItemIcon>
                        <ListItemText primary="Sort Z to A" />
                    </ListItemButton>
                </List>
            </Popover>
            {props.name}
            <IconButton onClick={(e) => setPopAnchor(e.currentTarget)}>
                {props.fieldSelected !== props.field ? (
                    <ImportExport />
                ) : props.sortTypeSelected === 0 ? (
                    <North />
                ) : props.sortTypeSelected === 1 ? (
                    <South />
                ) : (
                    <></>
                )}
            </IconButton>
        </Fragment>
    )
}