import { useEffect, useRef, useState } from "react"

import esriConfig from "@arcgis/core/config"
import esriMap from "@arcgis/core/Map"
import esriMapView from "@arcgis/core/views/MapView"
import esriGraphicsLayer from "@arcgis/core/layers/GraphicsLayer"
import esriZoom from "@arcgis/core/widgets/Zoom"
import esriSearch from "@arcgis/core/widgets/Search"
import esriGraphics from "@arcgis/core/Graphic"
import esriPoint from "@arcgis/core/geometry/Point"
import esriMarkerSymbol from "@arcgis/core/symbols/SimpleMarkerSymbol"
import esriPolyline from "@arcgis/core/geometry/Polyline"
import esriSimpleLineSymbol from "@arcgis/core/symbols/SimpleLineSymbol"
import esriPolygon from "@arcgis/core/geometry/Polygon"
import esriSimpleFillSymbol from "@arcgis/core/symbols/SimpleFillSymbol"
import esriTextSymbol from "@arcgis/core/symbols/TextSymbol"

import { InterfaceFieldingMarkerList, InterfaceMarkerPoint } from "../../fielding/fielding.interface"
import { esriToken } from "../../../state"
import axios from "axios"

interface interfaceFieldingRequest {
    id: string,
    name: string,
    markers: InterfaceFieldingMarkerList,
    color: string
}

export default function ProjectMapDetailMap(props: {
    fieldingRequestList: Array<interfaceFieldingRequest>,
    center: InterfaceMarkerPoint | null
}) {

    const mapRef = useRef<any>()

    const [view, setView] = useState<any>()
    const [layerMarker, setLayerMarker] = useState<any>()

    useEffect(() => {
        axios("https://ipapi.co/json")
            .then(response => {
                // initialize
                esriConfig.apiKey = esriToken
                let mapLocal = new esriMap({
                    basemap: "topo-vector" //"satellite"
                })
                let layerMarkerLocal = new esriGraphicsLayer()
                let layerMarkerTempLocal = new esriGraphicsLayer()
                mapLocal.add(layerMarkerLocal)
                mapLocal.add(layerMarkerTempLocal)
                let viewLocal = new esriMapView({
                    container: mapRef.current,
                    map: mapLocal,
                    zoom: props.center !== null ? 15 : 11,
                    center: props.center !== null ? [props.center.longitude, props.center.latitude] : [response.data.longitude, response.data.latitude],
                })
                // widget
                let viewZoom = new esriZoom({ view: viewLocal })
                viewLocal.ui.remove(viewZoom)
                let viewSearch = new esriSearch({ view: viewLocal, locationEnabled: false })
                viewLocal.ui.add(viewSearch, { position: "top-left" })
                // listener
                viewLocal.on("layerview-create", () => {
                    if (document.getElementById("searchbox-input")) {
                        (document.getElementById("searchbox-input") as HTMLInputElement).placeholder = "Enter adress or GPS"
                    }
                })
                setLayerMarker(layerMarkerLocal)
                setView(viewLocal)
            })
        return () => view?.destroy()
    }, [])

    useEffect(() => {
        if (layerMarker) {
            let textMarkers: Array<any> = []
            let pointMarkers: Array<any> = []
            let lineMarkers: Array<any> = []
            let shapeMarkers: Array<any> = []
            props.fieldingRequestList.map(fieldingRequest => {
                
                pointMarkers = [...pointMarkers, ...fieldingRequest.markers?.filter(marker => marker.dotLongLat !== '').map(marker => {
                    textMarkers.push(new esriGraphics({
                        geometry: new esriPoint({
                            latitude: parseFloat(marker.dotLongLat.split(',')[1]),
                            longitude: parseFloat(marker.dotLongLat.split(',')[0])
                        }),
                        symbol: new esriTextSymbol({
                            color: "black",
                            text: fieldingRequest.name,
                            xoffset: 40,
                            yoffset: -25,
                            font: {
                                size: 12,
                                family: "Roboto"
                            }
                        }),
                        attributes: {
                            type: "FIELDING_TEXT",
                            fieldingRequestId: fieldingRequest.id
                        }
                    }))
                    return new esriGraphics({
                        geometry: new esriPoint({
                            latitude: parseFloat(marker.dotLongLat.split(',')[1]),
                            longitude: parseFloat(marker.dotLongLat.split(',')[0])
                        }),
                        symbol: new esriMarkerSymbol({
                            style: "circle",
                            color: "red"
                        }),
                        attributes: {
                            type: "FIELDING_DOT",
                            fieldingRequestId: fieldingRequest.id
                        }
                    })
                })]
                fieldingRequest.markers?.filter(marker => marker.dotLongLat === '').map(marker => {
                    marker.lineData.map(lineData => {
                        try {
                            textMarkers.push(new esriGraphics({
                                geometry: new esriPoint({
                                    latitude: lineData[0].latitude,
                                    longitude: lineData[0].longitude
                                }),
                                symbol: new esriTextSymbol({
                                    color: "black",
                                    text: fieldingRequest.name,
                                    xoffset: 40,
                                    yoffset: -25,
                                    font: {
                                        size: 12,
                                        family: "Roboto"
                                    }
                                }),
                                attributes: {
                                    type: "FIELDING_TEXT",
                                    fieldingRequestId: fieldingRequest.id
                                }
                            }))
                            let lineMarker = new esriGraphics({
                                geometry: new esriPolyline({
                                    paths: [lineData.map((line) => [line.longitude, line.latitude])]
                                }),
                                symbol: new esriSimpleLineSymbol({
                                    color: fieldingRequest.color,
                                    width: 2
                                }),
                                attributes: {
                                    type: "FIELDING_LINE",
                                    fieldingRequestId: fieldingRequest.id
                                }
                            })
                            lineMarkers = [...lineMarkers, lineMarker]
                        } catch (error) {
                            console.log(error)
                        }
                        return null
                    })
                    return null
                })
                fieldingRequest.markers?.filter(marker => marker.dotLongLat === '').map(marker => {
                    marker.shapeData.map(shapeData => {
                        try {
                            textMarkers.push(new esriGraphics({
                                geometry: new esriPoint({
                                    latitude: shapeData[0].latitude,
                                    longitude: shapeData[0].longitude
                                }),
                                symbol: new esriTextSymbol({
                                    color: "black",
                                    text: fieldingRequest.name,
                                    xoffset: 40,
                                    yoffset: -25,
                                    font: {
                                        size: 12,
                                        family: "Roboto"
                                    }
                                }),
                                attributes: {
                                    type: "FIELDING_TEXT",
                                    fieldingRequestId: fieldingRequest.id
                                }
                            }))
                            let shapeMarker = new esriGraphics({
                                geometry: new esriPolygon({
                                    rings: [shapeData.map((shape) => [shape.longitude, shape.latitude])]
                                }),
                                symbol: new esriSimpleFillSymbol({
                                    color: [10, 10, 10, 0.5],
                                    outline: {
                                        color: fieldingRequest.color,
                                        width: 2
                                    }
                                }),
                                attributes: {
                                    type: "FIELDING_SHAPE",
                                    fieldingRequestId: fieldingRequest.id
                                }
                            })
                            shapeMarkers = [...shapeMarkers, shapeMarker]
                        } catch (error) {
                            console.log(error)
                        }
                        return null
                    })
                    return null
                })
            })
            layerMarker.removeAll()
            if (pointMarkers.length > 0) {
                layerMarker.addMany(pointMarkers)
            }
            if (lineMarkers.length > 0) {
                layerMarker.addMany(lineMarkers)
            }
            if (shapeMarkers.length > 0) {
                layerMarker.addMany(shapeMarkers)
            }
            if (textMarkers.length > 0) {
                layerMarker.addMany(textMarkers)
            }
        }
    }, [layerMarker, props.fieldingRequestList])

    return (
        <div className="arcgis-container">
            <div ref={mapRef} style={{ height: "100%", width: "100%" }} />
        </div>
    )
}