import Swal from "sweetalert2";

export default function notificationDelete() {
    return new Promise(resolve => {
        Swal.fire({
            icon: "question",
            title: "Are you sure want to delete this data?",
            showCancelButton: true
        })
            .then(result => {
                resolve(result.isConfirmed)
            })
    })
}