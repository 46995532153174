import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getExpenseDetail } from "../expense.api";
import { RootState } from "../../../store";
import moment from "moment";
import { CheckCircleOutline, HighlightOffOutlined, PauseCircleFilledOutlined } from "@mui/icons-material";
import ExpenseDetailAttachmentItem from "./ExpenseDetailAttachmentItem";
import ImagePreview from "../../../shared/ImagePreview";

export default function ExpenseDetail(props: { id: string | null, onClose: any, onApprove: any, onReject: any }) {

    const dispatch = useDispatch<any>()

    const { dataDetail } = useSelector((state: RootState) => state.expense)

    const [imageSelectedIndex, setImageSelectedIndex] = useState<number | null>(null)

    useEffect(() => {
        if (props.id) {
            dispatch(getExpenseDetail(props.id))
        }
    }, [dispatch])

    if (dataDetail === null) {
        return null
    }

    return (
        <Box display={"flex"} flexDirection={"column"} gap={3}>
            <Box>
                <Typography variant="h6">Expenses ID</Typography>
                <Typography variant="body1" fontWeight={"bold"}>{dataDetail.docNumber}</Typography>
            </Box>
            <Box display={"flex"} justifyContent={"space-between"}>
                <Box>
                    <Typography variant="body1" fontWeight={"bold"}>Created</Typography>
                    <Typography variant="body1" sx={{ opacity: 0.5 }}>{moment(dataDetail.createdDate).format("MM/DD/YYYY hh:mm A")}</Typography>
                </Box>
                <Box>
                    {dataDetail.status === 0 && (
                        <Button variant="text" color="warning" startIcon={<PauseCircleFilledOutlined />}>Pending</Button>
                    )}
                    {dataDetail.status === 1 && (
                        <Button variant="text" color="success" startIcon={<CheckCircleOutline />}>Approved</Button>
                    )}
                    {dataDetail.status === 2 && (
                        <Button variant="text" color="error" startIcon={<HighlightOffOutlined />}>Rejected</Button>
                    )}
                </Box>
            </Box>
            <Box>
                <Typography variant="body1" fontWeight={"bold"}>Attachment</Typography>
                {imageSelectedIndex !== null && (
                    <ImagePreview
                        name={dataDetail.attachments[imageSelectedIndex].fileName}
                        src={dataDetail.attachments[imageSelectedIndex].filePath}
                        isOpen={imageSelectedIndex !== null}
                        onClose={() => setImageSelectedIndex(null)}
                        onLeft={imageSelectedIndex > 0 ? () => setImageSelectedIndex(imageSelectedIndex - 1) : null}
                        onRight={imageSelectedIndex < (dataDetail.attachments.length - 1) ? () => setImageSelectedIndex(imageSelectedIndex + 1) : null}
                    />
                )}
                <Box display={"flex"} gap={1} flexWrap={"wrap"}>
                    {dataDetail.attachments.map((attachment, index) => (
                        <ExpenseDetailAttachmentItem key={index} name={attachment.fileName} path={attachment.filePath} onClick={() => setImageSelectedIndex(index)} />
                    ))}
                </Box>
            </Box>
            <Box>
                <Typography variant="body1" fontWeight={"bold"}>Notes</Typography>
                <Typography variant="body1">{dataDetail.notes}</Typography>
            </Box>
            <Box display={"flex"} justifyContent={"space-between"} paddingTop={1} sx={{ borderTop: "2px dashed lightGray" }}>
                <Typography variant="h5">Total</Typography>
                <Typography variant="h5">$ {dataDetail.amount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Typography>
            </Box>
            <Box display={"flex"} justifyContent={"space-between"}>
                <Button variant="contained" color="secondary" onClick={props.onClose}>Cancel</Button>
                {dataDetail.status === 0 && (
                    <Box display={"flex"} gap={1}>
                        <Button variant="contained" color="error" onClick={props.onReject}>Reject</Button>
                        <Button variant="contained" color="primary" onClick={props.onApprove}>Pay</Button>
                    </Box>
                )}
            </Box>
        </Box>
    )
}