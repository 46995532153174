import {
    Card,
    CardContent,
    CardHeader,
    Link,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    useTheme
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useEffect, useState } from "react";
import moment from "moment";
import { getDashboardFielderActive } from "../dashboard.api";
import CustomDatePicker from "../../../shared/customDatePicker/CustomDatePicker";
import { formatName } from "../../../shared/utils";
import CustomModal from "../../../shared/customModal/CustomModal";
import DashboardProject from "./DashboardProject";
import DashboardActiveFielderDetails from "./DashboardActiveFielderDetails";

export default function DashboardActiveFielder() {

    const dispatch = useDispatch<any>()
    const theme = useTheme()

    const { dataFielderActive } = useSelector((state: RootState) => state.dashboard)

    const [dateStart, setDateStart] = useState(moment().add(-7, 'days').format('YYYY-MM-DD'))
    const [dateEnd, setDateEnd] = useState(moment().format('YYYY-MM-DD'))
    const [targetFielderId, setTargetFielderId] = useState<string | undefined>(undefined)

    useEffect(() => {
        dispatch(getDashboardFielderActive({ filterStartDate: dateStart, filterEndData: dateEnd }))
    }, [dispatch, dateStart, dateEnd])

    const dataSorted = [...dataFielderActive].sort((a, b) => a.name.localeCompare(b.name))

    return (
        <Card variant="outlined">
            <CustomModal
                open={targetFielderId !== undefined}
                onClose={() => setTargetFielderId(undefined)}
                title='Active Fielder Detail'
                size="md"
                fullHeight
                styles={{
                    backgroundColor: '#f0f0f0'
                }}
                component={
                    <DashboardActiveFielderDetails dateStart={dateStart} dateEnd={dateEnd} targetFielderId={targetFielderId}/>
                }
            />
            <CardHeader
                sx={{ minHeight: 88 }}
                title={
                    <Typography variant="h5">
                        Assign Fielder by Date
                        <span style={{ fontWeight: "bold", color: theme.palette.primary.main }}> {dataFielderActive.length}</span>
                    </Typography>
                }
                action={
                    <CustomDatePicker dateStart={dateStart} dateEnd={dateEnd} setDateStart={setDateStart} setDateEnd={setDateEnd} />
                }
            />
            <CardContent sx={{ height: 350, overflow: "auto" }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Assigned Job</TableCell>
                            <TableCell>Last Activity</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataSorted.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell>{formatName(item.name)}</TableCell>
                                <TableCell style={{cursor: "pointer"}} onClick={() => setTargetFielderId(item.id)}><Link>{item.totalAssignedJobs}</Link></TableCell>
                                <TableCell>{item.lastActivityDateString}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    )
}