import {
    Box,
    Button,
    Collapse,
    Grid,
    List,
    ListItemButton,
    ListItemIcon, ListItemText,
    Tab,
    Tabs,
    TextField,
    Typography, useMediaQuery
} from "@mui/material";
import {useEffect, useState} from "react";
import {Container} from "@mui/system";
import {
    InitialProjectCreate, InitialProjectMultiEdit,
    InterfaceProjectCreate,
    InterfaceProjectDataList,
    InterfaceProjectDetail, InterfaceProjectMultiEdit
} from "../../project.interface";
import {projectEdit, projectGetDetail} from "../../project.api";
import {useDispatch, useSelector} from "react-redux"
import {userInfo} from "../../../../shared/utils";
import Swal from "sweetalert2";
import {setDataExpandedList} from "../../project.reducer";
import {RootState} from "../../../../store";
import {ExpandLess, ExpandMore, PersonRemove, StarBorder} from "@mui/icons-material";
import ProjectAssign from "../ProjectAssign";
import {
    fieldingAssignFtr,
    fieldingDetailsGet,
    fieldingEdit,
    FieldingNameGetByProjectId
} from "../../../fielding/fielding.api";
import {InitialFieldingDetail, InterfaceFieldingDetail} from "../../../fielding/fielding.interface";
import moment from "moment/moment";
import notificationSuccess from "../../../../shared/notificationSuccess";


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            style={{width: "100%", height: "90vh"}}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}


export default function ModalMultiProjectForm(props: {
    projectList: InterfaceProjectDataList,
    onClose: () => void,
    saveState: "idle" | "save",
    resetSaveState: () => void
}) {

    const dispatch = useDispatch<any>();

    const [tabValue, setTabValue] = useState<number>(0);

    const isMobile = useMediaQuery("(max-width:899px)");

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const [selectedDataDetail, setSelectedDataDetail] = useState<InterfaceProjectDetail | undefined>(undefined)

    const [values, setValues] = useState<InterfaceProjectMultiEdit>(InitialProjectMultiEdit)

    const [openFielder, setOpenFielder] = useState(true);
    const [selectedFielder, setSelectedFielder] = useState<{ id: string, name: string } | undefined>(undefined)

    // const [fieldingList, setFieldingList] = useState<Array<{id: string, name: string}>>([])

    const [fieldingRequest, setFieldingRequest] = useState<InterfaceFieldingDetail[]>([])

    const handleOpenClick = () => {
        setOpenFielder(!openFielder);
    };

    useEffect(() => {
        if (props.projectList.length > 0) {
            console.log(props.projectList);

            const promise = props.projectList?.map((project) => {
                return dispatch(FieldingNameGetByProjectId(project.id))
                    .unwrap()
                    .then((response: any) => {
                        return response.data.data as Array<{ id: string, name: string }>
                    }).catch(() => {
                        return []
                    })
            })

            Promise.all(promise).then((response) => {
                getFieldingDetails(response.flat())
            })

            dispatch(projectGetDetail(props.projectList[tabValue].id))
                .unwrap()
                .then((response: any) => {
                    setSelectedDataDetail(response.data.data)
                })
            setSelectedFielder(undefined)
        }
    }, [tabValue, props.projectList]);

    useEffect(() => {
        console.log(props.saveState);
        if (props.saveState === "save") {
            handleSubmit()
            props.resetSaveState()
        }
    }, [props.saveState]);

    const handleChangeValues = (e: any) => {
        const {name, value} = e.target
        setValues({
            ...values,
            [name]: value
        })
    }

    const getFieldingDetails = (fieldingList: Array<{ id: string, name: string }>) => {
        const promise = fieldingList.map((fielding) => {
            return dispatch(fieldingDetailsGet(fielding.id))
                .unwrap()
                .then((response: any) => {
                    let data: InterfaceFieldingDetail = response.data.data
                    return {
                        ...data,
                        dueDateString: moment(data.dueDateString).format("YYYY-MM-DD"),
                        fieldingRequestMarkers: data.fieldingRequestMarkers.map((marker: any) => {
                            return {
                                ...marker,
                                lineData: JSON.parse(marker.lineData),
                                shapeData: JSON.parse(marker.shapeData)
                            }
                        })
                    }
                }).catch(() => {
                    return InitialFieldingDetail
                })
        })


        Promise.all(promise).then((response) => {
            setFieldingRequest(response);
        })
    }

    const fieldProps = {
        onChange: handleChangeValues,
    }

    const handleSubmit = async () => {
        const promiseResponse = props.projectList.map((project, index) => {
            return dispatch(
                projectEdit({
                    token: userInfo().token,
                    id: project.id,
                    projectNumber: ((values.fielderCodeAndArea === undefined || values.fielderCodeAndArea === "") && (values.customerJobNumber === undefined || values.customerJobNumber === "")) ? project.projectNumber : ((values.fielderCodeAndArea === undefined || values.fielderCodeAndArea === "") ? project.fielderCodeAndArea ?? "" : values.fielderCodeAndArea) + " - " + ((values.customerJobNumber === undefined || values.customerJobNumber === "") ? project.customerJobNumber : values.customerJobNumber) + (props.projectList.length > 1 ? (index === 0 ? "" : ` - ${(values.requestName === undefined || values.requestName === "") ? project.requestName : values.requestName}`) : ""),
                    description: project.description,
                    fpAddress: project.fpAddress,
                    fpCity: project.fpCity,
                    customerCodeId: project.customerCodeId,
                    isHotJob: project.isHotJob,
                    requestName: values.requestName === undefined ? project.requestName : values.requestName,
                    projectName: values.projectName === undefined ? project.projectName : values.projectName,
                    siteName: values.siteName === undefined ? project.siteName : values.siteName,
                    customerJobNumber: values.customerJobNumber === undefined ? project.customerJobNumber : values.customerJobNumber,
                    expenseProjectID: values.expenseProjectID === undefined ? project.expenseProjectID : values.expenseProjectID,
                    poNumber: values.poNumber === undefined ? project.poNumber : values.poNumber,
                    fielderCodeAndArea: values.fielderCodeAndArea === undefined ? project.fielderCodeAndArea ?? "" : values.fielderCodeAndArea
                })).unwrap()
                .then((response: any) => {
                    if (response.status === 200) {
                        return {
                            isSuccess: true,
                            message: "Success"
                        }
                    } else {
                        return {
                            isSuccess: false,
                            message: "Failed"
                        }
                    }
                })
        })

        Promise.all(promiseResponse).then((response) => {
            console.log(response);

            if (response.every((res) => res.isSuccess)) {
                Swal.fire("Success", "Data has been saved", "success").then(() => {
                    props.onClose()
                })
            } else {
                Swal.fire("Warning!", "Some project are not saved", "error").then(() => {
                    props.onClose()
                })
            }
        })
    }

    const onMultipleAssign = async (isAssignFtr: boolean, changedFieldingRequest: InterfaceFieldingDetail, selectedAssign: Array<string>) => {
        if (isAssignFtr) {
            const promiseResponse = fieldingRequest.map((fielding) => {
                let _fielderIds = fielding.ftrFielderIds.filter((fielderId) => !changedFieldingRequest.ftrFielderIds.includes(fielderId))
                return dispatch(
                    fieldingAssignFtr({
                        fielderIds: [_fielderIds, ...selectedAssign].flat(),
                        fieldingRequestId: fielding.id,
                        token: userInfo().token,
                        ftrFielderQuantity: changedFieldingRequest.ftrFielderQuantity
                    })
                )
                    .unwrap()
                    .then((response: any) => {
                        if (response.status === 200) {
                            return {
                                isSuccess: true,
                                message: "Success"
                            }
                        } else {
                            return {
                                isSuccess: false,
                                message: "Failed"
                            }
                        }
                    })
            })

            Promise.all(promiseResponse).then((response) => {
                console.log(response);

                if (response.every((res) => res.isSuccess)) {
                    Swal.fire("Success", "Data has been saved", "success").then(() => {
                        props.onClose()
                    })
                } else {
                    Swal.fire("Warning!", "Some assign are not saved", "error").then(() => {
                        props.onClose()
                    })
                }
            })

        } else {

            const assignFtrPromise = fieldingRequest.map((fielding) => {
                return dispatch(
                    fieldingAssignFtr({
                        fielderIds: [],
                        fieldingRequestId: fielding.id,
                        token: userInfo().token,
                        ftrFielderQuantity: 0
                    })
                )
                    .unwrap()
                    .then((response: any) => {
                        if (response.status === 200) {
                            return {
                                isSuccess: true,
                                message: "Success"
                            }
                        } else {
                            return {
                                isSuccess: false,
                                message: "Failed"
                            }
                        }
                    })
            })

            Promise.all(assignFtrPromise).then((response) => {
                const promiseResponse = fieldingRequest.map((fielding) => {
                    let _fielderIds = fielding.fielderIds.filter((fielderId) => !selectedAssign.includes(fielderId))

                    let data = {
                        colorCode: fielding.colorCode,
                        customTypeId: fielding.customTypeId,
                        dueDateString: moment(fielding.dueDateString).format("MM/DD/YYYY"),
                        editedFieldingRequestMarkers: [],
                        fielderIds: [..._fielderIds, ...selectedAssign],
                        fieldingRequestId: fielding.id,
                        requestedDueDateString:
                            fielding.requestedDueDateString === "-" || fielding.requestedDueDateString === ""
                                ? ""
                                : moment(fielding.requestedDueDateString).format("MM/DD/YYYY"),
                        requestedStartDateString:
                            fielding.requestedStartDateString === "-" || fielding.requestedStartDateString === ""
                                ? ""
                                : moment(fielding.requestedStartDateString).format("MM/DD/YYYY"),
                        token: userInfo().token,
                        isEndMarker: false
                    }

                    return dispatch(fieldingEdit(data))
                        .unwrap()
                        .then((res: any) => {
                            if (res.status === 200) {
                                return {
                                    isSuccess: true,
                                    message: "Success"
                                }
                            } else {
                                return {
                                    isSuccess: false,
                                    message: "Failed"
                                }
                            }
                        })
                        .catch((err: any) => {
                            return {
                                isSuccess: false,
                                message: "Failed"
                            }
                        })
                })

                Promise.all(promiseResponse).then((response) => {
                    console.log(response);

                    if (response.every((res) => res.isSuccess)) {
                        Swal.fire("Success", "Data has been saved", "success").then(() => {
                            props.onClose()
                        })
                    } else {
                        Swal.fire("Warning!", "Some assign are not saved", "error").then(() => {
                            props.onClose()
                        })
                    }
                })
            })

        }
    }

    const [mobileTabValue, setMobileTabValue] = useState<number>(0)

    const handleMobileTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setMobileTabValue(newValue);
    };

    return (
        <>
            {
                isMobile && (
                    <Box sx={{flexGrow: 1, display: 'flex', mb: '25px'}}>
                        <Tabs value={mobileTabValue} onChange={handleMobileTabChange} aria-label="basic tabs example">
                            <Tab label="Edit Project" {...a11yProps(0)} />
                            <Tab label="Assign Fielder" {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                )
            }
            <Box
                sx={{flexGrow: 1, display: 'flex', height: '100%'}}
            >


                <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={tabValue}
                    onChange={handleTabChange}
                    aria-label="Vertical tabs example"
                    sx={{borderRight: 1, borderColor: 'divider', width: "200px"}}
                >
                    {
                        props.projectList.map((project, index) => (
                            <Tab label={project.projectNumber} {...a11yProps(index)} />
                        ))
                    }
                </Tabs>
                {
                    props.projectList.map((project, index) => (
                        <TabPanel value={tabValue} index={index}>
                            <Grid container spacing={2}>
                                {
                                    (!isMobile || (isMobile && mobileTabValue === 0)) && (
                                        <Grid item xs={12} md={6}
                                              sx={!isMobile ? {borderRight: 1, borderColor: 'divider'} : {}}>
                                            <Grid pr={2} sx={{minHeight: '87vh'}}>
                                                <Grid mb={2}>
                                                    <TextField
                                                        variant="outlined"
                                                        {...fieldProps}
                                                        value={values.fielderCodeAndArea === undefined ? project.fielderCodeAndArea : values.fielderCodeAndArea}
                                                        name="fielderCodeAndArea"
                                                        label={`Fielder Code + Area | ${selectedDataDetail?.fielderCodeAndArea}`}
                                                        placeholder={selectedDataDetail?.fielderCodeAndArea}
                                                    />
                                                </Grid>
                                                <Grid mb={2}>
                                                    <TextField
                                                        variant="outlined"
                                                        {...fieldProps}
                                                        value={values.requestName === undefined ? project.requestName : values.requestName}
                                                        name="requestName"
                                                        label={`Request Name | ${selectedDataDetail?.requestName}`}
                                                        placeholder={selectedDataDetail?.requestName}
                                                    />
                                                </Grid>
                                                <Grid mb={2}>
                                                    <TextField
                                                        variant="outlined"
                                                        {...fieldProps}
                                                        value={values.projectName === undefined ? project.projectName : values.projectName}
                                                        name="projectName"
                                                        label={`Project | ${selectedDataDetail?.projectName}`}
                                                        placeholder={selectedDataDetail?.projectName}
                                                    />
                                                </Grid>
                                                <Grid mb={2}>
                                                    <TextField
                                                        variant="outlined"
                                                        {...fieldProps}
                                                        value={values.siteName === undefined ? project.siteName : values.siteName}
                                                        name="siteName"
                                                        label={`Site Name | ${selectedDataDetail?.siteName}`}
                                                        placeholder={selectedDataDetail?.siteName}
                                                    />
                                                </Grid>
                                                <Grid mb={2}>
                                                    <TextField
                                                        variant="outlined"
                                                        {...fieldProps}
                                                        value={values.customerJobNumber === undefined ? project.customerJobNumber : values.customerJobNumber}
                                                        name="customerJobNumber"
                                                        label={`Customer Job Number | ${selectedDataDetail?.customerJobNumber}`}
                                                        placeholder={selectedDataDetail?.customerJobNumber}
                                                    />
                                                </Grid>
                                                <Grid mb={2}>
                                                    <TextField
                                                        variant="outlined"
                                                        {...fieldProps}
                                                        value={values.expenseProjectID === undefined ? project.expenseProjectID : values.expenseProjectID}
                                                        name="expenseProjectID"
                                                        label={`Expense Project ID | ${selectedDataDetail?.expenseProjectID}`}
                                                        placeholder={selectedDataDetail?.expenseProjectID}
                                                    />
                                                </Grid>
                                                <Grid mb={2}>
                                                    <TextField
                                                        variant="outlined"
                                                        {...fieldProps}
                                                        value={values.poNumber === undefined ? project.poNumber : values.poNumber}
                                                        name="poNumber"
                                                        label={`PO Number | ${selectedDataDetail?.poNumber}`}
                                                        placeholder={selectedDataDetail?.poNumber}
                                                    />
                                                </Grid>
                                                <Grid mb={1}>
                                                    <Button fullWidth variant="contained" onClick={handleSubmit}>
                                                        Save
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )
                                }
                                {
                                    (!isMobile || (isMobile && mobileTabValue === 1)) && (
                                        <Grid item xs={12} md={6}>
                                            <Grid sx={{minHeight: '87vh'}}>
                                                <Grid>
                                                    <List
                                                        sx={{width: '100%', bgcolor: 'background.paper'}}
                                                        component="nav"
                                                        aria-labelledby="nested-list-subheader"
                                                    >
                                                        <ListItemButton onClick={handleOpenClick}>
                                                            <ListItemText sx={{fontWeight: "600"}}
                                                                          primary={<Grid
                                                                              display={'flex'}>{selectedDataDetail?.projectFieldingRequests?.some(item => item?.fielderIds?.length === 0) &&
                                                                              <PersonRemove color="error"/>}<Typography
                                                                              ml={1}>{`Fielder Request ${selectedFielder !== undefined ? ` - ${selectedFielder.name}` : ""}`}</Typography></Grid>}/>
                                                            {openFielder ? <ExpandLess/> : <ExpandMore/>}
                                                        </ListItemButton>
                                                        <Collapse in={openFielder} timeout="auto" unmountOnExit>
                                                            {
                                                                selectedDataDetail?.projectFieldingRequests?.map((fielderRequest) => (
                                                                    <List component="div" disablePadding>
                                                                        <ListItemButton sx={{pl: 4}}
                                                                                        onClick={() => setSelectedFielder({
                                                                                            id: fielderRequest.fieldingRequestId,
                                                                                            name: fielderRequest.fieldingRequest
                                                                                        })}>
                                                                            <ListItemText
                                                                                primary={<Grid
                                                                                    display={'flex'}>{fielderRequest?.fielderIds?.length === 0 &&
                                                                                    <PersonRemove
                                                                                        color="error"/>}<Typography
                                                                                    ml={1}>{fielderRequest.fieldingRequest}</Typography>
                                                                                </Grid>}/>
                                                                        </ListItemButton>
                                                                    </List>
                                                                ))
                                                            }
                                                        </Collapse>
                                                    </List>
                                                </Grid>
                                                <hr/>
                                                <Grid>
                                                    {selectedFielder !== undefined &&
                                                        <ProjectAssign isMiniAssign={true} id={selectedFielder?.id}
                                                                       onMultipleAssign={onMultipleAssign}/>}
                                                </Grid>

                                            </Grid>
                                        </Grid>
                                    )
                                }
                            </Grid>
                        </TabPanel>
                    ))
                }

            </Box>
        </>

    )
}