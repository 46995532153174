import { Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import { optionList } from "../calendar.interface";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { toggleOptionSelected } from "../calendar.reducer";
import CheckIcon from "@mui/icons-material/Check";

export default function CalendarOption() {

    const dispatch = useDispatch<any>()

    const { optionSelected } = useSelector((state: RootState) => state.calendar)

    return (
        <Grid container spacing={1} margin={2}>
            <Grid item xs={12}>
                <Typography variant="h6">Show Option :</Typography>
            </Grid>
            {optionList.map((option) => (
                <Grid key={option.color} item xs={12} sm={6} md={3} lg={6}>
                    <FormControlLabel
                        label={option.name}
                        control={<Checkbox sx={{ color: `${option.color} !important` }} checked={optionSelected.includes(option.code)} onChange={() => dispatch(toggleOptionSelected(option.code))} />}
                    />
                </Grid>
            ))}
            <Grid item xs={12}>
                <Typography variant="h6">Label :</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography><CheckIcon sx={{fontSize: '14px'}}/> Ongoing</Typography>
            </Grid>
        </Grid>
    )
}